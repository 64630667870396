let area = {
  city_list: {
    110100: {
      name: '北京城区',
      id: 43
    },
    120100: {
      name: '天津城区',
      id: 361
    },
    130100: {
      name: '石家庄市',
      id: 350
    },
    130200: {
      name: '唐山市',
      id: 355
    },
    130300: {
      name: '秦皇岛市',
      id: 356
    },
    130400: {
      name: '邯郸市',
      id: 353
    },
    130500: {
      name: '邢台市',
      id: 354
    },
    130600: {
      name: '保定市',
      id: 359
    },
    130700: {
      name: '张家口市',
      id: 360
    },
    130800: {
      name: '承德市',
      id: 357
    },
    130900: {
      name: '沧州市',
      id: 358
    },
    131000: {
      name: '廊坊市',
      id: 352
    },
    131100: {
      name: '衡水市',
      id: 351
    },
    140100: {
      name: '太原市',
      id: 179
    },
    140200: {
      name: '大同市',
      id: 180
    },
    140300: {
      name: '阳泉市',
      id: 181
    },
    140400: {
      name: '长治市',
      id: 182
    },
    140500: {
      name: '晋城市',
      id: 183
    },
    140600: {
      name: '朔州市',
      id: 184
    },
    140700: {
      name: '晋中市',
      id: 185
    },
    140800: {
      name: '运城市',
      id: 186
    },
    140900: {
      name: '忻州市',
      id: 187
    },
    141000: {
      name: '临汾市',
      id: 178
    },
    141100: {
      name: '吕梁市',
      id: 177
    },
    150100: {
      name: '呼和浩特市',
      id: 279
    },
    150200: {
      name: '包头市',
      id: 276
    },
    150300: {
      name: '乌海市',
      id: 278
    },
    150400: {
      name: '赤峰市',
      id: 274
    },
    150500: {
      name: '通辽市',
      id: 275
    },
    150600: {
      name: '鄂尔多斯市',
      id: 271
    },
    150700: {
      name: '呼伦贝尔市',
      id: 273
    },
    150800: {
      name: '巴彦淖尔市',
      id: 269
    },
    150900: {
      name: '乌兰察布市',
      id: 270
    },
    152200: {
      name: '兴安盟',
      id: 280
    },
    152500: {
      name: '锡林郭勒盟',
      id: 277
    },
    152900: {
      name: '阿拉善盟',
      id: 272
    },
    210100: {
      name: '沈阳市',
      id: 263
    },
    210200: {
      name: '大连市',
      id: 261
    },
    210300: {
      name: '鞍山市',
      id: 259
    },
    210400: {
      name: '抚顺市',
      id: 258
    },
    210500: {
      name: '本溪市',
      id: 268
    },
    210600: {
      name: '丹东市',
      id: 267
    },
    210700: {
      name: '锦州市',
      id: 266
    },
    210800: {
      name: '营口市',
      id: 265
    },
    210900: {
      name: '阜新市',
      id: 257
    },
    211000: {
      name: '辽阳市',
      id: 256
    },
    211100: {
      name: '盘锦市',
      id: 255
    },
    211200: {
      name: '铁岭市',
      id: 264
    },
    211300: {
      name: '朝阳市',
      id: 262
    },
    211400: {
      name: '葫芦岛市',
      id: 260
    },
    220100: {
      name: '长春市',
      id: 239
    },
    220200: {
      name: '吉林市',
      id: 242
    },
    220300: {
      name: '四平市',
      id: 241
    },
    220400: {
      name: '辽源市',
      id: 244
    },
    220500: {
      name: '通化市',
      id: 243
    },
    220600: {
      name: '白山市',
      id: 246
    },
    220700: {
      name: '松原市',
      id: 245
    },
    220800: {
      name: '白城市',
      id: 238
    },
    222400: {
      name: '延边朝鲜族自治州',
      id: 240
    },
    230100: {
      name: '哈尔滨市',
      id: 169
    },
    230200: {
      name: '齐齐哈尔市',
      id: 168
    },
    230300: {
      name: '鸡西市',
      id: 167
    },
    230400: {
      name: '鹤岗市',
      id: 166
    },
    230500: {
      name: '双鸭山市',
      id: 165
    },
    230600: {
      name: '大庆市',
      id: 164
    },
    230700: {
      name: '伊春市',
      id: 176
    },
    230800: {
      name: '佳木斯市',
      id: 174
    },
    230900: {
      name: '七台河市',
      id: 173
    },
    231000: {
      name: '牡丹江市',
      id: 170
    },
    231100: {
      name: '黑河市',
      id: 171
    },
    231200: {
      name: '绥化市',
      id: 172
    },
    232700: {
      name: '大兴安岭地区',
      id: 175
    },
    310100: {
      name: '上海城区',
      id: 254
    },
    320100: {
      name: '南京市',
      id: 317
    },
    320200: {
      name: '无锡市',
      id: 319
    },
    320300: {
      name: '徐州市',
      id: 315
    },
    320400: {
      name: '常州市',
      id: 316
    },
    320500: {
      name: '苏州市',
      id: 313
    },
    320600: {
      name: '南通市',
      id: 314
    },
    320700: {
      name: '连云港市',
      id: 311
    },
    320800: {
      name: '淮安市',
      id: 312
    },
    320900: {
      name: '盐城市',
      id: 310
    },
    321000: {
      name: '扬州市',
      id: 308
    },
    321100: {
      name: '镇江市',
      id: 309
    },
    321200: {
      name: '泰州市',
      id: 318
    },
    321300: {
      name: '宿迁市',
      id: 320
    },
    330100: {
      name: '杭州市',
      id: 346
    },
    330200: {
      name: '宁波市',
      id: 347
    },
    330300: {
      name: '温州市',
      id: 348
    },
    330400: {
      name: '嘉兴市',
      id: 341
    },
    330500: {
      name: '湖州市',
      id: 342
    },
    330600: {
      name: '绍兴市',
      id: 343
    },
    330700: {
      name: '金华市',
      id: 344
    },
    330800: {
      name: '衢州市',
      id: 339
    },
    330900: {
      name: '舟山市',
      id: 340
    },
    331000: {
      name: '台州市',
      id: 338
    },
    331100: {
      name: '丽水市',
      id: 345
    },
    340100: {
      name: '合肥市',
      id: 27
    },
    340200: {
      name: '芜湖市',
      id: 29
    },
    340300: {
      name: '蚌埠市',
      id: 37
    },
    340400: {
      name: '淮南市',
      id: 39
    },
    340500: {
      name: '马鞍山市',
      id: 33
    },
    340600: {
      name: '淮北市',
      id: 35
    },
    340700: {
      name: '铜陵市',
      id: 24
    },
    340800: {
      name: '安庆市',
      id: 25
    },
    341000: {
      name: '黄山市',
      id: 31
    },
    341100: {
      name: '滁州市',
      id: 32
    },
    341200: {
      name: '阜阳市',
      id: 28
    },
    341300: {
      name: '宿州市',
      id: 30
    },
    341500: {
      name: '六安市',
      id: 38
    },
    341600: {
      name: '亳州市',
      id: 36
    },
    341700: {
      name: '池州市',
      id: 34
    },
    341800: {
      name: '宣城市',
      id: 26
    },
    350100: {
      name: '福州市',
      id: 333
    },
    350200: {
      name: '厦门市',
      id: 334
    },
    350300: {
      name: '莆田市',
      id: 335
    },
    350400: {
      name: '三明市',
      id: 336
    },
    350500: {
      name: '泉州市',
      id: 337
    },
    350600: {
      name: '漳州市',
      id: 329
    },
    350700: {
      name: '南平市',
      id: 330
    },
    350800: {
      name: '龙岩市',
      id: 331
    },
    350900: {
      name: '宁德市',
      id: 332
    },
    360100: {
      name: '南昌市',
      id: 299
    },
    360200: {
      name: '景德镇市',
      id: 300
    },
    360300: {
      name: '萍乡市',
      id: 297
    },
    360400: {
      name: '九江市',
      id: 298
    },
    360500: {
      name: '新余市',
      id: 306
    },
    360600: {
      name: '鹰潭市',
      id: 307
    },
    360700: {
      name: '赣州市',
      id: 304
    },
    360800: {
      name: '吉安市',
      id: 305
    },
    360900: {
      name: '宜春市',
      id: 303
    },
    361000: {
      name: '抚州市',
      id: 302
    },
    361100: {
      name: '上饶市',
      id: 301
    },
    370100: {
      name: '济南市',
      id: 189
    },
    370200: {
      name: '青岛市',
      id: 191
    },
    370300: {
      name: '淄博市',
      id: 193
    },
    370400: {
      name: '枣庄市',
      id: 198
    },
    370500: {
      name: '东营市',
      id: 200
    },
    370600: {
      name: '烟台市',
      id: 202
    },
    370700: {
      name: '潍坊市',
      id: 203
    },
    370800: {
      name: '济宁市',
      id: 196
    },
    370900: {
      name: '泰安市',
      id: 197
    },
    371000: {
      name: '威海市',
      id: 195
    },
    371100: {
      name: '日照市',
      id: 190
    },
    371200: {
      name: '莱芜市',
      id: 188
    },
    371300: {
      name: '临沂市',
      id: 194
    },
    371400: {
      name: '德州市',
      id: 192
    },
    371500: {
      name: '聊城市',
      id: 201
    },
    371600: {
      name: '滨州市',
      id: 199
    },
    371700: {
      name: '菏泽市',
      id: 204
    },
    410100: {
      name: '郑州市',
      id: 59
    },
    410200: {
      name: '开封市',
      id: 57
    },
    410300: {
      name: '洛阳市',
      id: 49
    },
    410400: {
      name: '平顶山市',
      id: 47
    },
    410500: {
      name: '安阳市',
      id: 53
    },
    410600: {
      name: '鹤壁市',
      id: 51
    },
    410700: {
      name: '新乡市',
      id: 45
    },
    410800: {
      name: '焦作市',
      id: 44
    },
    410900: {
      name: '濮阳市',
      id: 46
    },
    411000: {
      name: '许昌市',
      id: 56
    },
    411100: {
      name: '漯河市',
      id: 55
    },
    411200: {
      name: '三门峡市',
      id: 60
    },
    411300: {
      name: '南阳市',
      id: 58
    },
    411400: {
      name: '商丘市',
      id: 50
    },
    411500: {
      name: '信阳市',
      id: 48
    },
    411600: {
      name: '周口市',
      id: 54
    },
    411700: {
      name: '驻马店市',
      id: 52
    },
    419001: {
      name: '济源市',
      id: 61
    },
    420100: {
      name: '武汉市',
      id: 68
    },
    420200: {
      name: '黄石市',
      id: 78
    },
    420300: {
      name: '十堰市',
      id: 77
    },
    420500: {
      name: '宜昌市',
      id: 75
    },
    420600: {
      name: '襄阳市',
      id: 83
    },
    420700: {
      name: '鄂州市',
      id: 82
    },
    420800: {
      name: '荆门市',
      id: 81
    },
    420900: {
      name: '孝感市',
      id: 80
    },
    421000: {
      name: '荆州市',
      id: 73
    },
    421100: {
      name: '黄冈市',
      id: 71
    },
    421200: {
      name: '咸宁市',
      id: 69
    },
    421300: {
      name: '随州市',
      id: 79
    },
    422800: {
      name: '恩施土家族苗族自治州',
      id: 76
    },
    429004: {
      name: '仙桃市',
      id: 74
    },
    429005: {
      name: '潜江市',
      id: 72
    },
    429006: {
      name: '天门市',
      id: 70
    },
    429021: {
      name: '神农架林区',
      id: 67
    },
    430100: {
      name: '长沙市',
      id: 117
    },
    430200: {
      name: '株洲市',
      id: 115
    },
    430300: {
      name: '湘潭市',
      id: 120
    },
    430400: {
      name: '衡阳市',
      id: 119
    },
    430500: {
      name: '邵阳市',
      id: 122
    },
    430600: {
      name: '岳阳市',
      id: 121
    },
    430700: {
      name: '常德市',
      id: 124
    },
    430800: {
      name: '张家界市',
      id: 123
    },
    430900: {
      name: '益阳市',
      id: 125
    },
    431000: {
      name: '郴州市',
      id: 127
    },
    431100: {
      name: '永州市',
      id: 126
    },
    431200: {
      name: '怀化市',
      id: 118
    },
    431300: {
      name: '娄底市',
      id: 116
    },
    433100: {
      name: '湘西土家族苗族自治州',
      id: 128
    },
    440100: {
      name: '广州市',
      id: 101
    },
    440200: {
      name: '韶关市',
      id: 98
    },
    440300: {
      name: '深圳市',
      id: 95
    },
    440400: {
      name: '珠海市',
      id: 93
    },
    440500: {
      name: '汕头市',
      id: 91
    },
    440600: {
      name: '佛山市',
      id: 89
    },
    440700: {
      name: '江门市',
      id: 87
    },
    440800: {
      name: '湛江市',
      id: 85
    },
    440900: {
      name: '茂名市',
      id: 84
    },
    441200: {
      name: '肇庆市',
      id: 102
    },
    441300: {
      name: '惠州市',
      id: 99
    },
    441400: {
      name: '梅州市',
      id: 96
    },
    441500: {
      name: '汕尾市',
      id: 94
    },
    441600: {
      name: '河源市',
      id: 92
    },
    441700: {
      name: '阳江市',
      id: 90
    },
    441800: {
      name: '清远市',
      id: 88
    },
    441900: {
      name: '东莞市',
      id: 86
    },
    442000: {
      name: '中山市',
      id: 103
    },
    442100: {
      name: '东沙群岛',
      id: 104
    },
    445100: {
      name: '潮州市',
      id: 100
    },
    445200: {
      name: '揭阳市',
      id: 97
    },
    445300: {
      name: '云浮市',
      id: 105
    },
    450100: {
      name: '南宁市',
      id: 162
    },
    450200: {
      name: '柳州市',
      id: 160
    },
    450300: {
      name: '桂林市',
      id: 154
    },
    450400: {
      name: '梧州市',
      id: 153
    },
    450500: {
      name: '北海市',
      id: 157
    },
    450600: {
      name: '防城港市',
      id: 156
    },
    450700: {
      name: '钦州市',
      id: 151
    },
    450800: {
      name: '贵港市',
      id: 150
    },
    450900: {
      name: '玉林市',
      id: 152
    },
    451000: {
      name: '百色市',
      id: 159
    },
    451100: {
      name: '贺州市',
      id: 158
    },
    451200: {
      name: '河池市',
      id: 163
    },
    451300: {
      name: '来宾市',
      id: 161
    },
    451400: {
      name: '崇左市',
      id: 155
    },
    460100: {
      name: '海口市',
      id: 219
    },
    460200: {
      name: '三亚市',
      id: 222
    },
    460300: {
      name: '三沙市',
      id: 221
    },
    460400: {
      name: '儋州市',
      id: 220
    },
    469001: {
      name: '五指山市',
      id: 233
    },
    469002: {
      name: '琼海市',
      id: 231
    },
    469005: {
      name: '文昌市',
      id: 227
    },
    469006: {
      name: '万宁市',
      id: 225
    },
    469007: {
      name: '东方市',
      id: 223
    },
    469021: {
      name: '定安县',
      id: 236
    },
    469022: {
      name: '屯昌县',
      id: 235
    },
    469023: {
      name: '澄迈县',
      id: 234
    },
    469024: {
      name: '临高县',
      id: 232
    },
    469025: {
      name: '白沙黎族自治县',
      id: 230
    },
    469026: {
      name: '昌江黎族自治县',
      id: 229
    },
    469027: {
      name: '乐东黎族自治县',
      id: 228
    },
    469028: {
      name: '陵水黎族自治县',
      id: 226
    },
    469029: {
      name: '保亭黎族苗族自治县',
      id: 224
    },
    469030: {
      name: '琼中黎族苗族自治县',
      id: 237
    },
    500100: {
      name: '重庆城区',
      id: 42
    },
    500200: {
      name: '重庆郊县',
      id: 41
    },
    510100: {
      name: '成都市',
      id: 132
    },
    510300: {
      name: '自贡市',
      id: 135
    },
    510400: {
      name: '攀枝花市',
      id: 141
    },
    510500: {
      name: '泸州市',
      id: 139
    },
    510600: {
      name: '德阳市',
      id: 145
    },
    510700: {
      name: '绵阳市',
      id: 143
    },
    510800: {
      name: '广元市',
      id: 147
    },
    510900: {
      name: '遂宁市',
      id: 149
    },
    511000: {
      name: '内江市',
      id: 131
    },
    511100: {
      name: '乐山市',
      id: 136
    },
    511300: {
      name: '南充市',
      id: 133
    },
    511400: {
      name: '眉山市',
      id: 134
    },
    511500: {
      name: '宜宾市',
      id: 144
    },
    511600: {
      name: '广安市',
      id: 146
    },
    511700: {
      name: '达州市',
      id: 140
    },
    511800: {
      name: '雅安市',
      id: 142
    },
    511900: {
      name: '巴中市',
      id: 148
    },
    512000: {
      name: '资阳市',
      id: 129
    },
    513200: {
      name: '阿坝藏族羌族自治州',
      id: 130
    },
    513300: {
      name: '甘孜藏族自治州',
      id: 138
    },
    513400: {
      name: '凉山彝族自治州',
      id: 137
    },
    520100: {
      name: '贵阳市',
      id: 109
    },
    520200: {
      name: '六盘水市',
      id: 107
    },
    520300: {
      name: '遵义市',
      id: 108
    },
    520400: {
      name: '安顺市',
      id: 110
    },
    520500: {
      name: '毕节市',
      id: 112
    },
    520600: {
      name: '铜仁市',
      id: 114
    },
    522300: {
      name: '黔西南布依族苗族自治州',
      id: 106
    },
    522600: {
      name: '黔东南苗族侗族自治州',
      id: 111
    },
    522700: {
      name: '黔南布依族苗族自治州',
      id: 113
    },
    530100: {
      name: '昆明市',
      id: 283
    },
    530300: {
      name: '曲靖市',
      id: 296
    },
    530400: {
      name: '玉溪市',
      id: 292
    },
    530500: {
      name: '保山市',
      id: 293
    },
    530600: {
      name: '昭通市',
      id: 288
    },
    530700: {
      name: '丽江市',
      id: 290
    },
    530800: {
      name: '普洱市',
      id: 286
    },
    530900: {
      name: '临沧市',
      id: 287
    },
    532300: {
      name: '楚雄彝族自治州',
      id: 281
    },
    532500: {
      name: '红河哈尼族彝族自治州',
      id: 295
    },
    532600: {
      name: '文山壮族苗族自治州',
      id: 294
    },
    532800: {
      name: '西双版纳傣族自治州',
      id: 291
    },
    532900: {
      name: '大理白族自治州',
      id: 289
    },
    533100: {
      name: '德宏傣族景颇族自治州',
      id: 285
    },
    533300: {
      name: '怒江傈僳族自治州',
      id: 284
    },
    533400: {
      name: '迪庆藏族自治州',
      id: 282
    },
    540100: {
      name: '拉萨市',
      id: 249
    },
    540200: {
      name: '日喀则市',
      id: 250
    },
    540300: {
      name: '昌都市',
      id: 251
    },
    540400: {
      name: '林芝市',
      id: 252
    },
    540500: {
      name: '山南市',
      id: 247
    },
    540600: {
      name: '那曲市',
      id: 248
    },
    542500: {
      name: '阿里地区',
      id: 253
    },
    610100: {
      name: '西安市',
      id: 364
    },
    610200: {
      name: '铜川市',
      id: 365
    },
    610300: {
      name: '宝鸡市',
      id: 362
    },
    610400: {
      name: '咸阳市',
      id: 363
    },
    610500: {
      name: '渭南市',
      id: 370
    },
    610600: {
      name: '延安市',
      id: 371
    },
    610700: {
      name: '汉中市',
      id: 368
    },
    610800: {
      name: '榆林市',
      id: 369
    },
    610900: {
      name: '安康市',
      id: 367
    },
    611000: {
      name: '商洛市',
      id: 366
    },
    620100: {
      name: '兰州市',
      id: 206
    },
    620200: {
      name: '嘉峪关市',
      id: 208
    },
    620300: {
      name: '金昌市',
      id: 209
    },
    620400: {
      name: '白银市',
      id: 214
    },
    620500: {
      name: '天水市',
      id: 215
    },
    620600: {
      name: '武威市',
      id: 216
    },
    620700: {
      name: '张掖市',
      id: 218
    },
    620800: {
      name: '平凉市',
      id: 212
    },
    620900: {
      name: '酒泉市',
      id: 213
    },
    621000: {
      name: '庆阳市',
      id: 210
    },
    621100: {
      name: '定西市',
      id: 207
    },
    621200: {
      name: '陇南市',
      id: 205
    },
    622900: {
      name: '临夏回族自治州',
      id: 217
    },
    623000: {
      name: '甘南藏族自治州',
      id: 211
    },
    630100: {
      name: '西宁市',
      id: 321
    },
    630200: {
      name: '海东市',
      id: 322
    },
    632200: {
      name: '海北藏族自治州',
      id: 324
    },
    632300: {
      name: '黄南藏族自治州',
      id: 323
    },
    632500: {
      name: '海南藏族自治州',
      id: 328
    },
    632600: {
      name: '果洛藏族自治州',
      id: 327
    },
    632700: {
      name: '玉树藏族自治州',
      id: 326
    },
    632800: {
      name: '海西蒙古族藏族自治州',
      id: 325
    },
    640100: {
      name: '银川市',
      id: 62
    },
    640200: {
      name: '石嘴山市',
      id: 64
    },
    640300: {
      name: '吴忠市',
      id: 63
    },
    640400: {
      name: '固原市',
      id: 66
    },
    640500: {
      name: '中卫市',
      id: 65
    },
    650100: {
      name: '乌鲁木齐市',
      id: 4
    },
    650200: {
      name: '克拉玛依市',
      id: 3
    },
    650400: {
      name: '吐鲁番市',
      id: 2
    },
    650500: {
      name: '哈密市',
      id: 1
    },
    652300: {
      name: '昌吉回族自治州',
      id: 5
    },
    652700: {
      name: '博尔塔拉蒙古自治州',
      id: 6
    },
    652800: {
      name: '巴音郭楞蒙古自治州',
      id: 7
    },
    652900: {
      name: '阿克苏地区',
      id: 8
    },
    653000: {
      name: '克孜勒苏柯尔克孜自治州',
      id: 14
    },
    653100: {
      name: '喀什地区',
      id: 16
    },
    653200: {
      name: '和田地区',
      id: 19
    },
    654000: {
      name: '伊犁哈萨克自治州',
      id: 12
    },
    654200: {
      name: '塔城地区',
      id: 17
    },
    654300: {
      name: '阿勒泰地区',
      id: 20
    },
    659001: {
      name: '石河子市',
      id: 23
    },
    659002: {
      name: '阿拉尔市',
      id: 13
    },
    659003: {
      name: '图木舒克市',
      id: 11
    },
    659004: {
      name: '五家渠市',
      id: 10
    },
    659005: {
      name: '北屯市',
      id: 9
    },
    659006: {
      name: '铁门关市',
      id: 22
    },
    659007: {
      name: '双河市',
      id: 21
    },
    659008: {
      name: '可克达拉市',
      id: 18
    },
    659009: {
      name: '昆玉市',
      id: 15
    },
    810100: {
      name: '香港城区',
      id: 40
    },
    820100: {
      name: '澳门城区',
      id: 349
    }
  },
  county_list: {
    110101: {
      name: '东城区',
      id: 2647
    },
    110102: {
      name: '西城区',
      id: 2649
    },
    110105: {
      name: '朝阳区',
      id: 2641
    },
    110106: {
      name: '丰台区',
      id: 2643
    },
    110107: {
      name: '石景山区',
      id: 2653
    },
    110108: {
      name: '海淀区',
      id: 2655
    },
    110109: {
      name: '门头沟区',
      id: 2652
    },
    110111: {
      name: '房山区',
      id: 2651
    },
    110112: {
      name: '通州区',
      id: 2648
    },
    110113: {
      name: '顺义区',
      id: 2650
    },
    110114: {
      name: '昌平区',
      id: 2645
    },
    110115: {
      name: '大兴区',
      id: 2646
    },
    110116: {
      name: '怀柔区',
      id: 2642
    },
    110117: {
      name: '平谷区',
      id: 2644
    },
    110118: {
      name: '密云区',
      id: 2654
    },
    110119: {
      name: '延庆区',
      id: 2656
    },
    120101: {
      name: '和平区',
      id: 2424
    },
    120102: {
      name: '河东区',
      id: 2414
    },
    120103: {
      name: '河西区',
      id: 2416
    },
    120104: {
      name: '南开区',
      id: 2418
    },
    120105: {
      name: '河北区',
      id: 2420
    },
    120106: {
      name: '红桥区',
      id: 2426
    },
    120110: {
      name: '东丽区',
      id: 2422
    },
    120111: {
      name: '西青区',
      id: 2423
    },
    120112: {
      name: '津南区',
      id: 2425
    },
    120113: {
      name: '北辰区',
      id: 2415
    },
    120114: {
      name: '武清区',
      id: 2417
    },
    120115: {
      name: '宝坻区',
      id: 2419
    },
    120116: {
      name: '滨海新区',
      id: 2421
    },
    120117: {
      name: '宁河区',
      id: 2427
    },
    120118: {
      name: '静海区',
      id: 2428
    },
    120119: {
      name: '蓟州区',
      id: 2429
    },
    130102: {
      name: '长安区',
      id: 1919
    },
    130104: {
      name: '桥西区',
      id: 1916
    },
    130105: {
      name: '新华区',
      id: 1923
    },
    130107: {
      name: '井陉矿区',
      id: 1920
    },
    130108: {
      name: '裕华区',
      id: 1922
    },
    130109: {
      name: '藁城区',
      id: 1926
    },
    130110: {
      name: '鹿泉区',
      id: 1908
    },
    130111: {
      name: '栾城区',
      id: 1911
    },
    130121: {
      name: '井陉县',
      id: 1909
    },
    130123: {
      name: '正定县',
      id: 1918
    },
    130125: {
      name: '行唐县',
      id: 1915
    },
    130126: {
      name: '灵寿县',
      id: 1917
    },
    130127: {
      name: '高邑县',
      id: 1924
    },
    130128: {
      name: '深泽县',
      id: 1925
    },
    130129: {
      name: '赞皇县',
      id: 1921
    },
    130130: {
      name: '无极县',
      id: 1913
    },
    130131: {
      name: '平山县',
      id: 1914
    },
    130132: {
      name: '元氏县',
      id: 1910
    },
    130133: {
      name: '赵县',
      id: 1912
    },
    130181: {
      name: '辛集市',
      id: 1929
    },
    130183: {
      name: '晋州市',
      id: 1927
    },
    130184: {
      name: '新乐市',
      id: 1928
    },
    130202: {
      name: '路南区',
      id: 1728
    },
    130203: {
      name: '路北区',
      id: 1730
    },
    130204: {
      name: '古冶区',
      id: 1735
    },
    130205: {
      name: '开平区',
      id: 1736
    },
    130207: {
      name: '丰南区',
      id: 1733
    },
    130208: {
      name: '丰润区',
      id: 1738
    },
    130209: {
      name: '曹妃甸区',
      id: 1739
    },
    130223: {
      name: '滦县',
      id: 1732
    },
    130224: {
      name: '滦南县',
      id: 1729
    },
    130225: {
      name: '乐亭县',
      id: 1731
    },
    130227: {
      name: '迁西县',
      id: 1737
    },
    130229: {
      name: '玉田县',
      id: 1734
    },
    130281: {
      name: '遵化市',
      id: 1741
    },
    130283: {
      name: '迁安市',
      id: 1740
    },
    130302: {
      name: '海港区',
      id: 2262
    },
    130303: {
      name: '山海关区',
      id: 2267
    },
    130304: {
      name: '北戴河区',
      id: 2268
    },
    130306: {
      name: '抚宁区',
      id: 2266
    },
    130321: {
      name: '青龙满族自治县',
      id: 2264
    },
    130322: {
      name: '昌黎县',
      id: 2265
    },
    130324: {
      name: '卢龙县',
      id: 2263
    },
    130402: {
      name: '邯山区',
      id: 2092
    },
    130403: {
      name: '丛台区',
      id: 2095
    },
    130404: {
      name: '复兴区',
      id: 2089
    },
    130406: {
      name: '峰峰矿区',
      id: 2098
    },
    130407: {
      name: '肥乡区',
      id: 2099
    },
    130408: {
      name: '永年区',
      id: 2097
    },
    130423: {
      name: '临漳县',
      id: 2085
    },
    130424: {
      name: '成安县',
      id: 2093
    },
    130425: {
      name: '大名县',
      id: 2096
    },
    130426: {
      name: '涉县',
      id: 2090
    },
    130427: {
      name: '磁县',
      id: 2091
    },
    130430: {
      name: '邱县',
      id: 2083
    },
    130431: {
      name: '鸡泽县',
      id: 2087
    },
    130432: {
      name: '广平县',
      id: 2088
    },
    130433: {
      name: '馆陶县',
      id: 2084
    },
    130434: {
      name: '魏县',
      id: 2086
    },
    130435: {
      name: '曲周县',
      id: 2094
    },
    130481: {
      name: '武安市',
      id: 2100
    },
    130502: {
      name: '桥东区',
      id: 1036
    },
    130503: {
      name: '桥西区',
      id: 1031
    },
    130521: {
      name: '邢台县',
      id: 1025
    },
    130522: {
      name: '临城县',
      id: 1027
    },
    130523: {
      name: '内丘县',
      id: 1034
    },
    130524: {
      name: '柏乡县',
      id: 1037
    },
    130525: {
      name: '隆尧县',
      id: 1032
    },
    130526: {
      name: '任县',
      id: 1033
    },
    130527: {
      name: '南和县',
      id: 1040
    },
    130528: {
      name: '宁晋县',
      id: 1041
    },
    130529: {
      name: '巨鹿县',
      id: 1039
    },
    130530: {
      name: '新河县',
      id: 1029
    },
    130531: {
      name: '广宗县',
      id: 1030
    },
    130532: {
      name: '平乡县',
      id: 1026
    },
    130533: {
      name: '威县',
      id: 1028
    },
    130534: {
      name: '清河县',
      id: 1035
    },
    130535: {
      name: '临西县',
      id: 1038
    },
    130581: {
      name: '南宫市',
      id: 1023
    },
    130582: {
      name: '沙河市',
      id: 1024
    },
    130602: {
      name: '竞秀区',
      id: 779
    },
    130606: {
      name: '莲池区',
      id: 786
    },
    130607: {
      name: '满城区',
      id: 788
    },
    130608: {
      name: '清苑区',
      id: 794
    },
    130609: {
      name: '徐水区',
      id: 795
    },
    130623: {
      name: '涞水县',
      id: 784
    },
    130624: {
      name: '阜平县',
      id: 780
    },
    130626: {
      name: '定兴县',
      id: 790
    },
    130627: {
      name: '唐县',
      id: 792
    },
    130628: {
      name: '高阳县',
      id: 787
    },
    130629: {
      name: '容城县',
      id: 789
    },
    130630: {
      name: '涞源县',
      id: 778
    },
    130631: {
      name: '望都县',
      id: 775
    },
    130632: {
      name: '安新县',
      id: 776
    },
    130633: {
      name: '易县',
      id: 783
    },
    130634: {
      name: '曲阳县',
      id: 785
    },
    130635: {
      name: '蠡县',
      id: 781
    },
    130636: {
      name: '顺平县',
      id: 782
    },
    130637: {
      name: '博野县',
      id: 791
    },
    130638: {
      name: '雄县',
      id: 793
    },
    130681: {
      name: '涿州市',
      id: 774
    },
    130682: {
      name: '定州市',
      id: 772
    },
    130683: {
      name: '安国市',
      id: 773
    },
    130684: {
      name: '高碑店市',
      id: 777
    },
    130702: {
      name: '桥东区',
      id: 1528
    },
    130703: {
      name: '桥西区',
      id: 1536
    },
    130705: {
      name: '宣化区',
      id: 1532
    },
    130706: {
      name: '下花园区',
      id: 1534
    },
    130708: {
      name: '万全区',
      id: 1540
    },
    130709: {
      name: '崇礼区',
      id: 1539
    },
    130722: {
      name: '张北县',
      id: 1531
    },
    130723: {
      name: '康保县',
      id: 1527
    },
    130724: {
      name: '沽源县',
      id: 1529
    },
    130725: {
      name: '尚义县',
      id: 1537
    },
    130726: {
      name: '蔚县',
      id: 1538
    },
    130727: {
      name: '阳原县',
      id: 1533
    },
    130728: {
      name: '怀安县',
      id: 1535
    },
    130730: {
      name: '怀来县',
      id: 1525
    },
    130731: {
      name: '涿鹿县',
      id: 1526
    },
    130732: {
      name: '赤城县',
      id: 1530
    },
    130802: {
      name: '双桥区',
      id: 1254
    },
    130803: {
      name: '双滦区',
      id: 1256
    },
    130804: {
      name: '鹰手营子矿区',
      id: 1251
    },
    130821: {
      name: '承德县',
      id: 1250
    },
    130822: {
      name: '兴隆县',
      id: 1249
    },
    130824: {
      name: '滦平县',
      id: 1255
    },
    130825: {
      name: '隆化县',
      id: 1257
    },
    130826: {
      name: '丰宁满族自治县',
      id: 1252
    },
    130827: {
      name: '宽城满族自治县',
      id: 1253
    },
    130828: {
      name: '围场满族蒙古族自治县',
      id: 1248
    },
    130881: {
      name: '平泉市',
      id: 1247
    },
    130902: {
      name: '新华区',
      id: 264
    },
    130903: {
      name: '运河区',
      id: 260
    },
    130921: {
      name: '沧县',
      id: 257
    },
    130922: {
      name: '青县',
      id: 258
    },
    130923: {
      name: '东光县',
      id: 263
    },
    130924: {
      name: '海兴县',
      id: 265
    },
    130925: {
      name: '盐山县',
      id: 261
    },
    130926: {
      name: '肃宁县',
      id: 262
    },
    130927: {
      name: '南皮县',
      id: 267
    },
    130928: {
      name: '吴桥县',
      id: 268
    },
    130929: {
      name: '献县',
      id: 266
    },
    130930: {
      name: '孟村回族自治县',
      id: 259
    },
    130981: {
      name: '泊头市',
      id: 255
    },
    130982: {
      name: '任丘市',
      id: 256
    },
    130983: {
      name: '黄骅市',
      id: 253
    },
    130984: {
      name: '河间市',
      id: 254
    },
    131002: {
      name: '安次区',
      id: 2359
    },
    131003: {
      name: '广阳区',
      id: 2365
    },
    131022: {
      name: '固安县',
      id: 2362
    },
    131023: {
      name: '永清县',
      id: 2361
    },
    131024: {
      name: '香河县',
      id: 2360
    },
    131025: {
      name: '大城县',
      id: 2366
    },
    131026: {
      name: '文安县',
      id: 2364
    },
    131028: {
      name: '大厂回族自治县',
      id: 2363
    },
    131081: {
      name: '霸州市',
      id: 2368
    },
    131082: {
      name: '三河市',
      id: 2367
    },
    131102: {
      name: '桃城区',
      id: 2755
    },
    131103: {
      name: '冀州区',
      id: 2753
    },
    131121: {
      name: '枣强县',
      id: 2750
    },
    131122: {
      name: '武邑县',
      id: 2749
    },
    131123: {
      name: '武强县',
      id: 2748
    },
    131124: {
      name: '饶阳县',
      id: 2756
    },
    131125: {
      name: '安平县',
      id: 2754
    },
    131126: {
      name: '故城县',
      id: 2752
    },
    131127: {
      name: '景县',
      id: 2751
    },
    131128: {
      name: '阜城县',
      id: 2757
    },
    131182: {
      name: '深州市',
      id: 2758
    },
    140105: {
      name: '小店区',
      id: 2319
    },
    140106: {
      name: '迎泽区',
      id: 2320
    },
    140107: {
      name: '杏花岭区',
      id: 2321
    },
    140108: {
      name: '尖草坪区',
      id: 2322
    },
    140109: {
      name: '万柏林区',
      id: 2323
    },
    140110: {
      name: '晋源区',
      id: 2315
    },
    140121: {
      name: '清徐县',
      id: 2316
    },
    140122: {
      name: '阳曲县',
      id: 2317
    },
    140123: {
      name: '娄烦县',
      id: 2318
    },
    140181: {
      name: '古交市',
      id: 2324
    },
    140202: {
      name: '城区',
      id: 2133
    },
    140203: {
      name: '矿区',
      id: 2135
    },
    140211: {
      name: '南郊区',
      id: 2129
    },
    140212: {
      name: '新荣区',
      id: 2131
    },
    140221: {
      name: '阳高县',
      id: 2128
    },
    140222: {
      name: '天镇县',
      id: 2130
    },
    140223: {
      name: '广灵县',
      id: 2132
    },
    140224: {
      name: '灵丘县',
      id: 2134
    },
    140225: {
      name: '浑源县',
      id: 2136
    },
    140226: {
      name: '左云县',
      id: 2137
    },
    140227: {
      name: '大同县',
      id: 2138
    },
    140302: {
      name: '城区',
      id: 1097
    },
    140303: {
      name: '矿区',
      id: 1098
    },
    140311: {
      name: '郊区',
      id: 1095
    },
    140321: {
      name: '平定县',
      id: 1094
    },
    140322: {
      name: '盂县',
      id: 1096
    },
    140402: {
      name: '城区',
      id: 854
    },
    140411: {
      name: '郊区',
      id: 852
    },
    140421: {
      name: '长治县',
      id: 851
    },
    140423: {
      name: '襄垣县',
      id: 853
    },
    140424: {
      name: '屯留县',
      id: 855
    },
    140425: {
      name: '平顺县',
      id: 856
    },
    140426: {
      name: '黎城县',
      id: 857
    },
    140427: {
      name: '壶关县',
      id: 858
    },
    140428: {
      name: '长子县',
      id: 859
    },
    140429: {
      name: '武乡县',
      id: 860
    },
    140430: {
      name: '沁县',
      id: 849
    },
    140431: {
      name: '沁源县',
      id: 850
    },
    140481: {
      name: '潞城市',
      id: 848
    },
    140502: {
      name: '城区',
      id: 1603
    },
    140521: {
      name: '沁水县',
      id: 1601
    },
    140522: {
      name: '阳城县',
      id: 1602
    },
    140524: {
      name: '陵川县',
      id: 1604
    },
    140525: {
      name: '泽州县',
      id: 1605
    },
    140581: {
      name: '高平市',
      id: 1600
    },
    140602: {
      name: '朔城区',
      id: 1354
    },
    140603: {
      name: '平鲁区',
      id: 1356
    },
    140621: {
      name: '山阴县',
      id: 1351
    },
    140622: {
      name: '应县',
      id: 1352
    },
    140623: {
      name: '右玉县',
      id: 1353
    },
    140624: {
      name: '怀仁县',
      id: 1355
    },
    140702: {
      name: '榆次区',
      id: 316
    },
    140721: {
      name: '榆社县',
      id: 313
    },
    140722: {
      name: '左权县',
      id: 314
    },
    140723: {
      name: '和顺县',
      id: 315
    },
    140724: {
      name: '昔阳县',
      id: 317
    },
    140725: {
      name: '寿阳县',
      id: 318
    },
    140726: {
      name: '太谷县',
      id: 319
    },
    140727: {
      name: '祁县',
      id: 320
    },
    140728: {
      name: '平遥县',
      id: 310
    },
    140729: {
      name: '灵石县',
      id: 311
    },
    140781: {
      name: '介休市',
      id: 312
    },
    140802: {
      name: '盐湖区',
      id: 121
    },
    140821: {
      name: '临猗县',
      id: 118
    },
    140822: {
      name: '万荣县',
      id: 119
    },
    140823: {
      name: '闻喜县',
      id: 120
    },
    140824: {
      name: '稷山县',
      id: 122
    },
    140825: {
      name: '新绛县',
      id: 123
    },
    140826: {
      name: '绛县',
      id: 124
    },
    140827: {
      name: '垣曲县',
      id: 112
    },
    140828: {
      name: '夏县',
      id: 113
    },
    140829: {
      name: '平陆县',
      id: 114
    },
    140830: {
      name: '芮城县',
      id: 117
    },
    140881: {
      name: '永济市',
      id: 115
    },
    140882: {
      name: '河津市',
      id: 116
    },
    140902: {
      name: '忻府区',
      id: 657
    },
    140921: {
      name: '定襄县',
      id: 653
    },
    140922: {
      name: '五台县',
      id: 655
    },
    140923: {
      name: '代县',
      id: 656
    },
    140924: {
      name: '繁峙县',
      id: 658
    },
    140925: {
      name: '宁武县',
      id: 659
    },
    140926: {
      name: '静乐县',
      id: 646
    },
    140927: {
      name: '神池县',
      id: 647
    },
    140928: {
      name: '五寨县',
      id: 648
    },
    140929: {
      name: '岢岚县',
      id: 649
    },
    140930: {
      name: '河曲县',
      id: 651
    },
    140931: {
      name: '保德县',
      id: 652
    },
    140932: {
      name: '偏关县',
      id: 654
    },
    140981: {
      name: '原平市',
      id: 650
    },
    141002: {
      name: '尧都区',
      id: 2668
    },
    141021: {
      name: '曲沃县',
      id: 2660
    },
    141022: {
      name: '翼城县',
      id: 2665
    },
    141023: {
      name: '襄汾县',
      id: 2663
    },
    141024: {
      name: '洪洞县',
      id: 2669
    },
    141025: {
      name: '古县',
      id: 2667
    },
    141026: {
      name: '安泽县',
      id: 2671
    },
    141027: {
      name: '浮山县',
      id: 2670
    },
    141028: {
      name: '吉县',
      id: 2673
    },
    141029: {
      name: '乡宁县',
      id: 2672
    },
    141030: {
      name: '大宁县',
      id: 2659
    },
    141031: {
      name: '隰县',
      id: 2662
    },
    141032: {
      name: '永和县',
      id: 2661
    },
    141033: {
      name: '蒲县',
      id: 2666
    },
    141034: {
      name: '汾西县',
      id: 2664
    },
    141081: {
      name: '侯马市',
      id: 2657
    },
    141082: {
      name: '霍州市',
      id: 2658
    },
    141102: {
      name: '离石区',
      id: 1854
    },
    141121: {
      name: '文水县',
      id: 1853
    },
    141122: {
      name: '交城县',
      id: 1852
    },
    141123: {
      name: '兴县',
      id: 1856
    },
    141124: {
      name: '临县',
      id: 1855
    },
    141125: {
      name: '柳林县',
      id: 1858
    },
    141126: {
      name: '石楼县',
      id: 1857
    },
    141127: {
      name: '岚县',
      id: 1860
    },
    141128: {
      name: '方山县',
      id: 1859
    },
    141129: {
      name: '中阳县',
      id: 1861
    },
    141130: {
      name: '交口县',
      id: 1851
    },
    141181: {
      name: '孝义市',
      id: 1850
    },
    141182: {
      name: '汾阳市',
      id: 1849
    },
    150102: {
      name: '新城区',
      id: 1113
    },
    150103: {
      name: '回民区',
      id: 1109
    },
    150104: {
      name: '玉泉区',
      id: 1111
    },
    150105: {
      name: '赛罕区',
      id: 1108
    },
    150121: {
      name: '土默特左旗',
      id: 1115
    },
    150122: {
      name: '托克托县',
      id: 1116
    },
    150123: {
      name: '和林格尔县',
      id: 1112
    },
    150124: {
      name: '清水河县',
      id: 1114
    },
    150125: {
      name: '武川县',
      id: 1110
    },
    150202: {
      name: '东河区',
      id: 865
    },
    150203: {
      name: '昆都仑区',
      id: 866
    },
    150204: {
      name: '青山区',
      id: 863
    },
    150205: {
      name: '石拐区',
      id: 864
    },
    150206: {
      name: '白云鄂博矿区',
      id: 861
    },
    150207: {
      name: '九原区',
      id: 862
    },
    150221: {
      name: '土默特右旗',
      id: 869
    },
    150222: {
      name: '固阳县',
      id: 867
    },
    150223: {
      name: '达尔罕茂明安联合旗',
      id: 868
    },
    150302: {
      name: '海勃湾区',
      id: 1608
    },
    150303: {
      name: '海南区',
      id: 1606
    },
    150304: {
      name: '乌达区',
      id: 1607
    },
    150402: {
      name: '红山区',
      id: 1329
    },
    150403: {
      name: '元宝山区',
      id: 1331
    },
    150404: {
      name: '松山区',
      id: 1327
    },
    150421: {
      name: '阿鲁科尔沁旗',
      id: 1335
    },
    150422: {
      name: '巴林左旗',
      id: 1333
    },
    150423: {
      name: '巴林右旗',
      id: 1334
    },
    150424: {
      name: '林西县',
      id: 1330
    },
    150425: {
      name: '克什克腾旗',
      id: 1332
    },
    150426: {
      name: '翁牛特旗',
      id: 1328
    },
    150428: {
      name: '喀喇沁旗',
      id: 1325
    },
    150429: {
      name: '宁城县',
      id: 1326
    },
    150430: {
      name: '敖汉旗',
      id: 1336
    },
    150502: {
      name: '科尔沁区',
      id: 286
    },
    150521: {
      name: '科尔沁左翼中旗',
      id: 288
    },
    150522: {
      name: '科尔沁左翼后旗',
      id: 289
    },
    150523: {
      name: '开鲁县',
      id: 285
    },
    150524: {
      name: '库伦旗',
      id: 287
    },
    150525: {
      name: '奈曼旗',
      id: 283
    },
    150526: {
      name: '扎鲁特旗',
      id: 284
    },
    150581: {
      name: '霍林郭勒市',
      id: 290
    },
    150602: {
      name: '东胜区',
      id: 87
    },
    150603: {
      name: '康巴什区',
      id: 89
    },
    150621: {
      name: '达拉特旗',
      id: 93
    },
    150622: {
      name: '准格尔旗',
      id: 91
    },
    150623: {
      name: '鄂托克前旗',
      id: 92
    },
    150624: {
      name: '鄂托克旗',
      id: 88
    },
    150625: {
      name: '杭锦旗',
      id: 90
    },
    150626: {
      name: '乌审旗',
      id: 85
    },
    150627: {
      name: '伊金霍洛旗',
      id: 86
    },
    150702: {
      name: '海拉尔区',
      id: 614
    },
    150703: {
      name: '扎赉诺尔区',
      id: 610
    },
    150721: {
      name: '阿荣旗',
      id: 616
    },
    150722: {
      name: '莫力达瓦达斡尔族自治旗',
      id: 617
    },
    150723: {
      name: '鄂伦春自治旗',
      id: 613
    },
    150724: {
      name: '鄂温克族自治旗',
      id: 615
    },
    150725: {
      name: '陈巴尔虎旗',
      id: 611
    },
    150726: {
      name: '新巴尔虎左旗',
      id: 612
    },
    150727: {
      name: '新巴尔虎右旗',
      id: 609
    },
    150781: {
      name: '满洲里市',
      id: 621
    },
    150782: {
      name: '牙克石市',
      id: 622
    },
    150783: {
      name: '扎兰屯市',
      id: 619
    },
    150784: {
      name: '额尔古纳市',
      id: 620
    },
    150785: {
      name: '根河市',
      id: 618
    },
    150802: {
      name: '临河区',
      id: 444
    },
    150821: {
      name: '五原县',
      id: 449
    },
    150822: {
      name: '磴口县',
      id: 447
    },
    150823: {
      name: '乌拉特前旗',
      id: 448
    },
    150824: {
      name: '乌拉特中旗',
      id: 445
    },
    150825: {
      name: '乌拉特后旗',
      id: 446
    },
    150826: {
      name: '杭锦后旗',
      id: 450
    },
    150902: {
      name: '集宁区',
      id: 2569
    },
    150921: {
      name: '卓资县',
      id: 2573
    },
    150922: {
      name: '化德县',
      id: 2572
    },
    150923: {
      name: '商都县',
      id: 2571
    },
    150924: {
      name: '兴和县',
      id: 2570
    },
    150925: {
      name: '凉城县',
      id: 2579
    },
    150926: {
      name: '察哈尔右翼前旗',
      id: 2578
    },
    150927: {
      name: '察哈尔右翼中旗',
      id: 2577
    },
    150928: {
      name: '察哈尔右翼后旗',
      id: 2576
    },
    150929: {
      name: '四子王旗',
      id: 2575
    },
    150981: {
      name: '丰镇市',
      id: 2574
    },
    152201: {
      name: '乌兰浩特市',
      id: 2355
    },
    152202: {
      name: '阿尔山市',
      id: 2353
    },
    152221: {
      name: '科尔沁右翼前旗',
      id: 2358
    },
    152222: {
      name: '科尔沁右翼中旗',
      id: 2357
    },
    152223: {
      name: '扎赉特旗',
      id: 2356
    },
    152224: {
      name: '突泉县',
      id: 2354
    },
    152501: {
      name: '二连浩特市',
      id: 877
    },
    152502: {
      name: '锡林浩特市',
      id: 875
    },
    152522: {
      name: '阿巴嘎旗',
      id: 879
    },
    152523: {
      name: '苏尼特左旗',
      id: 878
    },
    152524: {
      name: '苏尼特右旗',
      id: 876
    },
    152525: {
      name: '东乌珠穆沁旗',
      id: 874
    },
    152526: {
      name: '西乌珠穆沁旗',
      id: 873
    },
    152527: {
      name: '太仆寺旗',
      id: 872
    },
    152528: {
      name: '镶黄旗',
      id: 871
    },
    152529: {
      name: '正镶白旗',
      id: 870
    },
    152530: {
      name: '正蓝旗',
      id: 881
    },
    152531: {
      name: '多伦县',
      id: 880
    },
    152921: {
      name: '阿拉善左旗',
      id: 96
    },
    152922: {
      name: '阿拉善右旗',
      id: 95
    },
    152923: {
      name: '额济纳旗',
      id: 94
    },
    210102: {
      name: '和平区',
      id: 2069
    },
    210103: {
      name: '沈河区',
      id: 2067
    },
    210104: {
      name: '大东区',
      id: 2077
    },
    210105: {
      name: '皇姑区',
      id: 2076
    },
    210106: {
      name: '铁西区',
      id: 2075
    },
    210111: {
      name: '苏家屯区',
      id: 2074
    },
    210112: {
      name: '浑南区',
      id: 2072
    },
    210113: {
      name: '沈北新区',
      id: 2070
    },
    210114: {
      name: '于洪区',
      id: 2068
    },
    210115: {
      name: '辽中区',
      id: 2078
    },
    210123: {
      name: '康平县',
      id: 2073
    },
    210124: {
      name: '法库县',
      id: 2071
    },
    210181: {
      name: '新民市',
      id: 2066
    },
    210202: {
      name: '中山区',
      id: 1878
    },
    210203: {
      name: '西岗区',
      id: 1884
    },
    210204: {
      name: '沙河口区',
      id: 1883
    },
    210211: {
      name: '甘井子区',
      id: 1882
    },
    210212: {
      name: '旅顺口区',
      id: 1881
    },
    210213: {
      name: '金州区',
      id: 1879
    },
    210214: {
      name: '普兰店区',
      id: 1885
    },
    210224: {
      name: '长海县',
      id: 1880
    },
    210281: {
      name: '瓦房店市',
      id: 1876
    },
    210283: {
      name: '庄河市',
      id: 1877
    },
    210302: {
      name: '铁东区',
      id: 1687
    },
    210303: {
      name: '铁西区',
      id: 1686
    },
    210304: {
      name: '立山区',
      id: 1685
    },
    210311: {
      name: '千山区',
      id: 1683
    },
    210321: {
      name: '台安县',
      id: 1684
    },
    210323: {
      name: '岫岩满族自治县',
      id: 1682
    },
    210381: {
      name: '海城市',
      id: 1681
    },
    210402: {
      name: '新抚区',
      id: 1459
    },
    210403: {
      name: '东洲区',
      id: 1458
    },
    210404: {
      name: '望花区',
      id: 1457
    },
    210411: {
      name: '顺城区',
      id: 1454
    },
    210421: {
      name: '抚顺县',
      id: 1456
    },
    210422: {
      name: '新宾满族自治县',
      id: 1455
    },
    210423: {
      name: '清原满族自治县',
      id: 1460
    },
    210502: {
      name: '平山区',
      id: 1223
    },
    210503: {
      name: '溪湖区',
      id: 1222
    },
    210504: {
      name: '明山区',
      id: 1220
    },
    210505: {
      name: '南芬区',
      id: 1219
    },
    210521: {
      name: '本溪满族自治县',
      id: 1221
    },
    210522: {
      name: '桓仁满族自治县',
      id: 1224
    },
    210602: {
      name: '元宝区',
      id: 982
    },
    210603: {
      name: '振兴区',
      id: 979
    },
    210604: {
      name: '振安区',
      id: 978
    },
    210624: {
      name: '宽甸满族自治县',
      id: 983
    },
    210681: {
      name: '东港市',
      id: 981
    },
    210682: {
      name: '凤城市',
      id: 980
    },
    210702: {
      name: '古塔区',
      id: 730
    },
    210703: {
      name: '凌河区',
      id: 729
    },
    210711: {
      name: '太和区',
      id: 733
    },
    210726: {
      name: '黑山县',
      id: 728
    },
    210727: {
      name: '义县',
      id: 727
    },
    210781: {
      name: '凌海市',
      id: 731
    },
    210782: {
      name: '北镇市',
      id: 732
    },
    210802: {
      name: '站前区',
      id: 557
    },
    210803: {
      name: '西市区',
      id: 556
    },
    210804: {
      name: '鲅鱼圈区',
      id: 555
    },
    210811: {
      name: '老边区',
      id: 560
    },
    210881: {
      name: '盖州市',
      id: 559
    },
    210882: {
      name: '大石桥市',
      id: 558
    },
    210902: {
      name: '海州区',
      id: 388
    },
    210903: {
      name: '新邱区',
      id: 387
    },
    210904: {
      name: '太平区',
      id: 392
    },
    210905: {
      name: '清河门区',
      id: 391
    },
    210911: {
      name: '细河区',
      id: 389
    },
    210921: {
      name: '阜新蒙古族自治县',
      id: 393
    },
    210922: {
      name: '彰武县',
      id: 390
    },
    211002: {
      name: '白塔区',
      id: 2374
    },
    211003: {
      name: '文圣区',
      id: 2373
    },
    211004: {
      name: '宏伟区',
      id: 2372
    },
    211005: {
      name: '弓长岭区',
      id: 2371
    },
    211011: {
      name: '太子河区',
      id: 2369
    },
    211021: {
      name: '辽阳县',
      id: 2370
    },
    211081: {
      name: '灯塔市',
      id: 2375
    },
    211102: {
      name: '双台子区',
      id: 2163
    },
    211103: {
      name: '兴隆台区',
      id: 2162
    },
    211104: {
      name: '大洼区',
      id: 2161
    },
    211122: {
      name: '盘山县',
      id: 2160
    },
    211202: {
      name: '银州区',
      id: 2149
    },
    211204: {
      name: '清河区',
      id: 2152
    },
    211221: {
      name: '铁岭县',
      id: 2148
    },
    211223: {
      name: '西丰县',
      id: 2151
    },
    211224: {
      name: '昌图县',
      id: 2150
    },
    211281: {
      name: '调兵山市',
      id: 2147
    },
    211282: {
      name: '开原市',
      id: 2146
    },
    211302: {
      name: '双塔区',
      id: 2006
    },
    211303: {
      name: '龙城区',
      id: 2010
    },
    211321: {
      name: '朝阳县',
      id: 2009
    },
    211322: {
      name: '建平县',
      id: 2008
    },
    211324: {
      name: '喀喇沁左翼蒙古族自治县',
      id: 2007
    },
    211381: {
      name: '北票市',
      id: 2005
    },
    211382: {
      name: '凌源市',
      id: 2004
    },
    211402: {
      name: '连山区',
      id: 1816
    },
    211403: {
      name: '龙港区',
      id: 1815
    },
    211404: {
      name: '南票区',
      id: 1814
    },
    211421: {
      name: '绥中县',
      id: 1813
    },
    211422: {
      name: '建昌县',
      id: 1812
    },
    211481: {
      name: '兴城市',
      id: 1811
    },
    220102: {
      name: '南关区',
      id: 1745
    },
    220103: {
      name: '宽城区',
      id: 1749
    },
    220104: {
      name: '朝阳区',
      id: 1748
    },
    220105: {
      name: '二道区',
      id: 1751
    },
    220106: {
      name: '绿园区',
      id: 1750
    },
    220112: {
      name: '双阳区',
      id: 1747
    },
    220113: {
      name: '九台区',
      id: 1746
    },
    220122: {
      name: '农安县',
      id: 1744
    },
    220182: {
      name: '榆树市',
      id: 1742
    },
    220183: {
      name: '德惠市',
      id: 1743
    },
    220202: {
      name: '昌邑区',
      id: 1548
    },
    220203: {
      name: '龙潭区',
      id: 1547
    },
    220204: {
      name: '船营区',
      id: 1549
    },
    220211: {
      name: '丰满区',
      id: 1546
    },
    220221: {
      name: '永吉县',
      id: 1545
    },
    220281: {
      name: '蛟河市',
      id: 1541
    },
    220282: {
      name: '桦甸市',
      id: 1543
    },
    220283: {
      name: '舒兰市',
      id: 1542
    },
    220284: {
      name: '磐石市',
      id: 1544
    },
    220302: {
      name: '铁西区',
      id: 1261
    },
    220303: {
      name: '铁东区',
      id: 1263
    },
    220322: {
      name: '梨树县',
      id: 1260
    },
    220323: {
      name: '伊通满族自治县',
      id: 1262
    },
    220381: {
      name: '公主岭市',
      id: 1259
    },
    220382: {
      name: '双辽市',
      id: 1258
    },
    220402: {
      name: '龙山区',
      id: 1077
    },
    220403: {
      name: '西安区',
      id: 1076
    },
    220421: {
      name: '东丰县',
      id: 1074
    },
    220422: {
      name: '东辽县',
      id: 1075
    },
    220502: {
      name: '东昌区',
      id: 805
    },
    220503: {
      name: '二道江区',
      id: 801
    },
    220521: {
      name: '通化县',
      id: 804
    },
    220523: {
      name: '辉南县',
      id: 807
    },
    220524: {
      name: '柳河县',
      id: 806
    },
    220581: {
      name: '梅河口市',
      id: 803
    },
    220582: {
      name: '集安市',
      id: 802
    },
    220602: {
      name: '浑江区',
      id: 578
    },
    220605: {
      name: '江源区',
      id: 579
    },
    220621: {
      name: '抚松县',
      id: 581
    },
    220622: {
      name: '靖宇县',
      id: 583
    },
    220623: {
      name: '长白朝鲜族自治县',
      id: 582
    },
    220681: {
      name: '临江市',
      id: 580
    },
    220702: {
      name: '宁江区',
      id: 431
    },
    220721: {
      name: '前郭尔罗斯蒙古族自治县',
      id: 435
    },
    220722: {
      name: '长岭县',
      id: 434
    },
    220723: {
      name: '乾安县',
      id: 432
    },
    220781: {
      name: '扶余市',
      id: 433
    },
    220802: {
      name: '洮北区',
      id: 218
    },
    220821: {
      name: '镇赉县',
      id: 221
    },
    220822: {
      name: '通榆县',
      id: 217
    },
    220881: {
      name: '洮南市',
      id: 219
    },
    220882: {
      name: '大安市',
      id: 220
    },
    222401: {
      name: '延吉市',
      id: 1753
    },
    222402: {
      name: '图们市',
      id: 1757
    },
    222403: {
      name: '敦化市',
      id: 1759
    },
    222404: {
      name: '珲春市',
      id: 1754
    },
    222405: {
      name: '龙井市',
      id: 1756
    },
    222406: {
      name: '和龙市',
      id: 1752
    },
    222424: {
      name: '汪清县',
      id: 1758
    },
    222426: {
      name: '安图县',
      id: 1755
    },
    230102: {
      name: '道里区',
      id: 1313
    },
    230103: {
      name: '南岗区',
      id: 1311
    },
    230104: {
      name: '道外区',
      id: 1309
    },
    230108: {
      name: '平房区',
      id: 1321
    },
    230109: {
      name: '松北区',
      id: 1320
    },
    230110: {
      name: '香坊区',
      id: 1319
    },
    230111: {
      name: '呼兰区',
      id: 1318
    },
    230112: {
      name: '阿城区',
      id: 1316
    },
    230113: {
      name: '双城区',
      id: 1314
    },
    230123: {
      name: '依兰县',
      id: 1317
    },
    230124: {
      name: '方正县',
      id: 1315
    },
    230125: {
      name: '宾县',
      id: 1312
    },
    230126: {
      name: '巴彦县',
      id: 1310
    },
    230127: {
      name: '木兰县',
      id: 1308
    },
    230128: {
      name: '通河县',
      id: 1323
    },
    230129: {
      name: '延寿县',
      id: 1322
    },
    230183: {
      name: '尚志市',
      id: 1307
    },
    230184: {
      name: '五常市',
      id: 1306
    },
    230202: {
      name: '龙沙区',
      id: 1082
    },
    230203: {
      name: '建华区',
      id: 1080
    },
    230204: {
      name: '铁锋区',
      id: 1079
    },
    230205: {
      name: '昂昂溪区',
      id: 1092
    },
    230206: {
      name: '富拉尔基区',
      id: 1091
    },
    230207: {
      name: '碾子山区',
      id: 1089
    },
    230208: {
      name: '梅里斯达斡尔族区',
      id: 1088
    },
    230221: {
      name: '龙江县',
      id: 1085
    },
    230223: {
      name: '依安县',
      id: 1084
    },
    230224: {
      name: '泰来县',
      id: 1083
    },
    230225: {
      name: '甘南县',
      id: 1081
    },
    230227: {
      name: '富裕县',
      id: 1093
    },
    230229: {
      name: '克山县',
      id: 1090
    },
    230230: {
      name: '克东县',
      id: 1087
    },
    230231: {
      name: '拜泉县',
      id: 1086
    },
    230281: {
      name: '讷河市',
      id: 1078
    },
    230302: {
      name: '鸡冠区',
      id: 840
    },
    230303: {
      name: '恒山区',
      id: 839
    },
    230304: {
      name: '滴道区',
      id: 847
    },
    230305: {
      name: '梨树区',
      id: 846
    },
    230306: {
      name: '城子河区',
      id: 845
    },
    230307: {
      name: '麻山区',
      id: 844
    },
    230321: {
      name: '鸡东县',
      id: 841
    },
    230381: {
      name: '虎林市',
      id: 843
    },
    230382: {
      name: '密山市',
      id: 842
    },
    230402: {
      name: '向阳区',
      id: 594
    },
    230403: {
      name: '工农区',
      id: 601
    },
    230404: {
      name: '南山区',
      id: 600
    },
    230405: {
      name: '兴安区',
      id: 599
    },
    230406: {
      name: '东山区',
      id: 598
    },
    230407: {
      name: '兴山区',
      id: 597
    },
    230421: {
      name: '萝北县',
      id: 596
    },
    230422: {
      name: '绥滨县',
      id: 595
    },
    230502: {
      name: '尖山区',
      id: 442
    },
    230503: {
      name: '岭东区',
      id: 441
    },
    230505: {
      name: '四方台区',
      id: 440
    },
    230506: {
      name: '宝山区',
      id: 439
    },
    230521: {
      name: '集贤县',
      id: 438
    },
    230522: {
      name: '友谊县',
      id: 437
    },
    230523: {
      name: '宝清县',
      id: 436
    },
    230524: {
      name: '饶河县',
      id: 443
    },
    230602: {
      name: '萨尔图区',
      id: 280
    },
    230603: {
      name: '龙凤区',
      id: 279
    },
    230604: {
      name: '让胡路区',
      id: 278
    },
    230605: {
      name: '红岗区',
      id: 277
    },
    230606: {
      name: '大同区',
      id: 276
    },
    230621: {
      name: '肇州县',
      id: 275
    },
    230622: {
      name: '肇源县',
      id: 274
    },
    230623: {
      name: '林甸县',
      id: 282
    },
    230624: {
      name: '杜尔伯特蒙古族自治县',
      id: 281
    },
    230702: {
      name: '伊春区',
      id: 155
    },
    230703: {
      name: '南岔区',
      id: 153
    },
    230704: {
      name: '友好区',
      id: 151
    },
    230705: {
      name: '西林区',
      id: 149
    },
    230706: {
      name: '翠峦区',
      id: 148
    },
    230707: {
      name: '新青区',
      id: 147
    },
    230708: {
      name: '美溪区',
      id: 144
    },
    230709: {
      name: '金山屯区',
      id: 143
    },
    230710: {
      name: '五营区',
      id: 145
    },
    230711: {
      name: '乌马河区',
      id: 158
    },
    230712: {
      name: '汤旺河区',
      id: 157
    },
    230713: {
      name: '带岭区',
      id: 156
    },
    230714: {
      name: '乌伊岭区',
      id: 154
    },
    230715: {
      name: '红星区',
      id: 152
    },
    230716: {
      name: '上甘岭区',
      id: 150
    },
    230722: {
      name: '嘉荫县',
      id: 159
    },
    230781: {
      name: '铁力市',
      id: 146
    },
    230803: {
      name: '向阳区',
      id: 2881
    },
    230804: {
      name: '前进区',
      id: 2879
    },
    230805: {
      name: '东风区',
      id: 2878
    },
    230811: {
      name: '郊区',
      id: 2882
    },
    230822: {
      name: '桦南县',
      id: 2883
    },
    230826: {
      name: '桦川县',
      id: 2880
    },
    230828: {
      name: '汤原县',
      id: 2877
    },
    230881: {
      name: '同江市',
      id: 2876
    },
    230882: {
      name: '富锦市',
      id: 2875
    },
    230883: {
      name: '抚远市',
      id: 2874
    },
    230902: {
      name: '新兴区',
      id: 2739
    },
    230903: {
      name: '桃山区',
      id: 2738
    },
    230904: {
      name: '茄子河区',
      id: 2737
    },
    230921: {
      name: '勃利县',
      id: 2740
    },
    231002: {
      name: '东安区',
      id: 1675
    },
    231003: {
      name: '阳明区',
      id: 1673
    },
    231004: {
      name: '爱民区',
      id: 1672
    },
    231005: {
      name: '西安区',
      id: 1671
    },
    231025: {
      name: '林口县',
      id: 1674
    },
    231081: {
      name: '绥芬河市',
      id: 1680
    },
    231083: {
      name: '海林市',
      id: 1679
    },
    231084: {
      name: '宁安市',
      id: 1678
    },
    231085: {
      name: '穆棱市',
      id: 1677
    },
    231086: {
      name: '东宁市',
      id: 1676
    },
    231102: {
      name: '爱辉区',
      id: 1465
    },
    231121: {
      name: '嫩江县',
      id: 1463
    },
    231123: {
      name: '逊克县',
      id: 1464
    },
    231124: {
      name: '孙吴县',
      id: 1466
    },
    231181: {
      name: '北安市',
      id: 1461
    },
    231182: {
      name: '五大连池市',
      id: 1462
    },
    231202: {
      name: '北林区',
      id: 1203
    },
    231221: {
      name: '望奎县',
      id: 1200
    },
    231222: {
      name: '兰西县',
      id: 1201
    },
    231223: {
      name: '青冈县',
      id: 1202
    },
    231224: {
      name: '庆安县',
      id: 1204
    },
    231225: {
      name: '明水县',
      id: 1205
    },
    231226: {
      name: '绥棱县',
      id: 1206
    },
    231281: {
      name: '安达市',
      id: 1197
    },
    231282: {
      name: '肇东市',
      id: 1198
    },
    231283: {
      name: '海伦市',
      id: 1199
    },
    232701: {
      name: '加格达奇区',
      id: 675
    },
    232721: {
      name: '呼玛县',
      id: 673
    },
    232722: {
      name: '塔河县',
      id: 674
    },
    232723: {
      name: '漠河县',
      id: 676
    },
    310101: {
      name: '黄浦区',
      id: 2189
    },
    310104: {
      name: '徐汇区',
      id: 2193
    },
    310105: {
      name: '长宁区',
      id: 2195
    },
    310106: {
      name: '静安区',
      id: 2197
    },
    310107: {
      name: '普陀区',
      id: 2199
    },
    310109: {
      name: '虹口区',
      id: 2201
    },
    310110: {
      name: '杨浦区',
      id: 2188
    },
    310112: {
      name: '闵行区',
      id: 2190
    },
    310113: {
      name: '宝山区',
      id: 2191
    },
    310114: {
      name: '嘉定区',
      id: 2192
    },
    310115: {
      name: '浦东新区',
      id: 2194
    },
    310116: {
      name: '金山区',
      id: 2196
    },
    310117: {
      name: '松江区',
      id: 2198
    },
    310118: {
      name: '青浦区',
      id: 2200
    },
    310120: {
      name: '奉贤区',
      id: 2187
    },
    310151: {
      name: '崇明区',
      id: 2186
    },
    320102: {
      name: '玄武区',
      id: 2629
    },
    320104: {
      name: '秦淮区',
      id: 2625
    },
    320105: {
      name: '建邺区',
      id: 2627
    },
    320106: {
      name: '鼓楼区',
      id: 2622
    },
    320111: {
      name: '浦口区',
      id: 2632
    },
    320113: {
      name: '栖霞区',
      id: 2630
    },
    320114: {
      name: '雨花台区',
      id: 2631
    },
    320115: {
      name: '江宁区',
      id: 2626
    },
    320116: {
      name: '六合区',
      id: 2628
    },
    320117: {
      name: '溧水区',
      id: 2623
    },
    320118: {
      name: '高淳区',
      id: 2624
    },
    320205: {
      name: '锡山区',
      id: 2743
    },
    320206: {
      name: '惠山区',
      id: 2744
    },
    320211: {
      name: '滨湖区',
      id: 2747
    },
    320213: {
      name: '梁溪区',
      id: 2746
    },
    320214: {
      name: '新吴区',
      id: 2745
    },
    320281: {
      name: '江阴市',
      id: 2741
    },
    320282: {
      name: '宜兴市',
      id: 2742
    },
    320302: {
      name: '鼓楼区',
      id: 2867
    },
    320303: {
      name: '云龙区',
      id: 2869
    },
    320305: {
      name: '贾汪区',
      id: 2866
    },
    320311: {
      name: '泉山区',
      id: 2870
    },
    320312: {
      name: '铜山区',
      id: 2872
    },
    320321: {
      name: '丰县',
      id: 2873
    },
    320322: {
      name: '沛县',
      id: 2871
    },
    320324: {
      name: '睢宁县',
      id: 2868
    },
    320381: {
      name: '新沂市',
      id: 2865
    },
    320382: {
      name: '邳州市',
      id: 2864
    },
    320402: {
      name: '天宁区',
      id: 140
    },
    320404: {
      name: '钟楼区',
      id: 138
    },
    320411: {
      name: '新北区',
      id: 142
    },
    320412: {
      name: '武进区',
      id: 139
    },
    320413: {
      name: '金坛区',
      id: 141
    },
    320481: {
      name: '溧阳市',
      id: 137
    },
    320505: {
      name: '虎丘区',
      id: 328
    },
    320506: {
      name: '吴中区',
      id: 326
    },
    320507: {
      name: '相城区',
      id: 327
    },
    320508: {
      name: '姑苏区',
      id: 323
    },
    320509: {
      name: '吴江区',
      id: 324
    },
    320581: {
      name: '常熟市',
      id: 325
    },
    320582: {
      name: '张家港市',
      id: 321
    },
    320583: {
      name: '昆山市',
      id: 322
    },
    320585: {
      name: '太仓市',
      id: 329
    },
    320602: {
      name: '崇川区',
      id: 479
    },
    320611: {
      name: '港闸区',
      id: 481
    },
    320612: {
      name: '通州区',
      id: 477
    },
    320621: {
      name: '海安县',
      id: 480
    },
    320623: {
      name: '如东县',
      id: 478
    },
    320681: {
      name: '启东市',
      id: 475
    },
    320682: {
      name: '如皋市',
      id: 476
    },
    320684: {
      name: '海门市',
      id: 482
    },
    320703: {
      name: '连云区',
      id: 634
    },
    320706: {
      name: '海州区',
      id: 631
    },
    320707: {
      name: '赣榆区',
      id: 632
    },
    320722: {
      name: '东海县',
      id: 635
    },
    320723: {
      name: '灌云县',
      id: 636
    },
    320724: {
      name: '灌南县',
      id: 633
    },
    320803: {
      name: '淮安区',
      id: 882
    },
    320804: {
      name: '淮阴区',
      id: 883
    },
    320812: {
      name: '清江浦区',
      id: 885
    },
    320813: {
      name: '洪泽区',
      id: 886
    },
    320826: {
      name: '涟水县',
      id: 884
    },
    320830: {
      name: '盱眙县',
      id: 887
    },
    320831: {
      name: '金湖县',
      id: 888
    },
    320902: {
      name: '亭湖区',
      id: 1100
    },
    320903: {
      name: '盐都区',
      id: 1102
    },
    320904: {
      name: '大丰区',
      id: 1099
    },
    320921: {
      name: '响水县',
      id: 1106
    },
    320922: {
      name: '滨海县',
      id: 1104
    },
    320923: {
      name: '阜宁县',
      id: 1105
    },
    320924: {
      name: '射阳县',
      id: 1101
    },
    320925: {
      name: '建湖县',
      id: 1103
    },
    320981: {
      name: '东台市',
      id: 1107
    },
    321002: {
      name: '广陵区',
      id: 2250
    },
    321003: {
      name: '邗江区',
      id: 2251
    },
    321012: {
      name: '江都区',
      id: 2246
    },
    321023: {
      name: '宝应县',
      id: 2247
    },
    321081: {
      name: '仪征市',
      id: 2249
    },
    321084: {
      name: '高邮市',
      id: 2248
    },
    321102: {
      name: '京口区',
      id: 2441
    },
    321111: {
      name: '润州区',
      id: 2436
    },
    321112: {
      name: '丹徒区',
      id: 2440
    },
    321181: {
      name: '丹阳市',
      id: 2438
    },
    321182: {
      name: '扬中市',
      id: 2439
    },
    321183: {
      name: '句容市',
      id: 2437
    },
    321202: {
      name: '海陵区',
      id: 2434
    },
    321203: {
      name: '高港区',
      id: 2435
    },
    321204: {
      name: '姜堰区',
      id: 2433
    },
    321281: {
      name: '兴化市',
      id: 2432
    },
    321282: {
      name: '靖江市',
      id: 2430
    },
    321283: {
      name: '泰兴市',
      id: 2431
    },
    321302: {
      name: '宿城区',
      id: 2538
    },
    321311: {
      name: '宿豫区',
      id: 2540
    },
    321322: {
      name: '沭阳县',
      id: 2541
    },
    321323: {
      name: '泗阳县',
      id: 2537
    },
    321324: {
      name: '泗洪县',
      id: 2539
    },
    330102: {
      name: '上城区',
      id: 2795
    },
    330103: {
      name: '下城区',
      id: 2786
    },
    330104: {
      name: '江干区',
      id: 2787
    },
    330105: {
      name: '拱墅区',
      id: 2788
    },
    330106: {
      name: '西湖区',
      id: 2790
    },
    330108: {
      name: '滨江区',
      id: 2784
    },
    330109: {
      name: '萧山区',
      id: 2785
    },
    330110: {
      name: '余杭区',
      id: 2791
    },
    330111: {
      name: '富阳区',
      id: 2792
    },
    330112: {
      name: '临安区',
      id: 2794
    },
    330122: {
      name: '桐庐县',
      id: 2793
    },
    330127: {
      name: '淳安县',
      id: 2789
    },
    330182: {
      name: '建德市',
      id: 2796
    },
    330203: {
      name: '海曙区',
      id: 40
    },
    330205: {
      name: '江北区',
      id: 43
    },
    330206: {
      name: '北仑区',
      id: 37
    },
    330211: {
      name: '镇海区',
      id: 44
    },
    330212: {
      name: '鄞州区',
      id: 45
    },
    330213: {
      name: '奉化区',
      id: 39
    },
    330225: {
      name: '象山县',
      id: 41
    },
    330226: {
      name: '宁海县',
      id: 42
    },
    330281: {
      name: '余姚市',
      id: 38
    },
    330282: {
      name: '慈溪市',
      id: 36
    },
    330302: {
      name: '鹿城区',
      id: 191
    },
    330303: {
      name: '龙湾区',
      id: 193
    },
    330304: {
      name: '瓯海区',
      id: 194
    },
    330305: {
      name: '洞头区',
      id: 187
    },
    330324: {
      name: '永嘉县',
      id: 192
    },
    330326: {
      name: '平阳县',
      id: 195
    },
    330327: {
      name: '苍南县',
      id: 188
    },
    330328: {
      name: '文成县',
      id: 189
    },
    330329: {
      name: '泰顺县',
      id: 190
    },
    330381: {
      name: '瑞安市',
      id: 185
    },
    330382: {
      name: '乐清市',
      id: 186
    },
    330402: {
      name: '南湖区',
      id: 365
    },
    330411: {
      name: '秀洲区',
      id: 364
    },
    330421: {
      name: '嘉善县',
      id: 367
    },
    330424: {
      name: '海盐县',
      id: 366
    },
    330481: {
      name: '海宁市',
      id: 361
    },
    330482: {
      name: '平湖市',
      id: 362
    },
    330483: {
      name: '桐乡市',
      id: 363
    },
    330502: {
      name: '吴兴区',
      id: 524
    },
    330503: {
      name: '南浔区',
      id: 520
    },
    330521: {
      name: '德清县',
      id: 521
    },
    330522: {
      name: '长兴县',
      id: 522
    },
    330523: {
      name: '安吉县',
      id: 523
    },
    330602: {
      name: '越城区',
      id: 717
    },
    330603: {
      name: '柯桥区',
      id: 719
    },
    330604: {
      name: '上虞区',
      id: 720
    },
    330624: {
      name: '新昌县',
      id: 718
    },
    330681: {
      name: '诸暨市',
      id: 716
    },
    330683: {
      name: '嵊州市',
      id: 721
    },
    330702: {
      name: '婺城区',
      id: 939
    },
    330703: {
      name: '金东区',
      id: 940
    },
    330723: {
      name: '武义县',
      id: 938
    },
    330726: {
      name: '浦江县',
      id: 941
    },
    330727: {
      name: '磐安县',
      id: 937
    },
    330781: {
      name: '兰溪市',
      id: 942
    },
    330782: {
      name: '义乌市',
      id: 943
    },
    330783: {
      name: '东阳市',
      id: 944
    },
    330784: {
      name: '永康市',
      id: 945
    },
    330802: {
      name: '柯城区',
      id: 1150
    },
    330803: {
      name: '衢江区',
      id: 1152
    },
    330822: {
      name: '常山县',
      id: 1149
    },
    330824: {
      name: '开化县',
      id: 1151
    },
    330825: {
      name: '龙游县',
      id: 1153
    },
    330881: {
      name: '江山市',
      id: 1154
    },
    330902: {
      name: '定海区',
      id: 1402
    },
    330903: {
      name: '普陀区',
      id: 1399
    },
    330921: {
      name: '岱山县',
      id: 1400
    },
    330922: {
      name: '嵊泗县',
      id: 1401
    },
    331002: {
      name: '椒江区',
      id: 2522
    },
    331003: {
      name: '黄岩区',
      id: 2524
    },
    331004: {
      name: '路桥区',
      id: 2525
    },
    331022: {
      name: '三门县',
      id: 2517
    },
    331023: {
      name: '天台县',
      id: 2521
    },
    331024: {
      name: '仙居县',
      id: 2523
    },
    331081: {
      name: '温岭市',
      id: 2518
    },
    331082: {
      name: '临海市',
      id: 2519
    },
    331083: {
      name: '玉环市',
      id: 2520
    },
    331102: {
      name: '莲都区',
      id: 2566
    },
    331121: {
      name: '青田县',
      id: 2560
    },
    331122: {
      name: '缙云县',
      id: 2564
    },
    331123: {
      name: '遂昌县',
      id: 2565
    },
    331124: {
      name: '松阳县',
      id: 2567
    },
    331125: {
      name: '云和县',
      id: 2568
    },
    331126: {
      name: '庆元县',
      id: 2562
    },
    331127: {
      name: '景宁畲族自治县',
      id: 2563
    },
    331181: {
      name: '龙泉市',
      id: 2561
    },
    340102: {
      name: '瑶海区',
      id: 202
    },
    340103: {
      name: '庐阳区',
      id: 204
    },
    340104: {
      name: '蜀山区',
      id: 201
    },
    340111: {
      name: '包河区',
      id: 197
    },
    340121: {
      name: '长丰县',
      id: 200
    },
    340122: {
      name: '肥东县',
      id: 198
    },
    340123: {
      name: '肥西县',
      id: 199
    },
    340124: {
      name: '庐江县',
      id: 203
    },
    340181: {
      name: '巢湖市',
      id: 196
    },
    340202: {
      name: '镜湖区',
      id: 407
    },
    340203: {
      name: '弋江区',
      id: 404
    },
    340207: {
      name: '鸠江区',
      id: 400
    },
    340208: {
      name: '三山区',
      id: 401
    },
    340221: {
      name: '芜湖县',
      id: 402
    },
    340222: {
      name: '繁昌县',
      id: 403
    },
    340223: {
      name: '南陵县',
      id: 406
    },
    340225: {
      name: '无为县',
      id: 405
    },
    340302: {
      name: '龙子湖区',
      id: 564
    },
    340303: {
      name: '蚌山区',
      id: 565
    },
    340304: {
      name: '禹会区',
      id: 562
    },
    340311: {
      name: '淮上区',
      id: 566
    },
    340321: {
      name: '怀远县',
      id: 563
    },
    340322: {
      name: '五河县',
      id: 567
    },
    340323: {
      name: '固镇县',
      id: 568
    },
    340402: {
      name: '大通区',
      id: 738
    },
    340403: {
      name: '田家庵区',
      id: 736
    },
    340404: {
      name: '谢家集区',
      id: 737
    },
    340405: {
      name: '八公山区',
      id: 734
    },
    340406: {
      name: '潘集区',
      id: 735
    },
    340421: {
      name: '凤台县',
      id: 739
    },
    340422: {
      name: '寿县',
      id: 740
    },
    340503: {
      name: '花山区',
      id: 973
    },
    340504: {
      name: '雨山区',
      id: 972
    },
    340506: {
      name: '博望区',
      id: 974
    },
    340521: {
      name: '当涂县',
      id: 977
    },
    340522: {
      name: '含山县',
      id: 975
    },
    340523: {
      name: '和县',
      id: 976
    },
    340602: {
      name: '杜集区',
      id: 1217
    },
    340603: {
      name: '相山区',
      id: 1215
    },
    340604: {
      name: '烈山区',
      id: 1216
    },
    340621: {
      name: '濉溪县',
      id: 1218
    },
    340705: {
      name: '铜官区',
      id: 1453
    },
    340706: {
      name: '义安区',
      id: 1452
    },
    340711: {
      name: '郊区',
      id: 1450
    },
    340722: {
      name: '枞阳县',
      id: 1451
    },
    340802: {
      name: '迎江区',
      id: 1656
    },
    340803: {
      name: '大观区',
      id: 1662
    },
    340811: {
      name: '宜秀区',
      id: 1658
    },
    340822: {
      name: '怀宁县',
      id: 1659
    },
    340824: {
      name: '潜山县',
      id: 1657
    },
    340825: {
      name: '太湖县',
      id: 1663
    },
    340826: {
      name: '宿松县',
      id: 1664
    },
    340827: {
      name: '望江县',
      id: 1660
    },
    340828: {
      name: '岳西县',
      id: 1661
    },
    340881: {
      name: '桐城市',
      id: 1665
    },
    341002: {
      name: '屯溪区',
      id: 2731
    },
    341003: {
      name: '黄山区',
      id: 2733
    },
    341004: {
      name: '徽州区',
      id: 2736
    },
    341021: {
      name: '歙县',
      id: 2730
    },
    341022: {
      name: '休宁县',
      id: 2734
    },
    341023: {
      name: '黟县',
      id: 2735
    },
    341024: {
      name: '祁门县',
      id: 2732
    },
    341102: {
      name: '琅琊区',
      id: 2858
    },
    341103: {
      name: '南谯区',
      id: 2861
    },
    341122: {
      name: '来安县',
      id: 2860
    },
    341124: {
      name: '全椒县',
      id: 2859
    },
    341125: {
      name: '定远县',
      id: 2862
    },
    341126: {
      name: '凤阳县',
      id: 2863
    },
    341181: {
      name: '天长市',
      id: 2856
    },
    341182: {
      name: '明光市',
      id: 2857
    },
    341202: {
      name: '颍州区',
      id: 102
    },
    341203: {
      name: '颍东区',
      id: 103
    },
    341204: {
      name: '颍泉区',
      id: 100
    },
    341221: {
      name: '临泉县',
      id: 99
    },
    341222: {
      name: '太和县',
      id: 98
    },
    341225: {
      name: '阜南县',
      id: 104
    },
    341226: {
      name: '颍上县',
      id: 101
    },
    341282: {
      name: '界首市',
      id: 97
    },
    341302: {
      name: '埇桥区',
      id: 301
    },
    341321: {
      name: '砀山县',
      id: 298
    },
    341322: {
      name: '萧县',
      id: 299
    },
    341323: {
      name: '灵璧县',
      id: 300
    },
    341324: {
      name: '泗县',
      id: 302
    },
    341502: {
      name: '金安区',
      id: 680
    },
    341503: {
      name: '裕安区',
      id: 678
    },
    341504: {
      name: '叶集区',
      id: 677
    },
    341522: {
      name: '霍邱县',
      id: 683
    },
    341523: {
      name: '舒城县',
      id: 682
    },
    341524: {
      name: '金寨县',
      id: 681
    },
    341525: {
      name: '霍山县',
      id: 679
    },
    341602: {
      name: '谯城区',
      id: 912
    },
    341621: {
      name: '涡阳县',
      id: 915
    },
    341622: {
      name: '蒙城县',
      id: 914
    },
    341623: {
      name: '利辛县',
      id: 913
    },
    341702: {
      name: '贵池区',
      id: 1117
    },
    341721: {
      name: '东至县',
      id: 1120
    },
    341722: {
      name: '石台县',
      id: 1119
    },
    341723: {
      name: '青阳县',
      id: 1118
    },
    341802: {
      name: '宣州区',
      id: 1298
    },
    341821: {
      name: '郎溪县',
      id: 1303
    },
    341822: {
      name: '广德县',
      id: 1301
    },
    341823: {
      name: '泾县',
      id: 1300
    },
    341824: {
      name: '绩溪县',
      id: 1299
    },
    341825: {
      name: '旌德县',
      id: 1297
    },
    341881: {
      name: '宁国市',
      id: 1302
    },
    350102: {
      name: '鼓楼区',
      id: 2466
    },
    350103: {
      name: '台江区',
      id: 2468
    },
    350104: {
      name: '仓山区',
      id: 2470
    },
    350105: {
      name: '马尾区',
      id: 2459
    },
    350111: {
      name: '晋安区',
      id: 2462
    },
    350112: {
      name: '长乐区',
      id: 2464
    },
    350121: {
      name: '闽侯县',
      id: 2461
    },
    350122: {
      name: '连江县',
      id: 2463
    },
    350123: {
      name: '罗源县',
      id: 2465
    },
    350124: {
      name: '闽清县',
      id: 2467
    },
    350125: {
      name: '永泰县',
      id: 2469
    },
    350128: {
      name: '平潭县',
      id: 2460
    },
    350181: {
      name: '福清市',
      id: 2471
    },
    350203: {
      name: '思明区',
      id: 2293
    },
    350205: {
      name: '海沧区',
      id: 2288
    },
    350206: {
      name: '湖里区',
      id: 2289
    },
    350211: {
      name: '集美区',
      id: 2290
    },
    350212: {
      name: '同安区',
      id: 2291
    },
    350213: {
      name: '翔安区',
      id: 2292
    },
    350302: {
      name: '城厢区',
      id: 2118
    },
    350303: {
      name: '涵江区',
      id: 2114
    },
    350304: {
      name: '荔城区',
      id: 2115
    },
    350305: {
      name: '秀屿区',
      id: 2116
    },
    350322: {
      name: '仙游县',
      id: 2117
    },
    350402: {
      name: '梅列区',
      id: 1968
    },
    350403: {
      name: '三元区',
      id: 1970
    },
    350421: {
      name: '明溪县',
      id: 1978
    },
    350423: {
      name: '清流县',
      id: 1979
    },
    350424: {
      name: '宁化县',
      id: 1969
    },
    350425: {
      name: '大田县',
      id: 1971
    },
    350426: {
      name: '尤溪县',
      id: 1972
    },
    350427: {
      name: '沙县',
      id: 1973
    },
    350428: {
      name: '将乐县',
      id: 1974
    },
    350429: {
      name: '泰宁县',
      id: 1975
    },
    350430: {
      name: '建宁县',
      id: 1977
    },
    350481: {
      name: '永安市',
      id: 1976
    },
    350502: {
      name: '鲤城区',
      id: 1794
    },
    350503: {
      name: '丰泽区',
      id: 1796
    },
    350504: {
      name: '洛江区',
      id: 1798
    },
    350505: {
      name: '泉港区',
      id: 1800
    },
    350521: {
      name: '惠安县',
      id: 1805
    },
    350524: {
      name: '安溪县',
      id: 1795
    },
    350525: {
      name: '永春县',
      id: 1797
    },
    350526: {
      name: '德化县',
      id: 1799
    },
    350527: {
      name: '金门县',
      id: 1801
    },
    350581: {
      name: '石狮市',
      id: 1802
    },
    350582: {
      name: '晋江市',
      id: 1803
    },
    350583: {
      name: '南安市',
      id: 1804
    },
    350602: {
      name: '芗城区',
      id: 1486
    },
    350603: {
      name: '龙文区',
      id: 1488
    },
    350622: {
      name: '云霄县',
      id: 1484
    },
    350623: {
      name: '漳浦县',
      id: 1485
    },
    350624: {
      name: '诏安县',
      id: 1487
    },
    350625: {
      name: '长泰县',
      id: 1489
    },
    350626: {
      name: '东山县',
      id: 1490
    },
    350627: {
      name: '南靖县',
      id: 1491
    },
    350628: {
      name: '平和县',
      id: 1492
    },
    350629: {
      name: '华安县',
      id: 1493
    },
    350681: {
      name: '龙海市',
      id: 1494
    },
    350702: {
      name: '延平区',
      id: 1228
    },
    350703: {
      name: '建阳区',
      id: 1230
    },
    350721: {
      name: '顺昌县',
      id: 1225
    },
    350722: {
      name: '浦城县',
      id: 1226
    },
    350723: {
      name: '光泽县',
      id: 1227
    },
    350724: {
      name: '松溪县',
      id: 1229
    },
    350725: {
      name: '政和县',
      id: 1231
    },
    350781: {
      name: '邵武市',
      id: 1232
    },
    350782: {
      name: '武夷山市',
      id: 1233
    },
    350783: {
      name: '建瓯市',
      id: 1234
    },
    350802: {
      name: '新罗区',
      id: 995
    },
    350803: {
      name: '永定区',
      id: 997
    },
    350821: {
      name: '长汀县',
      id: 993
    },
    350823: {
      name: '上杭县',
      id: 994
    },
    350824: {
      name: '武平县',
      id: 996
    },
    350825: {
      name: '连城县',
      id: 998
    },
    350881: {
      name: '漳平市',
      id: 999
    },
    350902: {
      name: '蕉城区',
      id: 753
    },
    350921: {
      name: '霞浦县',
      id: 750
    },
    350922: {
      name: '古田县',
      id: 751
    },
    350923: {
      name: '屏南县',
      id: 752
    },
    350924: {
      name: '寿宁县',
      id: 754
    },
    350925: {
      name: '周宁县',
      id: 755
    },
    350926: {
      name: '柘荣县',
      id: 756
    },
    350981: {
      name: '福安市',
      id: 757
    },
    350982: {
      name: '福鼎市',
      id: 758
    },
    360102: {
      name: '东湖区',
      id: 2119
    },
    360103: {
      name: '西湖区',
      id: 2121
    },
    360104: {
      name: '青云谱区',
      id: 2126
    },
    360105: {
      name: '湾里区',
      id: 2127
    },
    360111: {
      name: '青山湖区',
      id: 2122
    },
    360112: {
      name: '新建区',
      id: 2123
    },
    360121: {
      name: '南昌县',
      id: 2125
    },
    360123: {
      name: '安义县',
      id: 2124
    },
    360124: {
      name: '进贤县',
      id: 2120
    },
    360202: {
      name: '昌江区',
      id: 1980
    },
    360203: {
      name: '珠山区',
      id: 1983
    },
    360222: {
      name: '浮梁县',
      id: 1981
    },
    360281: {
      name: '乐平市',
      id: 1982
    },
    360302: {
      name: '安源区',
      id: 1809
    },
    360313: {
      name: '湘东区',
      id: 1810
    },
    360321: {
      name: '莲花县',
      id: 1808
    },
    360322: {
      name: '上栗县',
      id: 1806
    },
    360323: {
      name: '芦溪县',
      id: 1807
    },
    360402: {
      name: '濂溪区',
      id: 1572
    },
    360403: {
      name: '浔阳区',
      id: 1567
    },
    360404: {
      name: '柴桑区',
      id: 1569
    },
    360423: {
      name: '武宁县',
      id: 1571
    },
    360424: {
      name: '修水县',
      id: 1573
    },
    360425: {
      name: '永修县',
      id: 1568
    },
    360426: {
      name: '德安县',
      id: 1570
    },
    360428: {
      name: '都昌县',
      id: 1566
    },
    360429: {
      name: '湖口县',
      id: 1565
    },
    360430: {
      name: '彭泽县',
      id: 1561
    },
    360481: {
      name: '瑞昌市',
      id: 1563
    },
    360482: {
      name: '共青城市',
      id: 1564
    },
    360483: {
      name: '庐山市',
      id: 1562
    },
    360502: {
      name: '渝水区',
      id: 1359
    },
    360521: {
      name: '分宜县',
      id: 1358
    },
    360602: {
      name: '月湖区',
      id: 1129
    },
    360622: {
      name: '余江县',
      id: 1130
    },
    360681: {
      name: '贵溪市',
      id: 1128
    },
    360702: {
      name: '章贡区',
      id: 896
    },
    360703: {
      name: '南康区',
      id: 899
    },
    360704: {
      name: '赣县区',
      id: 893
    },
    360722: {
      name: '信丰县',
      id: 901
    },
    360723: {
      name: '大余县',
      id: 903
    },
    360724: {
      name: '上犹县',
      id: 897
    },
    360725: {
      name: '崇义县',
      id: 900
    },
    360726: {
      name: '安远县',
      id: 894
    },
    360727: {
      name: '龙南县',
      id: 895
    },
    360728: {
      name: '定南县',
      id: 889
    },
    360729: {
      name: '全南县',
      id: 890
    },
    360730: {
      name: '宁都县',
      id: 891
    },
    360731: {
      name: '于都县',
      id: 905
    },
    360732: {
      name: '兴国县',
      id: 906
    },
    360733: {
      name: '会昌县',
      id: 902
    },
    360734: {
      name: '寻乌县',
      id: 904
    },
    360735: {
      name: '石城县',
      id: 898
    },
    360781: {
      name: '瑞金市',
      id: 892
    },
    360802: {
      name: '吉州区',
      id: 668
    },
    360803: {
      name: '青原区',
      id: 664
    },
    360821: {
      name: '吉安县',
      id: 670
    },
    360822: {
      name: '吉水县',
      id: 671
    },
    360823: {
      name: '峡江县',
      id: 667
    },
    360824: {
      name: '新干县',
      id: 669
    },
    360825: {
      name: '永丰县',
      id: 665
    },
    360826: {
      name: '泰和县',
      id: 666
    },
    360827: {
      name: '遂川县',
      id: 661
    },
    360828: {
      name: '万安县',
      id: 662
    },
    360829: {
      name: '安福县',
      id: 660
    },
    360830: {
      name: '永新县',
      id: 672
    },
    360881: {
      name: '井冈山市',
      id: 663
    },
    360902: {
      name: '袁州区',
      id: 469
    },
    360921: {
      name: '奉新县',
      id: 474
    },
    360922: {
      name: '万载县',
      id: 472
    },
    360923: {
      name: '上高县',
      id: 473
    },
    360924: {
      name: '宜丰县',
      id: 470
    },
    360925: {
      name: '靖安县',
      id: 471
    },
    360926: {
      name: '铜鼓县',
      id: 468
    },
    360981: {
      name: '丰城市',
      id: 467
    },
    360982: {
      name: '樟树市',
      id: 465
    },
    360983: {
      name: '高安市',
      id: 466
    },
    361002: {
      name: '临川区',
      id: 2530
    },
    361003: {
      name: '东乡区',
      id: 2528
    },
    361021: {
      name: '南城县',
      id: 2534
    },
    361022: {
      name: '黎川县',
      id: 2533
    },
    361023: {
      name: '南丰县',
      id: 2532
    },
    361024: {
      name: '崇仁县',
      id: 2531
    },
    361025: {
      name: '乐安县',
      id: 2529
    },
    361026: {
      name: '宜黄县',
      id: 2527
    },
    361027: {
      name: '金溪县',
      id: 2526
    },
    361028: {
      name: '资溪县',
      id: 2536
    },
    361030: {
      name: '广昌县',
      id: 2535
    },
    361102: {
      name: '信州区',
      id: 2396
    },
    361103: {
      name: '广丰区',
      id: 2394
    },
    361121: {
      name: '广信区',
      id: 2399
    },
    361123: {
      name: '玉山县',
      id: 2398
    },
    361124: {
      name: '铅山县',
      id: 2397
    },
    361125: {
      name: '横峰县',
      id: 2395
    },
    361126: {
      name: '弋阳县',
      id: 2393
    },
    361127: {
      name: '余干县',
      id: 2404
    },
    361128: {
      name: '鄱阳县',
      id: 2403
    },
    361129: {
      name: '万年县',
      id: 2402
    },
    361130: {
      name: '婺源县',
      id: 2400
    },
    361181: {
      name: '德兴市',
      id: 2401
    },
    370102: {
      name: '历下区',
      id: 1616
    },
    370103: {
      name: '市中区',
      id: 1619
    },
    370104: {
      name: '槐荫区',
      id: 1622
    },
    370105: {
      name: '天桥区',
      id: 1624
    },
    370112: {
      name: '历城区',
      id: 1615
    },
    370113: {
      name: '长清区',
      id: 1617
    },
    370114: {
      name: '章丘区',
      id: 1620
    },
    370124: {
      name: '平阴县',
      id: 1618
    },
    370125: {
      name: '济阳县',
      id: 1621
    },
    370126: {
      name: '商河县',
      id: 1623
    },
    370202: {
      name: '市南区',
      id: 1394
    },
    370203: {
      name: '市北区',
      id: 1396
    },
    370211: {
      name: '黄岛区',
      id: 1389
    },
    370212: {
      name: '崂山区',
      id: 1390
    },
    370213: {
      name: '李沧区',
      id: 1395
    },
    370214: {
      name: '城阳区',
      id: 1397
    },
    370215: {
      name: '即墨区',
      id: 1398
    },
    370281: {
      name: '胶州市',
      id: 1393
    },
    370283: {
      name: '平度市',
      id: 1391
    },
    370285: {
      name: '莱西市',
      id: 1392
    },
    370302: {
      name: '淄川区',
      id: 1146
    },
    370303: {
      name: '张店区',
      id: 1147
    },
    370304: {
      name: '博山区',
      id: 1148
    },
    370305: {
      name: '临淄区',
      id: 1143
    },
    370306: {
      name: '周村区',
      id: 1144
    },
    370321: {
      name: '桓台县',
      id: 1141
    },
    370322: {
      name: '高青县',
      id: 1142
    },
    370323: {
      name: '沂源县',
      id: 1145
    },
    370402: {
      name: '市中区',
      id: 950
    },
    370403: {
      name: '薛城区',
      id: 951
    },
    370404: {
      name: '峄城区',
      id: 947
    },
    370405: {
      name: '台儿庄区',
      id: 948
    },
    370406: {
      name: '山亭区',
      id: 949
    },
    370481: {
      name: '滕州市',
      id: 946
    },
    370502: {
      name: '东营区',
      id: 726
    },
    370503: {
      name: '河口区',
      id: 722
    },
    370505: {
      name: '垦利区',
      id: 723
    },
    370522: {
      name: '利津县',
      id: 724
    },
    370523: {
      name: '广饶县',
      id: 725
    },
    370602: {
      name: '芝罘区',
      id: 541
    },
    370611: {
      name: '福山区',
      id: 545
    },
    370612: {
      name: '牟平区',
      id: 546
    },
    370613: {
      name: '莱山区',
      id: 542
    },
    370634: {
      name: '长岛县',
      id: 547
    },
    370681: {
      name: '龙口市',
      id: 540
    },
    370682: {
      name: '莱阳市',
      id: 536
    },
    370683: {
      name: '莱州市',
      id: 537
    },
    370684: {
      name: '蓬莱市',
      id: 538
    },
    370685: {
      name: '招远市',
      id: 539
    },
    370686: {
      name: '栖霞市',
      id: 543
    },
    370687: {
      name: '海阳市',
      id: 544
    },
    370702: {
      name: '潍城区',
      id: 380
    },
    370703: {
      name: '寒亭区',
      id: 382
    },
    370704: {
      name: '坊子区',
      id: 384
    },
    370705: {
      name: '奎文区',
      id: 379
    },
    370724: {
      name: '临朐县',
      id: 381
    },
    370725: {
      name: '昌乐县',
      id: 383
    },
    370781: {
      name: '青州市',
      id: 375
    },
    370782: {
      name: '诸城市',
      id: 376
    },
    370783: {
      name: '寿光市',
      id: 377
    },
    370784: {
      name: '安丘市',
      id: 378
    },
    370785: {
      name: '高密市',
      id: 385
    },
    370786: {
      name: '昌邑市',
      id: 386
    },
    370811: {
      name: '任城区',
      id: 166
    },
    370812: {
      name: '兖州区',
      id: 167
    },
    370826: {
      name: '微山县',
      id: 162
    },
    370827: {
      name: '鱼台县',
      id: 163
    },
    370828: {
      name: '金乡县',
      id: 164
    },
    370829: {
      name: '嘉祥县',
      id: 165
    },
    370830: {
      name: '汶上县',
      id: 168
    },
    370831: {
      name: '泗水县',
      id: 169
    },
    370832: {
      name: '梁山县',
      id: 170
    },
    370881: {
      name: '曲阜市',
      id: 160
    },
    370883: {
      name: '邹城市',
      id: 161
    },
    370902: {
      name: '泰山区',
      id: 24
    },
    370911: {
      name: '岱岳区',
      id: 22
    },
    370921: {
      name: '宁阳县',
      id: 21
    },
    370923: {
      name: '东平县',
      id: 23
    },
    370982: {
      name: '新泰市',
      id: 20
    },
    370983: {
      name: '肥城市',
      id: 25
    },
    371002: {
      name: '环翠区',
      id: 2252
    },
    371003: {
      name: '文登区',
      id: 2253
    },
    371082: {
      name: '荣成市',
      id: 2254
    },
    371083: {
      name: '乳山市',
      id: 2255
    },
    371102: {
      name: '东港区',
      id: 2081
    },
    371103: {
      name: '岚山区',
      id: 2080
    },
    371121: {
      name: '五莲县',
      id: 2082
    },
    371122: {
      name: '莒县',
      id: 2079
    },
    371202: {
      name: '莱城区',
      id: 1897
    },
    371203: {
      name: '钢城区',
      id: 1898
    },
    371302: {
      name: '兰山区',
      id: 1715
    },
    371311: {
      name: '罗庄区',
      id: 1710
    },
    371312: {
      name: '河东区',
      id: 1708
    },
    371321: {
      name: '沂南县',
      id: 1707
    },
    371322: {
      name: '郯城县',
      id: 1711
    },
    371323: {
      name: '沂水县',
      id: 1709
    },
    371324: {
      name: '兰陵县',
      id: 1716
    },
    371325: {
      name: '费县',
      id: 1714
    },
    371326: {
      name: '平邑县',
      id: 1718
    },
    371327: {
      name: '莒南县',
      id: 1717
    },
    371328: {
      name: '蒙阴县',
      id: 1713
    },
    371329: {
      name: '临沭县',
      id: 1712
    },
    371402: {
      name: '德城区',
      id: 1500
    },
    371403: {
      name: '陵城区',
      id: 1504
    },
    371422: {
      name: '宁津县',
      id: 1495
    },
    371423: {
      name: '庆云县',
      id: 1502
    },
    371424: {
      name: '临邑县',
      id: 1501
    },
    371425: {
      name: '齐河县',
      id: 1505
    },
    371426: {
      name: '平原县',
      id: 1503
    },
    371427: {
      name: '夏津县',
      id: 1499
    },
    371428: {
      name: '武城县',
      id: 1498
    },
    371481: {
      name: '乐陵市',
      id: 1497
    },
    371482: {
      name: '禹城市',
      id: 1496
    },
    371502: {
      name: '东昌府区',
      id: 1270
    },
    371521: {
      name: '阳谷县',
      id: 1264
    },
    371522: {
      name: '莘县',
      id: 1268
    },
    371523: {
      name: '茌平县',
      id: 1267
    },
    371524: {
      name: '东阿县',
      id: 1271
    },
    371525: {
      name: '冠县',
      id: 1269
    },
    371526: {
      name: '高唐县',
      id: 1266
    },
    371581: {
      name: '临清市',
      id: 1265
    },
    371602: {
      name: '滨城区',
      id: 1057
    },
    371603: {
      name: '沾化区',
      id: 1053
    },
    371621: {
      name: '惠民县',
      id: 1056
    },
    371622: {
      name: '阳信县',
      id: 1055
    },
    371623: {
      name: '无棣县',
      id: 1058
    },
    371625: {
      name: '博兴县',
      id: 1054
    },
    371626: {
      name: '邹平县',
      id: 1052
    },
    371702: {
      name: '牡丹区',
      id: 820
    },
    371703: {
      name: '定陶区',
      id: 818
    },
    371721: {
      name: '曹县',
      id: 823
    },
    371722: {
      name: '单县',
      id: 825
    },
    371723: {
      name: '成武县',
      id: 824
    },
    371724: {
      name: '巨野县',
      id: 821
    },
    371725: {
      name: '郓城县',
      id: 819
    },
    371726: {
      name: '鄄城县',
      id: 822
    },
    371728: {
      name: '东明县',
      id: 817
    },
    410102: {
      name: '中原区',
      id: 129
    },
    410103: {
      name: '二七区',
      id: 128
    },
    410104: {
      name: '管城回族区',
      id: 131
    },
    410105: {
      name: '金水区',
      id: 130
    },
    410106: {
      name: '上街区',
      id: 125
    },
    410108: {
      name: '惠济区',
      id: 127
    },
    410122: {
      name: '中牟县',
      id: 132
    },
    410181: {
      name: '巩义市',
      id: 126
    },
    410182: {
      name: '荥阳市',
      id: 134
    },
    410183: {
      name: '新密市',
      id: 133
    },
    410184: {
      name: '新郑市',
      id: 136
    },
    410185: {
      name: '登封市',
      id: 135
    },
    410202: {
      name: '龙亭区',
      id: 638
    },
    410203: {
      name: '顺河回族区',
      id: 642
    },
    410204: {
      name: '鼓楼区',
      id: 641
    },
    410205: {
      name: '禹王台区',
      id: 637
    },
    410212: {
      name: '祥符区',
      id: 639
    },
    410221: {
      name: '杞县',
      id: 645
    },
    410222: {
      name: '通许县',
      id: 644
    },
    410223: {
      name: '尉氏县',
      id: 640
    },
    410225: {
      name: '兰考县',
      id: 643
    },
    410302: {
      name: '老城区',
      id: 517
    },
    410303: {
      name: '西工区',
      id: 515
    },
    410304: {
      name: '瀍河回族区',
      id: 507
    },
    410305: {
      name: '涧西区',
      id: 505
    },
    410306: {
      name: '吉利区',
      id: 510
    },
    410311: {
      name: '洛龙区',
      id: 513
    },
    410322: {
      name: '孟津县',
      id: 514
    },
    410323: {
      name: '新安县',
      id: 512
    },
    410324: {
      name: '栾川县',
      id: 518
    },
    410325: {
      name: '嵩县',
      id: 516
    },
    410326: {
      name: '汝阳县',
      id: 508
    },
    410327: {
      name: '宜阳县',
      id: 506
    },
    410328: {
      name: '洛宁县',
      id: 511
    },
    410329: {
      name: '伊川县',
      id: 509
    },
    410381: {
      name: '偃师市',
      id: 519
    },
    410402: {
      name: '新华区',
      id: 357
    },
    410403: {
      name: '卫东区',
      id: 352
    },
    410404: {
      name: '石龙区',
      id: 351
    },
    410411: {
      name: '湛河区',
      id: 354
    },
    410421: {
      name: '宝丰县',
      id: 356
    },
    410422: {
      name: '叶县',
      id: 355
    },
    410423: {
      name: '鲁山县',
      id: 358
    },
    410425: {
      name: '郏县',
      id: 353
    },
    410481: {
      name: '舞钢市',
      id: 360
    },
    410482: {
      name: '汝州市',
      id: 359
    },
    410502: {
      name: '文峰区',
      id: 929
    },
    410503: {
      name: '北关区',
      id: 928
    },
    410505: {
      name: '殷都区',
      id: 930
    },
    410506: {
      name: '龙安区',
      id: 935
    },
    410522: {
      name: '安阳县',
      id: 934
    },
    410523: {
      name: '汤阴县',
      id: 933
    },
    410526: {
      name: '滑县',
      id: 932
    },
    410527: {
      name: '内黄县',
      id: 931
    },
    410581: {
      name: '林州市',
      id: 936
    },
    410602: {
      name: '鹤山区',
      id: 711
    },
    410603: {
      name: '山城区',
      id: 712
    },
    410611: {
      name: '淇滨区',
      id: 713
    },
    410621: {
      name: '浚县',
      id: 715
    },
    410622: {
      name: '淇县',
      id: 714
    },
    410702: {
      name: '红旗区',
      id: 1375
    },
    410703: {
      name: '卫滨区',
      id: 1373
    },
    410704: {
      name: '凤泉区',
      id: 1381
    },
    410711: {
      name: '牧野区',
      id: 1372
    },
    410721: {
      name: '新乡县',
      id: 1377
    },
    410724: {
      name: '获嘉县',
      id: 1376
    },
    410725: {
      name: '原阳县',
      id: 1374
    },
    410726: {
      name: '延津县',
      id: 1382
    },
    410727: {
      name: '封丘县',
      id: 1380
    },
    410728: {
      name: '长垣县',
      id: 1383
    },
    410781: {
      name: '卫辉市',
      id: 1378
    },
    410782: {
      name: '辉县市',
      id: 1379
    },
    410802: {
      name: '解放区',
      id: 1134
    },
    410803: {
      name: '中站区',
      id: 1139
    },
    410804: {
      name: '马村区',
      id: 1138
    },
    410811: {
      name: '山阳区',
      id: 1131
    },
    410821: {
      name: '修武县',
      id: 1133
    },
    410822: {
      name: '博爱县',
      id: 1132
    },
    410823: {
      name: '武陟县',
      id: 1135
    },
    410825: {
      name: '温县',
      id: 1140
    },
    410882: {
      name: '沁阳市',
      id: 1137
    },
    410883: {
      name: '孟州市',
      id: 1136
    },
    410902: {
      name: '华龙区',
      id: 1838
    },
    410922: {
      name: '清丰县',
      id: 1836
    },
    410923: {
      name: '南乐县',
      id: 1835
    },
    410926: {
      name: '范县',
      id: 1840
    },
    410927: {
      name: '台前县',
      id: 1839
    },
    410928: {
      name: '濮阳县',
      id: 1837
    },
    411002: {
      name: '魏都区',
      id: 2801
    },
    411003: {
      name: '建安区',
      id: 2799
    },
    411024: {
      name: '鄢陵县',
      id: 2802
    },
    411025: {
      name: '襄城县',
      id: 2800
    },
    411081: {
      name: '禹州市',
      id: 2797
    },
    411082: {
      name: '长葛市',
      id: 2798
    },
    411102: {
      name: '源汇区',
      id: 2689
    },
    411103: {
      name: '郾城区',
      id: 2686
    },
    411104: {
      name: '召陵区',
      id: 2685
    },
    411121: {
      name: '舞阳县',
      id: 2688
    },
    411122: {
      name: '临颍县',
      id: 2687
    },
    411202: {
      name: '湖滨区',
      id: 210
    },
    411203: {
      name: '陕州区',
      id: 209
    },
    411221: {
      name: '渑池县',
      id: 212
    },
    411224: {
      name: '卢氏县',
      id: 211
    },
    411281: {
      name: '义马市',
      id: 208
    },
    411282: {
      name: '灵宝市',
      id: 213
    },
    411302: {
      name: '宛城区',
      id: 54
    },
    411303: {
      name: '卧龙区',
      id: 58
    },
    411321: {
      name: '南召县',
      id: 62
    },
    411322: {
      name: '方城县',
      id: 61
    },
    411323: {
      name: '西峡县',
      id: 56
    },
    411324: {
      name: '镇平县',
      id: 55
    },
    411325: {
      name: '内乡县',
      id: 59
    },
    411326: {
      name: '淅川县',
      id: 57
    },
    411327: {
      name: '社旗县',
      id: 52
    },
    411328: {
      name: '唐河县',
      id: 51
    },
    411329: {
      name: '新野县',
      id: 53
    },
    411330: {
      name: '桐柏县',
      id: 60
    },
    411381: {
      name: '邓州市',
      id: 63
    },
    411402: {
      name: '梁园区',
      id: 574
    },
    411403: {
      name: '睢阳区',
      id: 572
    },
    411421: {
      name: '民权县',
      id: 576
    },
    411422: {
      name: '睢县',
      id: 571
    },
    411423: {
      name: '宁陵县',
      id: 570
    },
    411424: {
      name: '柘城县',
      id: 575
    },
    411425: {
      name: '虞城县',
      id: 573
    },
    411426: {
      name: '夏邑县',
      id: 569
    },
    411481: {
      name: '永城市',
      id: 577
    },
    411502: {
      name: '浉河区',
      id: 415
    },
    411503: {
      name: '平桥区',
      id: 409
    },
    411521: {
      name: '罗山县',
      id: 414
    },
    411522: {
      name: '光山县',
      id: 413
    },
    411523: {
      name: '新县',
      id: 417
    },
    411524: {
      name: '商城县',
      id: 416
    },
    411525: {
      name: '固始县',
      id: 410
    },
    411526: {
      name: '潢川县',
      id: 408
    },
    411527: {
      name: '淮滨县',
      id: 412
    },
    411528: {
      name: '息县',
      id: 411
    },
    411602: {
      name: '川汇区',
      id: 1043
    },
    411621: {
      name: '扶沟县',
      id: 1047
    },
    411622: {
      name: '西华县',
      id: 1049
    },
    411623: {
      name: '商水县',
      id: 1048
    },
    411624: {
      name: '沈丘县',
      id: 1044
    },
    411625: {
      name: '郸城县',
      id: 1042
    },
    411626: {
      name: '淮阳县',
      id: 1046
    },
    411627: {
      name: '太康县',
      id: 1045
    },
    411628: {
      name: '鹿邑县',
      id: 1050
    },
    411681: {
      name: '项城市',
      id: 1051
    },
    411702: {
      name: '驿城区',
      id: 762
    },
    411721: {
      name: '西平县',
      id: 768
    },
    411722: {
      name: '上蔡县',
      id: 767
    },
    411723: {
      name: '平舆县',
      id: 764
    },
    411724: {
      name: '正阳县',
      id: 763
    },
    411725: {
      name: '确山县',
      id: 766
    },
    411726: {
      name: '泌阳县',
      id: 765
    },
    411727: {
      name: '汝南县',
      id: 770
    },
    411728: {
      name: '遂平县',
      id: 769
    },
    411729: {
      name: '新蔡县',
      id: 771
    },
    419001: {
      name: '济源市',
      id: 561
    },
    420102: {
      name: '江岸区',
      id: 423
    },
    420103: {
      name: '江汉区',
      id: 421
    },
    420104: {
      name: '硚口区',
      id: 419
    },
    420105: {
      name: '汉阳区',
      id: 429
    },
    420106: {
      name: '武昌区',
      id: 427
    },
    420107: {
      name: '青山区',
      id: 426
    },
    420111: {
      name: '洪山区',
      id: 418
    },
    420112: {
      name: '东西湖区',
      id: 425
    },
    420113: {
      name: '汉南区',
      id: 424
    },
    420114: {
      name: '蔡甸区',
      id: 422
    },
    420115: {
      name: '江夏区',
      id: 420
    },
    420116: {
      name: '黄陂区',
      id: 430
    },
    420117: {
      name: '新洲区',
      id: 428
    },
    420202: {
      name: '黄石港区',
      id: 395
    },
    420203: {
      name: '西塞山区',
      id: 394
    },
    420204: {
      name: '下陆区',
      id: 398
    },
    420205: {
      name: '铁山区',
      id: 397
    },
    420222: {
      name: '阳新县',
      id: 396
    },
    420281: {
      name: '大冶市',
      id: 399
    },
    420302: {
      name: '茅箭区',
      id: 956
    },
    420303: {
      name: '张湾区',
      id: 961
    },
    420304: {
      name: '郧阳区',
      id: 960
    },
    420322: {
      name: '郧西县',
      id: 959
    },
    420323: {
      name: '竹山县',
      id: 958
    },
    420324: {
      name: '竹溪县',
      id: 957
    },
    420325: {
      name: '房县',
      id: 962
    },
    420381: {
      name: '丹江口市',
      id: 963
    },
    420502: {
      name: '西陵区',
      id: 1436
    },
    420503: {
      name: '伍家岗区',
      id: 1434
    },
    420504: {
      name: '点军区',
      id: 1432
    },
    420505: {
      name: '猇亭区',
      id: 1440
    },
    420506: {
      name: '夷陵区',
      id: 1438
    },
    420525: {
      name: '远安县',
      id: 1435
    },
    420526: {
      name: '兴山县',
      id: 1433
    },
    420527: {
      name: '秭归县',
      id: 1441
    },
    420528: {
      name: '长阳土家族自治县',
      id: 1439
    },
    420529: {
      name: '五峰土家族自治县',
      id: 1437
    },
    420581: {
      name: '宜都市',
      id: 1431
    },
    420582: {
      name: '当阳市',
      id: 1430
    },
    420583: {
      name: '枝江市',
      id: 1429
    },
    420602: {
      name: '襄城区',
      id: 1190
    },
    420606: {
      name: '樊城区',
      id: 1193
    },
    420607: {
      name: '襄州区',
      id: 1192
    },
    420624: {
      name: '南漳县',
      id: 1191
    },
    420625: {
      name: '谷城县',
      id: 1189
    },
    420626: {
      name: '保康县',
      id: 1194
    },
    420682: {
      name: '老河口市',
      id: 1196
    },
    420683: {
      name: '枣阳市',
      id: 1195
    },
    420684: {
      name: '宜城市',
      id: 1188
    },
    420702: {
      name: '梁子湖区',
      id: 1866
    },
    420703: {
      name: '华容区',
      id: 1868
    },
    420704: {
      name: '鄂城区',
      id: 1867
    },
    420802: {
      name: '东宝区',
      id: 1669
    },
    420804: {
      name: '掇刀区',
      id: 1668
    },
    420821: {
      name: '京山县',
      id: 1667
    },
    420822: {
      name: '沙洋县',
      id: 1666
    },
    420881: {
      name: '钟祥市',
      id: 1670
    },
    420902: {
      name: '孝南区',
      id: 2229
    },
    420921: {
      name: '孝昌县',
      id: 2228
    },
    420922: {
      name: '大悟县',
      id: 2227
    },
    420923: {
      name: '云梦县',
      id: 2230
    },
    420981: {
      name: '应城市',
      id: 2226
    },
    420982: {
      name: '安陆市',
      id: 2225
    },
    420984: {
      name: '汉川市',
      id: 2224
    },
    421002: {
      name: '沙市区',
      id: 180
    },
    421003: {
      name: '荆州区',
      id: 177
    },
    421022: {
      name: '公安县',
      id: 183
    },
    421023: {
      name: '监利县',
      id: 182
    },
    421024: {
      name: '江陵县',
      id: 181
    },
    421081: {
      name: '石首市',
      id: 178
    },
    421083: {
      name: '洪湖市',
      id: 179
    },
    421087: {
      name: '松滋市',
      id: 184
    },
    421102: {
      name: '黄州区',
      id: 29
    },
    421121: {
      name: '团风县',
      id: 35
    },
    421122: {
      name: '红安县',
      id: 34
    },
    421123: {
      name: '罗田县',
      id: 33
    },
    421124: {
      name: '英山县',
      id: 30
    },
    421125: {
      name: '浠水县',
      id: 28
    },
    421126: {
      name: '蕲春县',
      id: 27
    },
    421127: {
      name: '黄梅县',
      id: 26
    },
    421181: {
      name: '麻城市',
      id: 31
    },
    421182: {
      name: '武穴市',
      id: 32
    },
    421202: {
      name: '咸安区',
      id: 530
    },
    421221: {
      name: '嘉鱼县',
      id: 535
    },
    421222: {
      name: '通城县',
      id: 534
    },
    421223: {
      name: '崇阳县',
      id: 532
    },
    421224: {
      name: '通山县',
      id: 531
    },
    421281: {
      name: '赤壁市',
      id: 533
    },
    421303: {
      name: '曾都区',
      id: 451
    },
    421321: {
      name: '随县',
      id: 453
    },
    421381: {
      name: '广水市',
      id: 452
    },
    422801: {
      name: '恩施市',
      id: 1443
    },
    422802: {
      name: '利川市',
      id: 1445
    },
    422822: {
      name: '建始县',
      id: 1442
    },
    422823: {
      name: '巴东县',
      id: 1444
    },
    422825: {
      name: '宣恩县',
      id: 1446
    },
    422826: {
      name: '咸丰县',
      id: 1447
    },
    422827: {
      name: '来凤县',
      id: 1448
    },
    422828: {
      name: '鹤峰县',
      id: 1449
    },
    429004: {
      name: '仙桃市',
      id: 954
    },
    429005: {
      name: '潜江市',
      id: 953
    },
    429006: {
      name: '天门市',
      id: 952
    },
    429021: {
      name: '神农架林区',
      id: 350
    },
    430102: {
      name: '芙蓉区',
      id: 987
    },
    430103: {
      name: '天心区',
      id: 986
    },
    430104: {
      name: '岳麓区',
      id: 989
    },
    430105: {
      name: '开福区',
      id: 988
    },
    430111: {
      name: '雨花区',
      id: 985
    },
    430112: {
      name: '望城区',
      id: 984
    },
    430121: {
      name: '长沙县',
      id: 992
    },
    430181: {
      name: '浏阳市',
      id: 990
    },
    430182: {
      name: '宁乡市',
      id: 991
    },
    430202: {
      name: '荷塘区',
      id: 743
    },
    430203: {
      name: '芦淞区',
      id: 747
    },
    430204: {
      name: '石峰区',
      id: 746
    },
    430211: {
      name: '天元区',
      id: 741
    },
    430221: {
      name: '株洲县',
      id: 742
    },
    430223: {
      name: '攸县',
      id: 745
    },
    430224: {
      name: '茶陵县',
      id: 744
    },
    430225: {
      name: '炎陵县',
      id: 748
    },
    430281: {
      name: '醴陵市',
      id: 749
    },
    430302: {
      name: '雨湖区',
      id: 1507
    },
    430304: {
      name: '岳塘区',
      id: 1508
    },
    430321: {
      name: '湘潭县',
      id: 1506
    },
    430381: {
      name: '湘乡市',
      id: 1509
    },
    430382: {
      name: '韶山市',
      id: 1510
    },
    430405: {
      name: '珠晖区',
      id: 1242
    },
    430406: {
      name: '雁峰区',
      id: 1241
    },
    430407: {
      name: '石鼓区',
      id: 1244
    },
    430408: {
      name: '蒸湘区',
      id: 1243
    },
    430412: {
      name: '南岳区',
      id: 1238
    },
    430421: {
      name: '衡阳县',
      id: 1236
    },
    430422: {
      name: '衡南县',
      id: 1235
    },
    430423: {
      name: '衡山县',
      id: 1239
    },
    430424: {
      name: '衡东县',
      id: 1237
    },
    430426: {
      name: '祁东县',
      id: 1240
    },
    430481: {
      name: '耒阳市',
      id: 1246
    },
    430482: {
      name: '常宁市',
      id: 1245
    },
    430502: {
      name: '双清区',
      id: 1936
    },
    430503: {
      name: '大祥区',
      id: 1934
    },
    430511: {
      name: '北塔区',
      id: 1932
    },
    430521: {
      name: '邵东县',
      id: 1930
    },
    430522: {
      name: '新邵县',
      id: 1933
    },
    430523: {
      name: '邵阳县',
      id: 1931
    },
    430524: {
      name: '隆回县',
      id: 1937
    },
    430525: {
      name: '洞口县',
      id: 1935
    },
    430527: {
      name: '绥宁县',
      id: 1938
    },
    430528: {
      name: '新宁县',
      id: 1940
    },
    430529: {
      name: '城步苗族自治县',
      id: 1939
    },
    430581: {
      name: '武冈市',
      id: 1941
    },
    430602: {
      name: '岳阳楼区',
      id: 1721
    },
    430603: {
      name: '云溪区',
      id: 1725
    },
    430611: {
      name: '君山区',
      id: 1719
    },
    430621: {
      name: '岳阳县',
      id: 1720
    },
    430623: {
      name: '华容县',
      id: 1723
    },
    430624: {
      name: '湘阴县',
      id: 1722
    },
    430626: {
      name: '平江县',
      id: 1724
    },
    430681: {
      name: '汨罗市',
      id: 1727
    },
    430682: {
      name: '临湘市',
      id: 1726
    },
    430702: {
      name: '武陵区',
      id: 2274
    },
    430703: {
      name: '鼎城区',
      id: 2272
    },
    430721: {
      name: '安乡县',
      id: 2269
    },
    430722: {
      name: '汉寿县',
      id: 2271
    },
    430723: {
      name: '澧县',
      id: 2270
    },
    430724: {
      name: '临澧县',
      id: 2275
    },
    430725: {
      name: '桃源县',
      id: 2273
    },
    430726: {
      name: '石门县',
      id: 2276
    },
    430781: {
      name: '津市市',
      id: 2277
    },
    430802: {
      name: '永定区',
      id: 2104
    },
    430811: {
      name: '武陵源区',
      id: 2101
    },
    430821: {
      name: '慈利县',
      id: 2103
    },
    430822: {
      name: '桑植县',
      id: 2102
    },
    430902: {
      name: '资阳区',
      id: 2559
    },
    430903: {
      name: '赫山区',
      id: 2558
    },
    430921: {
      name: '南县',
      id: 2555
    },
    430922: {
      name: '桃江县',
      id: 2557
    },
    430923: {
      name: '安化县',
      id: 2556
    },
    430981: {
      name: '沅江市',
      id: 2554
    },
    431002: {
      name: '北湖区',
      id: 686
    },
    431003: {
      name: '苏仙区',
      id: 684
    },
    431021: {
      name: '桂阳县',
      id: 692
    },
    431022: {
      name: '宜章县',
      id: 694
    },
    431023: {
      name: '永兴县',
      id: 693
    },
    431024: {
      name: '嘉禾县',
      id: 687
    },
    431025: {
      name: '临武县',
      id: 685
    },
    431026: {
      name: '汝城县',
      id: 689
    },
    431027: {
      name: '桂东县',
      id: 688
    },
    431028: {
      name: '安仁县',
      id: 690
    },
    431081: {
      name: '资兴市',
      id: 691
    },
    431102: {
      name: '零陵区',
      id: 483
    },
    431103: {
      name: '冷水滩区',
      id: 487
    },
    431121: {
      name: '祁阳县',
      id: 493
    },
    431122: {
      name: '东安县',
      id: 492
    },
    431123: {
      name: '双牌县',
      id: 485
    },
    431124: {
      name: '道县',
      id: 484
    },
    431125: {
      name: '江永县',
      id: 488
    },
    431126: {
      name: '宁远县',
      id: 486
    },
    431127: {
      name: '蓝山县',
      id: 490
    },
    431128: {
      name: '新田县',
      id: 489
    },
    431129: {
      name: '江华瑶族自治县',
      id: 491
    },
    431202: {
      name: '鹤城区',
      id: 919
    },
    431221: {
      name: '中方县',
      id: 927
    },
    431222: {
      name: '沅陵县',
      id: 917
    },
    431223: {
      name: '辰溪县',
      id: 916
    },
    431224: {
      name: '溆浦县',
      id: 920
    },
    431225: {
      name: '会同县',
      id: 918
    },
    431226: {
      name: '麻阳苗族自治县',
      id: 922
    },
    431227: {
      name: '新晃侗族自治县',
      id: 921
    },
    431228: {
      name: '芷江侗族自治县',
      id: 924
    },
    431229: {
      name: '靖州苗族侗族自治县',
      id: 923
    },
    431230: {
      name: '通道侗族自治县',
      id: 926
    },
    431281: {
      name: '洪江市',
      id: 925
    },
    431302: {
      name: '娄星区',
      id: 697
    },
    431321: {
      name: '双峰县',
      id: 696
    },
    431322: {
      name: '新化县',
      id: 695
    },
    431381: {
      name: '冷水江市',
      id: 699
    },
    431382: {
      name: '涟源市',
      id: 698
    },
    433101: {
      name: '吉首市',
      id: 334
    },
    433122: {
      name: '泸溪县',
      id: 337
    },
    433123: {
      name: '凤凰县',
      id: 335
    },
    433124: {
      name: '花垣县',
      id: 336
    },
    433125: {
      name: '保靖县',
      id: 331
    },
    433126: {
      name: '古丈县',
      id: 332
    },
    433127: {
      name: '永顺县',
      id: 330
    },
    433130: {
      name: '龙山县',
      id: 333
    },
    440103: {
      name: '荔湾区',
      id: 1556
    },
    440104: {
      name: '越秀区',
      id: 1554
    },
    440105: {
      name: '海珠区',
      id: 1553
    },
    440106: {
      name: '天河区',
      id: 1551
    },
    440111: {
      name: '白云区',
      id: 1560
    },
    440112: {
      name: '黄埔区',
      id: 1559
    },
    440113: {
      name: '番禺区',
      id: 1558
    },
    440114: {
      name: '花都区',
      id: 1557
    },
    440115: {
      name: '南沙区',
      id: 1555
    },
    440117: {
      name: '从化区',
      id: 1552
    },
    440118: {
      name: '增城区',
      id: 1550
    },
    440203: {
      name: '武江区',
      id: 1277
    },
    440204: {
      name: '浈江区',
      id: 1276
    },
    440205: {
      name: '曲江区',
      id: 1275
    },
    440222: {
      name: '始兴县',
      id: 1279
    },
    440224: {
      name: '仁化县',
      id: 1278
    },
    440229: {
      name: '翁源县',
      id: 1274
    },
    440232: {
      name: '乳源瑶族自治县',
      id: 1281
    },
    440233: {
      name: '新丰县',
      id: 1280
    },
    440281: {
      name: '乐昌市',
      id: 1273
    },
    440282: {
      name: '南雄市',
      id: 1272
    },
    440303: {
      name: '罗湖区',
      id: 1966
    },
    440304: {
      name: '福田区',
      id: 1965
    },
    440305: {
      name: '南山区',
      id: 1964
    },
    440306: {
      name: '宝安区',
      id: 1963
    },
    440307: {
      name: '龙岗区',
      id: 1962
    },
    440308: {
      name: '盐田区',
      id: 1961
    },
    440309: {
      name: '龙华区',
      id: 1960
    },
    440310: {
      name: '坪山区',
      id: 1967
    },
    440402: {
      name: '香洲区',
      id: 1792
    },
    440403: {
      name: '斗门区',
      id: 1791
    },
    440404: {
      name: '金湾区',
      id: 1790
    },
    440499: {
      name: '澳门大学横琴校区(由澳门管辖)',
      id: 1793
    },
    440507: {
      name: '龙湖区',
      id: 2281
    },
    440511: {
      name: '金平区',
      id: 2287
    },
    440512: {
      name: '濠江区',
      id: 2285
    },
    440513: {
      name: '潮阳区',
      id: 2284
    },
    440514: {
      name: '潮南区',
      id: 2283
    },
    440515: {
      name: '澄海区',
      id: 2282
    },
    440523: {
      name: '南澳县',
      id: 2286
    },
    440604: {
      name: '禅城区',
      id: 2109
    },
    440605: {
      name: '南海区',
      id: 2108
    },
    440606: {
      name: '顺德区',
      id: 2107
    },
    440607: {
      name: '三水区',
      id: 2106
    },
    440608: {
      name: '高明区',
      id: 2105
    },
    440703: {
      name: '蓬江区',
      id: 2549
    },
    440704: {
      name: '江海区',
      id: 2548
    },
    440705: {
      name: '新会区',
      id: 2547
    },
    440781: {
      name: '台山市',
      id: 2553
    },
    440783: {
      name: '开平市',
      id: 2552
    },
    440784: {
      name: '鹤山市',
      id: 2551
    },
    440785: {
      name: '恩平市',
      id: 2550
    },
    440802: {
      name: '赤坎区',
      id: 2445
    },
    440803: {
      name: '霞山区',
      id: 2443
    },
    440804: {
      name: '坡头区',
      id: 2442
    },
    440811: {
      name: '麻章区',
      id: 2447
    },
    440823: {
      name: '遂溪县',
      id: 2446
    },
    440825: {
      name: '徐闻县',
      id: 2444
    },
    440881: {
      name: '廉江市',
      id: 2450
    },
    440882: {
      name: '雷州市',
      id: 2449
    },
    440883: {
      name: '吴川市',
      id: 2448
    },
    440902: {
      name: '茂南区',
      id: 2821
    },
    440904: {
      name: '电白区',
      id: 2820
    },
    440981: {
      name: '高州市',
      id: 2824
    },
    440982: {
      name: '化州市',
      id: 2823
    },
    440983: {
      name: '信宜市',
      id: 2822
    },
    441202: {
      name: '端州区',
      id: 1426
    },
    441203: {
      name: '鼎湖区',
      id: 1424
    },
    441204: {
      name: '高要区',
      id: 1422
    },
    441223: {
      name: '广宁县',
      id: 1428
    },
    441224: {
      name: '怀集县',
      id: 1427
    },
    441225: {
      name: '封开县',
      id: 1425
    },
    441226: {
      name: '德庆县',
      id: 1423
    },
    441284: {
      name: '四会市',
      id: 1421
    },
    441302: {
      name: '惠城区',
      id: 1184
    },
    441303: {
      name: '惠阳区',
      id: 1183
    },
    441322: {
      name: '博罗县',
      id: 1187
    },
    441323: {
      name: '惠东县',
      id: 1186
    },
    441324: {
      name: '龙门县',
      id: 1185
    },
    441402: {
      name: '梅江区',
      id: 1845
    },
    441403: {
      name: '梅县区',
      id: 1844
    },
    441422: {
      name: '大埔县',
      id: 1848
    },
    441423: {
      name: '丰顺县',
      id: 1847
    },
    441424: {
      name: '五华县',
      id: 1846
    },
    441426: {
      name: '平远县',
      id: 1843
    },
    441427: {
      name: '蕉岭县',
      id: 1842
    },
    441481: {
      name: '兴宁市',
      id: 1841
    },
    441502: {
      name: '城区',
      id: 1640
    },
    441521: {
      name: '海丰县',
      id: 1642
    },
    441523: {
      name: '陆河县',
      id: 1641
    },
    441581: {
      name: '陆丰市',
      id: 1639
    },
    441602: {
      name: '源城区',
      id: 2181
    },
    441621: {
      name: '紫金县',
      id: 2185
    },
    441622: {
      name: '龙川县',
      id: 2184
    },
    441623: {
      name: '连平县',
      id: 2183
    },
    441624: {
      name: '和平县',
      id: 2182
    },
    441625: {
      name: '东源县',
      id: 2180
    },
    441702: {
      name: '江城区',
      id: 2037
    },
    441704: {
      name: '阳东区',
      id: 2036
    },
    441721: {
      name: '阳西县',
      id: 2038
    },
    441781: {
      name: '阳春市',
      id: 2035
    },
    441802: {
      name: '清城区',
      id: 2511
    },
    441803: {
      name: '清新区',
      id: 2509
    },
    441821: {
      name: '佛冈县',
      id: 2513
    },
    441823: {
      name: '阳山县',
      id: 2512
    },
    441825: {
      name: '连山壮族瑶族自治县',
      id: 2510
    },
    441826: {
      name: '连南瑶族自治县',
      id: 2508
    },
    441881: {
      name: '英德市',
      id: 2515
    },
    441882: {
      name: '连州市',
      id: 2514
    },
    441900: {
      name: '东莞市',
      id: 2387
    },
    442000: {
      name: '中山市',
      id: 374
    },
    442100: {
      name: '东沙群岛',
      id: 955
    },
    445102: {
      name: '湘桥区',
      id: 2707
    },
    445103: {
      name: '潮安区',
      id: 2706
    },
    445122: {
      name: '饶平县',
      id: 2705
    },
    445202: {
      name: '榕城区',
      id: 272
    },
    445203: {
      name: '揭东区',
      id: 269
    },
    445222: {
      name: '揭西县',
      id: 271
    },
    445224: {
      name: '惠来县',
      id: 273
    },
    445281: {
      name: '普宁市',
      id: 270
    },
    445302: {
      name: '云城区',
      id: 73
    },
    445303: {
      name: '云安区',
      id: 72
    },
    445321: {
      name: '新兴县',
      id: 75
    },
    445322: {
      name: '郁南县',
      id: 76
    },
    445381: {
      name: '罗定市',
      id: 74
    },
    450102: {
      name: '兴宁区',
      id: 2016
    },
    450103: {
      name: '青秀区',
      id: 2014
    },
    450105: {
      name: '江南区',
      id: 2018
    },
    450107: {
      name: '西乡塘区',
      id: 2011
    },
    450108: {
      name: '良庆区',
      id: 2013
    },
    450109: {
      name: '邕宁区',
      id: 2012
    },
    450110: {
      name: '武鸣区',
      id: 2021
    },
    450123: {
      name: '隆安县',
      id: 2022
    },
    450124: {
      name: '马山县',
      id: 2017
    },
    450125: {
      name: '上林县',
      id: 2015
    },
    450126: {
      name: '宾阳县',
      id: 2020
    },
    450127: {
      name: '横县',
      id: 2019
    },
    450202: {
      name: '城中区',
      id: 1827
    },
    450203: {
      name: '鱼峰区',
      id: 1832
    },
    450204: {
      name: '柳南区',
      id: 1830
    },
    450205: {
      name: '柳北区',
      id: 1826
    },
    450206: {
      name: '柳江区',
      id: 1825
    },
    450222: {
      name: '柳城县',
      id: 1834
    },
    450223: {
      name: '鹿寨县',
      id: 1829
    },
    450224: {
      name: '融安县',
      id: 1828
    },
    450225: {
      name: '融水苗族自治县',
      id: 1833
    },
    450226: {
      name: '三江侗族自治县',
      id: 1831
    },
    450302: {
      name: '秀峰区',
      id: 2336
    },
    450303: {
      name: '叠彩区',
      id: 2334
    },
    450304: {
      name: '象山区',
      id: 2327
    },
    450305: {
      name: '七星区',
      id: 2325
    },
    450311: {
      name: '雁山区',
      id: 2333
    },
    450312: {
      name: '临桂区',
      id: 2331
    },
    450321: {
      name: '阳朔县',
      id: 2339
    },
    450323: {
      name: '灵川县',
      id: 2332
    },
    450324: {
      name: '全州县',
      id: 2337
    },
    450325: {
      name: '兴安县',
      id: 2335
    },
    450326: {
      name: '永福县',
      id: 2328
    },
    450327: {
      name: '灌阳县',
      id: 2326
    },
    450328: {
      name: '龙胜各族自治县',
      id: 2330
    },
    450329: {
      name: '资源县',
      id: 2329
    },
    450330: {
      name: '平乐县',
      id: 2338
    },
    450331: {
      name: '荔浦县',
      id: 2341
    },
    450332: {
      name: '恭城瑶族自治县',
      id: 2340
    },
    450403: {
      name: '万秀区',
      id: 2140
    },
    450405: {
      name: '长洲区',
      id: 2142
    },
    450406: {
      name: '龙圩区',
      id: 2141
    },
    450421: {
      name: '苍梧县',
      id: 2144
    },
    450422: {
      name: '藤县',
      id: 2143
    },
    450423: {
      name: '蒙山县',
      id: 2145
    },
    450481: {
      name: '岑溪市',
      id: 2139
    },
    450502: {
      name: '海城区',
      id: 2619
    },
    450503: {
      name: '银海区',
      id: 2618
    },
    450512: {
      name: '铁山港区',
      id: 2621
    },
    450521: {
      name: '合浦县',
      id: 2620
    },
    450602: {
      name: '港口区',
      id: 2497
    },
    450603: {
      name: '防城区',
      id: 2498
    },
    450621: {
      name: '上思县',
      id: 2499
    },
    450681: {
      name: '东兴市',
      id: 2500
    },
    450702: {
      name: '钦南区',
      id: 2854
    },
    450703: {
      name: '钦北区',
      id: 2853
    },
    450721: {
      name: '灵山县',
      id: 2855
    },
    450722: {
      name: '浦北县',
      id: 2852
    },
    450802: {
      name: '港北区',
      id: 2711
    },
    450803: {
      name: '港南区',
      id: 2709
    },
    450804: {
      name: '覃塘区',
      id: 2708
    },
    450821: {
      name: '平南县',
      id: 2710
    },
    450881: {
      name: '桂平市',
      id: 2712
    },
    450902: {
      name: '玉州区',
      id: 292
    },
    450903: {
      name: '福绵区',
      id: 291
    },
    450921: {
      name: '容县',
      id: 294
    },
    450922: {
      name: '陆川县',
      id: 296
    },
    450923: {
      name: '博白县',
      id: 295
    },
    450924: {
      name: '兴业县',
      id: 293
    },
    450981: {
      name: '北流市',
      id: 297
    },
    451002: {
      name: '右江区',
      id: 1482
    },
    451021: {
      name: '田阳区',
      id: 1473
    },
    451022: {
      name: '田东县',
      id: 1481
    },
    451023: {
      name: '平果县',
      id: 1480
    },
    451024: {
      name: '德保县',
      id: 1483
    },
    451026: {
      name: '那坡县',
      id: 1477
    },
    451027: {
      name: '凌云县',
      id: 1476
    },
    451028: {
      name: '乐业县',
      id: 1479
    },
    451029: {
      name: '田林县',
      id: 1478
    },
    451030: {
      name: '西林县',
      id: 1472
    },
    451031: {
      name: '隆林各族自治县',
      id: 1474
    },
    451081: {
      name: '靖西市',
      id: 1475
    },
    451102: {
      name: '八步区',
      id: 1213
    },
    451103: {
      name: '平桂区',
      id: 1210
    },
    451121: {
      name: '昭平县',
      id: 1212
    },
    451122: {
      name: '钟山县',
      id: 1211
    },
    451123: {
      name: '富川瑶族自治县',
      id: 1214
    },
    451202: {
      name: '金城江区',
      id: 1890
    },
    451203: {
      name: '宜州区',
      id: 1888
    },
    451221: {
      name: '南丹县',
      id: 1894
    },
    451222: {
      name: '天峨县',
      id: 1896
    },
    451223: {
      name: '凤山县',
      id: 1895
    },
    451224: {
      name: '东兰县',
      id: 1891
    },
    451225: {
      name: '罗城仫佬族自治县',
      id: 1889
    },
    451226: {
      name: '环江毛南族自治县',
      id: 1893
    },
    451227: {
      name: '巴马瑶族自治县',
      id: 1892
    },
    451228: {
      name: '都安瑶族自治县',
      id: 1887
    },
    451229: {
      name: '大化瑶族自治县',
      id: 1886
    },
    451302: {
      name: '兴宾区',
      id: 1695
    },
    451321: {
      name: '忻城县',
      id: 1699
    },
    451322: {
      name: '象州县',
      id: 1698
    },
    451323: {
      name: '武宣县',
      id: 1697
    },
    451324: {
      name: '金秀瑶族自治县',
      id: 1696
    },
    451381: {
      name: '合山市',
      id: 1694
    },
    451402: {
      name: '江州区',
      id: 2243
    },
    451421: {
      name: '扶绥县',
      id: 2245
    },
    451422: {
      name: '宁明县',
      id: 2241
    },
    451423: {
      name: '龙州县',
      id: 2240
    },
    451424: {
      name: '大新县',
      id: 2244
    },
    451425: {
      name: '天等县',
      id: 2242
    },
    451481: {
      name: '凭祥市',
      id: 2239
    },
    460105: {
      name: '秀英区',
      id: 2034
    },
    460106: {
      name: '龙华区',
      id: 2033
    },
    460107: {
      name: '琼山区',
      id: 2032
    },
    460108: {
      name: '美兰区',
      id: 2031
    },
    460202: {
      name: '海棠区',
      id: 2205
    },
    460203: {
      name: '吉阳区',
      id: 2204
    },
    460204: {
      name: '天涯区',
      id: 2203
    },
    460205: {
      name: '崖州区',
      id: 2202
    },
    460321: {
      name: '西沙群岛',
      id: 2390
    },
    460322: {
      name: '南沙群岛',
      id: 2389
    },
    460323: {
      name: '中沙群岛的岛礁及其海域',
      id: 2388
    },
    460400: {
      name: '儋州市',
      id: 2516
    },
    469001: {
      name: '五指山市',
      id: 2406
    },
    469002: {
      name: '琼海市',
      id: 2405
    },
    469005: {
      name: '文昌市',
      id: 2392
    },
    469006: {
      name: '万宁市',
      id: 2391
    },
    469007: {
      name: '东方市',
      id: 2472
    },
    469021: {
      name: '定安县',
      id: 2234
    },
    469022: {
      name: '屯昌县',
      id: 2233
    },
    469023: {
      name: '澄迈县',
      id: 2232
    },
    469024: {
      name: '临高县',
      id: 2231
    },
    469025: {
      name: '白沙黎族自治县',
      id: 2223
    },
    469026: {
      name: '昌江黎族自治县',
      id: 2222
    },
    469027: {
      name: '乐东黎族自治县',
      id: 2207
    },
    469028: {
      name: '陵水黎族自治县',
      id: 2206
    },
    469029: {
      name: '保亭黎族苗族自治县',
      id: 2314
    },
    469030: {
      name: '琼中黎族苗族自治县',
      id: 2342
    },
    500101: {
      name: '万州区',
      id: 1599
    },
    500102: {
      name: '涪陵区',
      id: 1598
    },
    500103: {
      name: '渝中区',
      id: 1597
    },
    500104: {
      name: '大渡口区',
      id: 1577
    },
    500105: {
      name: '江北区',
      id: 1576
    },
    500106: {
      name: '沙坪坝区',
      id: 1575
    },
    500107: {
      name: '九龙坡区',
      id: 1574
    },
    500108: {
      name: '南岸区',
      id: 1579
    },
    500109: {
      name: '北碚区',
      id: 1578
    },
    500110: {
      name: '綦江区',
      id: 1580
    },
    500111: {
      name: '大足区',
      id: 1589
    },
    500112: {
      name: '渝北区',
      id: 1587
    },
    500113: {
      name: '巴南区',
      id: 1586
    },
    500114: {
      name: '黔江区',
      id: 1585
    },
    500115: {
      name: '长寿区',
      id: 1594
    },
    500116: {
      name: '江津区',
      id: 1593
    },
    500117: {
      name: '合川区',
      id: 1592
    },
    500118: {
      name: '永川区',
      id: 1591
    },
    500119: {
      name: '南川区',
      id: 1595
    },
    500120: {
      name: '璧山区',
      id: 1596
    },
    500151: {
      name: '铜梁区',
      id: 1584
    },
    500152: {
      name: '潼南区',
      id: 1583
    },
    500153: {
      name: '荣昌区',
      id: 1582
    },
    500154: {
      name: '开州区',
      id: 1581
    },
    500155: {
      name: '梁平区',
      id: 1590
    },
    500156: {
      name: '武隆区',
      id: 1588
    },
    500229: {
      name: '城口县',
      id: 1348
    },
    500230: {
      name: '丰都县',
      id: 1339
    },
    500231: {
      name: '垫江县',
      id: 1337
    },
    500233: {
      name: '忠县',
      id: 1343
    },
    500235: {
      name: '云阳县',
      id: 1342
    },
    500236: {
      name: '奉节县',
      id: 1347
    },
    500237: {
      name: '巫山县',
      id: 1346
    },
    500238: {
      name: '巫溪县',
      id: 1345
    },
    500240: {
      name: '石柱土家族自治县',
      id: 1341
    },
    500241: {
      name: '秀山土家族苗族自治县',
      id: 1340
    },
    500242: {
      name: '酉阳土家族苗族自治县',
      id: 1338
    },
    500243: {
      name: '彭水苗族土家族自治县',
      id: 1344
    },
    510104: {
      name: '锦江区',
      id: 1010
    },
    510105: {
      name: '青羊区',
      id: 1015
    },
    510106: {
      name: '金牛区',
      id: 1013
    },
    510107: {
      name: '武侯区',
      id: 1018
    },
    510108: {
      name: '成华区',
      id: 1017
    },
    510112: {
      name: '龙泉驿区',
      id: 1007
    },
    510113: {
      name: '青白江区',
      id: 1006
    },
    510114: {
      name: '新都区',
      id: 1012
    },
    510115: {
      name: '温江区',
      id: 1011
    },
    510116: {
      name: '双流区',
      id: 1016
    },
    510117: {
      name: '郫都区',
      id: 1014
    },
    510121: {
      name: '金堂县',
      id: 1004
    },
    510129: {
      name: '大邑县',
      id: 1019
    },
    510131: {
      name: '蒲江县',
      id: 1002
    },
    510132: {
      name: '新津县',
      id: 1005
    },
    510181: {
      name: '都江堰市',
      id: 1009
    },
    510182: {
      name: '彭州市',
      id: 1008
    },
    510183: {
      name: '邛崃市',
      id: 1001
    },
    510184: {
      name: '崇州市',
      id: 1000
    },
    510185: {
      name: '简阳市',
      id: 1003
    },
    510302: {
      name: '自流井区',
      id: 2259
    },
    510303: {
      name: '贡井区',
      id: 2261
    },
    510304: {
      name: '大安区',
      id: 2260
    },
    510311: {
      name: '沿滩区',
      id: 2256
    },
    510321: {
      name: '荣县',
      id: 2258
    },
    510322: {
      name: '富顺县',
      id: 2257
    },
    510402: {
      name: '东区',
      id: 2055
    },
    510403: {
      name: '西区',
      id: 2054
    },
    510411: {
      name: '仁和区',
      id: 2052
    },
    510421: {
      name: '米易县',
      id: 2051
    },
    510422: {
      name: '盐边县',
      id: 2053
    },
    510502: {
      name: '江阳区',
      id: 1871
    },
    510503: {
      name: '纳溪区',
      id: 1874
    },
    510504: {
      name: '龙马潭区',
      id: 1873
    },
    510521: {
      name: '泸县',
      id: 1870
    },
    510522: {
      name: '合江县',
      id: 1869
    },
    510524: {
      name: '叙永县',
      id: 1872
    },
    510525: {
      name: '古蔺县',
      id: 1875
    },
    510603: {
      name: '旌阳区',
      id: 1692
    },
    510623: {
      name: '中江县',
      id: 1691
    },
    510626: {
      name: '罗江区',
      id: 1693
    },
    510681: {
      name: '广汉市',
      id: 1688
    },
    510682: {
      name: '什邡市',
      id: 1690
    },
    510683: {
      name: '绵竹市',
      id: 1689
    },
    510703: {
      name: '涪城区',
      id: 2810
    },
    510704: {
      name: '游仙区',
      id: 2808
    },
    510705: {
      name: '安州区',
      id: 2803
    },
    510722: {
      name: '三台县',
      id: 2806
    },
    510723: {
      name: '盐亭县',
      id: 2807
    },
    510725: {
      name: '梓潼县',
      id: 2811
    },
    510726: {
      name: '北川羌族自治县',
      id: 2809
    },
    510727: {
      name: '平武县',
      id: 2804
    },
    510781: {
      name: '江油市',
      id: 2805
    },
    510802: {
      name: '利州区',
      id: 2691
    },
    510811: {
      name: '昭化区',
      id: 2693
    },
    510812: {
      name: '朝天区',
      id: 2695
    },
    510821: {
      name: '旺苍县',
      id: 2690
    },
    510822: {
      name: '青川县',
      id: 2694
    },
    510823: {
      name: '剑阁县',
      id: 2696
    },
    510824: {
      name: '苍溪县',
      id: 2692
    },
    510903: {
      name: '船山区',
      id: 2542
    },
    510904: {
      name: '安居区',
      id: 2543
    },
    510921: {
      name: '蓬溪县',
      id: 2545
    },
    510922: {
      name: '射洪县',
      id: 2546
    },
    510923: {
      name: '大英县',
      id: 2544
    },
    511002: {
      name: '市中区',
      id: 1386
    },
    511011: {
      name: '东兴区',
      id: 1384
    },
    511024: {
      name: '威远县',
      id: 1387
    },
    511025: {
      name: '资中县',
      id: 1385
    },
    511083: {
      name: '隆昌市',
      id: 1388
    },
    511102: {
      name: '市中区',
      id: 1156
    },
    511111: {
      name: '沙湾区',
      id: 1159
    },
    511112: {
      name: '五通桥区',
      id: 1161
    },
    511113: {
      name: '金口河区',
      id: 1157
    },
    511123: {
      name: '犍为县',
      id: 1162
    },
    511124: {
      name: '井研县',
      id: 1158
    },
    511126: {
      name: '夹江县',
      id: 1164
    },
    511129: {
      name: '沐川县',
      id: 1163
    },
    511132: {
      name: '峨边彝族自治县',
      id: 1155
    },
    511133: {
      name: '马边彝族自治县',
      id: 1160
    },
    511181: {
      name: '峨眉山市',
      id: 1165
    },
    511302: {
      name: '顺庆区',
      id: 706
    },
    511303: {
      name: '高坪区',
      id: 708
    },
    511304: {
      name: '嘉陵区',
      id: 705
    },
    511321: {
      name: '南部县',
      id: 704
    },
    511322: {
      name: '营山县',
      id: 702
    },
    511323: {
      name: '蓬安县',
      id: 703
    },
    511324: {
      name: '仪陇县',
      id: 707
    },
    511325: {
      name: '西充县',
      id: 709
    },
    511381: {
      name: '阆中市',
      id: 710
    },
    511402: {
      name: '东坡区',
      id: 2168
    },
    511403: {
      name: '彭山区',
      id: 2165
    },
    511421: {
      name: '仁寿县',
      id: 2164
    },
    511423: {
      name: '洪雅县',
      id: 2167
    },
    511424: {
      name: '丹棱县',
      id: 2169
    },
    511425: {
      name: '青神县',
      id: 2166
    },
    511502: {
      name: '翠屏区',
      id: 2344
    },
    511503: {
      name: '南溪区',
      id: 2346
    },
    511521: {
      name: '宜宾县',
      id: 2343
    },
    511523: {
      name: '江安县',
      id: 2348
    },
    511524: {
      name: '长宁县',
      id: 2345
    },
    511525: {
      name: '高县',
      id: 2347
    },
    511526: {
      name: '珙县',
      id: 2351
    },
    511527: {
      name: '筠连县',
      id: 2352
    },
    511528: {
      name: '兴文县',
      id: 2349
    },
    511529: {
      name: '屏山县',
      id: 2350
    },
    511602: {
      name: '广安区',
      id: 2155
    },
    511603: {
      name: '前锋区',
      id: 2158
    },
    511621: {
      name: '岳池县',
      id: 2156
    },
    511622: {
      name: '武胜县',
      id: 2157
    },
    511623: {
      name: '邻水县',
      id: 2154
    },
    511681: {
      name: '华蓥市',
      id: 2153
    },
    511702: {
      name: '通川区',
      id: 2000
    },
    511703: {
      name: '达川区',
      id: 2002
    },
    511722: {
      name: '宣汉县',
      id: 1998
    },
    511723: {
      name: '开江县',
      id: 1999
    },
    511724: {
      name: '大竹县',
      id: 2001
    },
    511725: {
      name: '渠县',
      id: 2003
    },
    511781: {
      name: '万源市',
      id: 1997
    },
    511802: {
      name: '雨城区',
      id: 1823
    },
    511803: {
      name: '名山区',
      id: 1819
    },
    511822: {
      name: '荥经县',
      id: 1818
    },
    511823: {
      name: '汉源县',
      id: 1822
    },
    511824: {
      name: '石棉县',
      id: 1824
    },
    511825: {
      name: '天全县',
      id: 1820
    },
    511826: {
      name: '芦山县',
      id: 1821
    },
    511827: {
      name: '宝兴县',
      id: 1817
    },
    511902: {
      name: '巴州区',
      id: 2885
    },
    511903: {
      name: '恩阳区',
      id: 2886
    },
    511921: {
      name: '通江县',
      id: 2884
    },
    511922: {
      name: '南江县',
      id: 2887
    },
    511923: {
      name: '平昌县',
      id: 2888
    },
    512002: {
      name: '雁江区',
      id: 216
    },
    512021: {
      name: '安岳县',
      id: 215
    },
    512022: {
      name: '乐至县',
      id: 214
    },
    513201: {
      name: '马尔康市',
      id: 10
    },
    513221: {
      name: '汶川县',
      id: 3
    },
    513222: {
      name: '理县',
      id: 1
    },
    513223: {
      name: '茂县',
      id: 11
    },
    513224: {
      name: '松潘县',
      id: 9
    },
    513225: {
      name: '九寨沟市',
      id: 8
    },
    513226: {
      name: '金川县',
      id: 7
    },
    513227: {
      name: '小金县',
      id: 13
    },
    513228: {
      name: '黑水县',
      id: 12
    },
    513230: {
      name: '壤塘县',
      id: 6
    },
    513231: {
      name: '阿坝县',
      id: 5
    },
    513232: {
      name: '若尔盖县',
      id: 4
    },
    513233: {
      name: '红原县',
      id: 2
    },
    513301: {
      name: '康定市',
      id: 1410
    },
    513322: {
      name: '泸定县',
      id: 1413
    },
    513323: {
      name: '丹巴县',
      id: 1411
    },
    513324: {
      name: '九龙县',
      id: 1408
    },
    513325: {
      name: '雅江县',
      id: 1406
    },
    513326: {
      name: '道孚县',
      id: 1419
    },
    513327: {
      name: '炉霍县',
      id: 1417
    },
    513328: {
      name: '甘孜县',
      id: 1416
    },
    513329: {
      name: '新龙县',
      id: 1415
    },
    513330: {
      name: '德格县',
      id: 1405
    },
    513331: {
      name: '白玉县',
      id: 1404
    },
    513332: {
      name: '石渠县',
      id: 1403
    },
    513333: {
      name: '色达县',
      id: 1414
    },
    513334: {
      name: '理塘县',
      id: 1412
    },
    513335: {
      name: '巴塘县',
      id: 1409
    },
    513336: {
      name: '乡城县',
      id: 1407
    },
    513337: {
      name: '稻城县',
      id: 1420
    },
    513338: {
      name: '得荣县',
      id: 1418
    },
    513401: {
      name: '西昌市',
      id: 1170
    },
    513422: {
      name: '木里藏族自治县',
      id: 1173
    },
    513423: {
      name: '盐源县',
      id: 1171
    },
    513424: {
      name: '德昌县',
      id: 1168
    },
    513425: {
      name: '会理县',
      id: 1180
    },
    513426: {
      name: '会东县',
      id: 1178
    },
    513427: {
      name: '宁南县',
      id: 1177
    },
    513428: {
      name: '普格县',
      id: 1176
    },
    513429: {
      name: '布拖县',
      id: 1182
    },
    513430: {
      name: '金阳县',
      id: 1167
    },
    513431: {
      name: '昭觉县',
      id: 1166
    },
    513432: {
      name: '喜德县',
      id: 1175
    },
    513433: {
      name: '冕宁县',
      id: 1174
    },
    513434: {
      name: '越西县',
      id: 1172
    },
    513435: {
      name: '甘洛县',
      id: 1169
    },
    513436: {
      name: '美姑县',
      id: 1181
    },
    513437: {
      name: '雷波县',
      id: 1179
    },
    520102: {
      name: '南明区',
      id: 2059
    },
    520103: {
      name: '云岩区',
      id: 2057
    },
    520111: {
      name: '花溪区',
      id: 2063
    },
    520112: {
      name: '乌当区',
      id: 2061
    },
    520113: {
      name: '白云区',
      id: 2062
    },
    520115: {
      name: '观山湖区',
      id: 2064
    },
    520121: {
      name: '开阳县',
      id: 2056
    },
    520122: {
      name: '息烽县',
      id: 2058
    },
    520123: {
      name: '修文县',
      id: 2060
    },
    520181: {
      name: '清镇市',
      id: 2065
    },
    520201: {
      name: '钟山区',
      id: 2237
    },
    520203: {
      name: '六枝特区',
      id: 2238
    },
    520221: {
      name: '水城县',
      id: 2236
    },
    520281: {
      name: '盘州市',
      id: 2235
    },
    520302: {
      name: '红花岗区',
      id: 1762
    },
    520303: {
      name: '汇川区',
      id: 1764
    },
    520304: {
      name: '播州区',
      id: 1766
    },
    520322: {
      name: '桐梓县',
      id: 1760
    },
    520323: {
      name: '绥阳县',
      id: 1761
    },
    520324: {
      name: '正安县',
      id: 1763
    },
    520325: {
      name: '道真仡佬族苗族自治县',
      id: 1765
    },
    520326: {
      name: '务川仡佬族苗族自治县',
      id: 1767
    },
    520327: {
      name: '凤冈县',
      id: 1768
    },
    520328: {
      name: '湄潭县',
      id: 1769
    },
    520329: {
      name: '余庆县',
      id: 1770
    },
    520330: {
      name: '习水县',
      id: 1773
    },
    520381: {
      name: '赤水市',
      id: 1771
    },
    520382: {
      name: '仁怀市',
      id: 1772
    },
    520402: {
      name: '西秀区',
      id: 1944
    },
    520403: {
      name: '平坝区',
      id: 1946
    },
    520422: {
      name: '普定县',
      id: 1942
    },
    520423: {
      name: '镇宁布依族苗族自治县',
      id: 1943
    },
    520424: {
      name: '关岭布依族苗族自治县',
      id: 1945
    },
    520425: {
      name: '紫云苗族布依族自治县',
      id: 1947
    },
    520502: {
      name: '七星关区',
      id: 2700
    },
    520521: {
      name: '大方县',
      id: 2697
    },
    520522: {
      name: '黔西县',
      id: 2698
    },
    520523: {
      name: '金沙县',
      id: 2699
    },
    520524: {
      name: '织金县',
      id: 2701
    },
    520525: {
      name: '纳雍县',
      id: 2702
    },
    520526: {
      name: '威宁彝族回族苗族自治县',
      id: 2703
    },
    520527: {
      name: '赫章县',
      id: 2704
    },
    520602: {
      name: '碧江区',
      id: 2838
    },
    520603: {
      name: '万山区',
      id: 2840
    },
    520621: {
      name: '江口县',
      id: 2835
    },
    520622: {
      name: '玉屏侗族自治县',
      id: 2836
    },
    520623: {
      name: '石阡县',
      id: 2837
    },
    520624: {
      name: '思南县',
      id: 2839
    },
    520625: {
      name: '印江土家族苗族自治县',
      id: 2841
    },
    520626: {
      name: '德江县',
      id: 2842
    },
    520627: {
      name: '沿河土家族自治县',
      id: 2833
    },
    520628: {
      name: '松桃苗族自治县',
      id: 2834
    },
    522301: {
      name: '兴义市',
      id: 965
    },
    522322: {
      name: '兴仁县',
      id: 964
    },
    522323: {
      name: '普安县',
      id: 966
    },
    522324: {
      name: '晴隆县',
      id: 967
    },
    522325: {
      name: '贞丰县',
      id: 968
    },
    522326: {
      name: '望谟县',
      id: 969
    },
    522327: {
      name: '册亨县',
      id: 970
    },
    522328: {
      name: '安龙县',
      id: 971
    },
    522601: {
      name: '凯里市',
      id: 1778
    },
    522622: {
      name: '黄平县',
      id: 1776
    },
    522623: {
      name: '施秉县',
      id: 1779
    },
    522624: {
      name: '三穗县',
      id: 1781
    },
    522625: {
      name: '镇远县',
      id: 1783
    },
    522626: {
      name: '岑巩县',
      id: 1785
    },
    522627: {
      name: '天柱县',
      id: 1786
    },
    522628: {
      name: '锦屏县',
      id: 1787
    },
    522629: {
      name: '剑河县',
      id: 1774
    },
    522630: {
      name: '台江县',
      id: 1788
    },
    522631: {
      name: '黎平县',
      id: 1789
    },
    522632: {
      name: '榕江县',
      id: 1775
    },
    522633: {
      name: '从江县',
      id: 1777
    },
    522634: {
      name: '雷山县',
      id: 1780
    },
    522635: {
      name: '麻江县',
      id: 1782
    },
    522636: {
      name: '丹寨县',
      id: 1784
    },
    522701: {
      name: '都匀市',
      id: 1954
    },
    522702: {
      name: '福泉市',
      id: 1956
    },
    522722: {
      name: '荔波县',
      id: 1953
    },
    522723: {
      name: '贵定县',
      id: 1955
    },
    522725: {
      name: '瓮安县',
      id: 1957
    },
    522726: {
      name: '独山县',
      id: 1958
    },
    522727: {
      name: '平塘县',
      id: 1959
    },
    522728: {
      name: '罗甸县',
      id: 1948
    },
    522729: {
      name: '长顺县',
      id: 1949
    },
    522730: {
      name: '龙里县',
      id: 1950
    },
    522731: {
      name: '惠水县',
      id: 1951
    },
    522732: {
      name: '三都水族自治县',
      id: 1952
    },
    530102: {
      name: '五华区',
      id: 1636
    },
    530103: {
      name: '盘龙区',
      id: 1630
    },
    530111: {
      name: '官渡区',
      id: 1625
    },
    530112: {
      name: '西山区',
      id: 1635
    },
    530113: {
      name: '东川区',
      id: 1637
    },
    530114: {
      name: '呈贡区',
      id: 1631
    },
    530115: {
      name: '晋宁区',
      id: 1633
    },
    530124: {
      name: '富民县',
      id: 1638
    },
    530125: {
      name: '宜良县',
      id: 1632
    },
    530126: {
      name: '石林彝族自治县',
      id: 1634
    },
    530127: {
      name: '嵩明县',
      id: 1628
    },
    530128: {
      name: '禄劝彝族苗族自治县',
      id: 1629
    },
    530129: {
      name: '寻甸回族彝族自治县',
      id: 1627
    },
    530181: {
      name: '安宁市',
      id: 1626
    },
    530302: {
      name: '麒麟区',
      id: 2725
    },
    530303: {
      name: '沾益区',
      id: 2721
    },
    530321: {
      name: '马龙县',
      id: 2727
    },
    530322: {
      name: '陆良县',
      id: 2728
    },
    530323: {
      name: '师宗县',
      id: 2724
    },
    530324: {
      name: '罗平县',
      id: 2726
    },
    530325: {
      name: '富源县',
      id: 2722
    },
    530326: {
      name: '会泽县',
      id: 2723
    },
    530381: {
      name: '宣威市',
      id: 2729
    },
    530402: {
      name: '红塔区',
      id: 2845
    },
    530403: {
      name: '江川区',
      id: 2847
    },
    530422: {
      name: '澄江县',
      id: 2849
    },
    530423: {
      name: '通海县',
      id: 2850
    },
    530424: {
      name: '华宁县',
      id: 2846
    },
    530425: {
      name: '易门县',
      id: 2848
    },
    530426: {
      name: '峨山彝族自治县',
      id: 2843
    },
    530427: {
      name: '新平彝族傣族自治县',
      id: 2844
    },
    530428: {
      name: '元江哈尼族彝族傣族自治县',
      id: 2851
    },
    530502: {
      name: '隆阳区',
      id: 2477
    },
    530521: {
      name: '施甸县',
      id: 2479
    },
    530523: {
      name: '龙陵县',
      id: 2476
    },
    530524: {
      name: '昌宁县',
      id: 2478
    },
    530581: {
      name: '腾冲市',
      id: 2480
    },
    530602: {
      name: '昭阳区',
      id: 2580
    },
    530621: {
      name: '鲁甸县',
      id: 2585
    },
    530622: {
      name: '巧家县',
      id: 2583
    },
    530623: {
      name: '盐津县',
      id: 2584
    },
    530624: {
      name: '大关县',
      id: 2581
    },
    530625: {
      name: '永善县',
      id: 2582
    },
    530626: {
      name: '绥江县',
      id: 2589
    },
    530627: {
      name: '镇雄县',
      id: 2590
    },
    530628: {
      name: '彝良县',
      id: 2587
    },
    530629: {
      name: '威信县',
      id: 2588
    },
    530630: {
      name: '水富县',
      id: 2586
    },
    530702: {
      name: '古城区',
      id: 455
    },
    530721: {
      name: '玉龙纳西族自治县',
      id: 457
    },
    530722: {
      name: '永胜县',
      id: 458
    },
    530723: {
      name: '华坪县',
      id: 454
    },
    530724: {
      name: '宁蒗彝族自治县',
      id: 456
    },
    530802: {
      name: '思茅区',
      id: 591
    },
    530821: {
      name: '宁洱哈尼族彝族自治县',
      id: 586
    },
    530822: {
      name: '墨江哈尼族自治县',
      id: 584
    },
    530823: {
      name: '景东彝族自治县',
      id: 585
    },
    530824: {
      name: '景谷傣族彝族自治县',
      id: 592
    },
    530825: {
      name: '镇沅彝族哈尼族拉祜族自治县',
      id: 593
    },
    530826: {
      name: '江城哈尼族彝族自治县',
      id: 589
    },
    530827: {
      name: '孟连傣族拉祜族佤族自治县',
      id: 590
    },
    530828: {
      name: '澜沧拉祜族自治县',
      id: 587
    },
    530829: {
      name: '西盟佤族自治县',
      id: 588
    },
    530902: {
      name: '临翔区',
      id: 83
    },
    530921: {
      name: '凤庆县',
      id: 77
    },
    530922: {
      name: '云县',
      id: 78
    },
    530923: {
      name: '永德县',
      id: 82
    },
    530924: {
      name: '镇康县',
      id: 84
    },
    530925: {
      name: '双江拉祜族佤族布朗族傣族自治县',
      id: 80
    },
    530926: {
      name: '耿马傣族佤族自治县',
      id: 81
    },
    530927: {
      name: '沧源佤族自治县',
      id: 79
    },
    532301: {
      name: '楚雄市',
      id: 2176
    },
    532322: {
      name: '双柏县',
      id: 2178
    },
    532323: {
      name: '牟定县',
      id: 2177
    },
    532324: {
      name: '南华县',
      id: 2175
    },
    532325: {
      name: '姚安县',
      id: 2174
    },
    532326: {
      name: '大姚县',
      id: 2173
    },
    532327: {
      name: '永仁县',
      id: 2172
    },
    532328: {
      name: '元谋县',
      id: 2171
    },
    532329: {
      name: '武定县',
      id: 2170
    },
    532331: {
      name: '禄丰县',
      id: 2179
    },
    532501: {
      name: '个旧市',
      id: 1993
    },
    532502: {
      name: '开远市',
      id: 1991
    },
    532503: {
      name: '蒙自市',
      id: 1989
    },
    532504: {
      name: '弥勒市',
      id: 1988
    },
    532523: {
      name: '屏边苗族自治县',
      id: 1994
    },
    532524: {
      name: '建水县',
      id: 1992
    },
    532525: {
      name: '石屏县',
      id: 1990
    },
    532527: {
      name: '泸西县',
      id: 1987
    },
    532528: {
      name: '元阳县',
      id: 1986
    },
    532529: {
      name: '红河县',
      id: 1996
    },
    532530: {
      name: '金平苗族瑶族傣族自治县',
      id: 1985
    },
    532531: {
      name: '绿春县',
      id: 1984
    },
    532532: {
      name: '河口瑶族自治县',
      id: 1995
    },
    532601: {
      name: '文山市',
      id: 2717
    },
    532622: {
      name: '砚山县',
      id: 2719
    },
    532623: {
      name: '西畴县',
      id: 2718
    },
    532624: {
      name: '麻栗坡县',
      id: 2716
    },
    532625: {
      name: '马关县',
      id: 2715
    },
    532626: {
      name: '丘北县',
      id: 2714
    },
    532627: {
      name: '广南县',
      id: 2713
    },
    532628: {
      name: '富宁县',
      id: 2720
    },
    532801: {
      name: '景洪市',
      id: 2473
    },
    532822: {
      name: '勐海县',
      id: 2475
    },
    532823: {
      name: '勐腊县',
      id: 2474
    },
    532901: {
      name: '大理市',
      id: 2592
    },
    532922: {
      name: '漾濞彝族自治县',
      id: 2594
    },
    532923: {
      name: '祥云县',
      id: 2593
    },
    532924: {
      name: '宾川县',
      id: 2591
    },
    532925: {
      name: '弥渡县',
      id: 2602
    },
    532926: {
      name: '南涧彝族自治县',
      id: 2601
    },
    532927: {
      name: '巍山彝族回族自治县',
      id: 2600
    },
    532928: {
      name: '永平县',
      id: 2599
    },
    532929: {
      name: '云龙县',
      id: 2598
    },
    532930: {
      name: '洱源县',
      id: 2597
    },
    532931: {
      name: '剑川县',
      id: 2596
    },
    532932: {
      name: '鹤庆县',
      id: 2595
    },
    533102: {
      name: '瑞丽市',
      id: 797
    },
    533103: {
      name: '芒市',
      id: 796
    },
    533122: {
      name: '梁河县',
      id: 800
    },
    533123: {
      name: '盈江县',
      id: 799
    },
    533124: {
      name: '陇川县',
      id: 798
    },
    533301: {
      name: '泸水市',
      id: 2112
    },
    533323: {
      name: '福贡县',
      id: 2113
    },
    533324: {
      name: '贡山独龙族怒族自治县',
      id: 2111
    },
    533325: {
      name: '兰坪白族普米族自治县',
      id: 2110
    },
    533401: {
      name: '香格里拉市',
      id: 2278
    },
    533422: {
      name: '德钦县',
      id: 2280
    },
    533423: {
      name: '维西傈僳族自治县',
      id: 2279
    },
    540102: {
      name: '城关区',
      id: 2637
    },
    540103: {
      name: '堆龙德庆区',
      id: 2639
    },
    540104: {
      name: '达孜区',
      id: 2633
    },
    540121: {
      name: '林周县',
      id: 2640
    },
    540122: {
      name: '当雄县',
      id: 2635
    },
    540123: {
      name: '尼木县',
      id: 2636
    },
    540124: {
      name: '曲水县',
      id: 2638
    },
    540127: {
      name: '墨竹工卡县',
      id: 2634
    },
    540202: {
      name: '桑珠孜区',
      id: 2771
    },
    540221: {
      name: '南木林县',
      id: 2765
    },
    540222: {
      name: '江孜县',
      id: 2767
    },
    540223: {
      name: '定日县',
      id: 2769
    },
    540224: {
      name: '萨迦县',
      id: 2772
    },
    540225: {
      name: '拉孜县',
      id: 2759
    },
    540226: {
      name: '昂仁县',
      id: 2761
    },
    540227: {
      name: '谢通门县',
      id: 2763
    },
    540228: {
      name: '白朗县',
      id: 2764
    },
    540229: {
      name: '仁布县',
      id: 2774
    },
    540230: {
      name: '康马县',
      id: 2775
    },
    540231: {
      name: '定结县',
      id: 2776
    },
    540232: {
      name: '仲巴县',
      id: 2766
    },
    540233: {
      name: '亚东县',
      id: 2768
    },
    540234: {
      name: '吉隆县',
      id: 2770
    },
    540235: {
      name: '聂拉木县',
      id: 2773
    },
    540236: {
      name: '萨嘎县',
      id: 2760
    },
    540237: {
      name: '岗巴县',
      id: 2762
    },
    540302: {
      name: '卡若区',
      id: 2376
    },
    540321: {
      name: '江达县',
      id: 2381
    },
    540322: {
      name: '贡觉县',
      id: 2382
    },
    540323: {
      name: '类乌齐县',
      id: 2383
    },
    540324: {
      name: '丁青县',
      id: 2377
    },
    540325: {
      name: '察雅县',
      id: 2378
    },
    540326: {
      name: '八宿县',
      id: 2379
    },
    540327: {
      name: '左贡县',
      id: 2380
    },
    540328: {
      name: '芒康县',
      id: 2385
    },
    540329: {
      name: '洛隆县',
      id: 2386
    },
    540330: {
      name: '边坝县',
      id: 2384
    },
    540402: {
      name: '巴宜区',
      id: 2502
    },
    540421: {
      name: '工布江达县',
      id: 2506
    },
    540422: {
      name: '米林县',
      id: 2507
    },
    540423: {
      name: '墨脱县',
      id: 2501
    },
    540424: {
      name: '波密县',
      id: 2503
    },
    540425: {
      name: '察隅县',
      id: 2504
    },
    540426: {
      name: '朗县',
      id: 2505
    },
    540502: {
      name: '乃东区',
      id: 340
    },
    540521: {
      name: '扎囊县',
      id: 345
    },
    540522: {
      name: '贡嘎县',
      id: 338
    },
    540523: {
      name: '桑日县',
      id: 339
    },
    540524: {
      name: '琼结县',
      id: 341
    },
    540525: {
      name: '曲松县',
      id: 342
    },
    540526: {
      name: '措美县',
      id: 346
    },
    540527: {
      name: '洛扎县',
      id: 347
    },
    540528: {
      name: '加查县',
      id: 348
    },
    540529: {
      name: '隆子县',
      id: 349
    },
    540530: {
      name: '错那县',
      id: 343
    },
    540531: {
      name: '浪卡子县',
      id: 344
    },
    540602: {
      name: '色尼区',
      id: 497
    },
    540621: {
      name: '嘉黎县',
      id: 494
    },
    540622: {
      name: '比如县',
      id: 495
    },
    540623: {
      name: '聂荣县',
      id: 496
    },
    540624: {
      name: '安多县',
      id: 498
    },
    540625: {
      name: '申扎县',
      id: 501
    },
    540626: {
      name: '索县',
      id: 502
    },
    540627: {
      name: '班戈县',
      id: 503
    },
    540628: {
      name: '巴青县',
      id: 504
    },
    540629: {
      name: '尼玛县',
      id: 500
    },
    540630: {
      name: '双湖县',
      id: 499
    },
    542521: {
      name: '普兰县',
      id: 2781
    },
    542522: {
      name: '札达县',
      id: 2783
    },
    542523: {
      name: '噶尔县',
      id: 2782
    },
    542524: {
      name: '日土县',
      id: 2778
    },
    542525: {
      name: '革吉县',
      id: 2777
    },
    542526: {
      name: '改则县',
      id: 2780
    },
    542527: {
      name: '措勤县',
      id: 2779
    },
    610102: {
      name: '新城区',
      id: 1648
    },
    610103: {
      name: '碑林区',
      id: 1651
    },
    610104: {
      name: '莲湖区',
      id: 1645
    },
    610111: {
      name: '灞桥区',
      id: 1653
    },
    610112: {
      name: '未央区',
      id: 1655
    },
    610113: {
      name: '雁塔区',
      id: 1649
    },
    610114: {
      name: '阎良区',
      id: 1652
    },
    610115: {
      name: '临潼区',
      id: 1646
    },
    610116: {
      name: '长安区',
      id: 1647
    },
    610117: {
      name: '高陵区',
      id: 1643
    },
    610118: {
      name: '鄠邑区',
      id: 1644
    },
    610122: {
      name: '蓝田县',
      id: 1654
    },
    610124: {
      name: '周至县',
      id: 1650
    },
    610202: {
      name: '王益区',
      id: 1862
    },
    610203: {
      name: '印台区',
      id: 1864
    },
    610204: {
      name: '耀州区',
      id: 1865
    },
    610222: {
      name: '宜君县',
      id: 1863
    },
    610302: {
      name: '渭滨区',
      id: 2048
    },
    610303: {
      name: '金台区',
      id: 2050
    },
    610304: {
      name: '陈仓区',
      id: 2045
    },
    610322: {
      name: '凤翔县',
      id: 2039
    },
    610323: {
      name: '岐山县',
      id: 2040
    },
    610324: {
      name: '扶风县',
      id: 2049
    },
    610326: {
      name: '眉县',
      id: 2046
    },
    610327: {
      name: '陇县',
      id: 2047
    },
    610328: {
      name: '千阳县',
      id: 2043
    },
    610329: {
      name: '麟游县',
      id: 2044
    },
    610330: {
      name: '凤县',
      id: 2042
    },
    610331: {
      name: '太白县',
      id: 2041
    },
    610402: {
      name: '秦都区',
      id: 2216
    },
    610403: {
      name: '杨陵区',
      id: 2211
    },
    610404: {
      name: '渭城区',
      id: 2213
    },
    610422: {
      name: '三原县',
      id: 2218
    },
    610423: {
      name: '泾阳县',
      id: 2215
    },
    610424: {
      name: '乾县',
      id: 2217
    },
    610425: {
      name: '礼泉县',
      id: 2212
    },
    610426: {
      name: '永寿县',
      id: 2214
    },
    610427: {
      name: '彬县',
      id: 2209
    },
    610428: {
      name: '长武县',
      id: 2210
    },
    610429: {
      name: '旬邑县',
      id: 2208
    },
    610430: {
      name: '淳化县',
      id: 2219
    },
    610431: {
      name: '武功县',
      id: 2220
    },
    610481: {
      name: '兴平市',
      id: 2221
    },
    610502: {
      name: '临渭区',
      id: 2299
    },
    610503: {
      name: '华州区',
      id: 2301
    },
    610522: {
      name: '潼关县',
      id: 2303
    },
    610523: {
      name: '大荔县',
      id: 2304
    },
    610524: {
      name: '合阳县',
      id: 2300
    },
    610525: {
      name: '澄城县',
      id: 2302
    },
    610526: {
      name: '蒲城县',
      id: 2297
    },
    610527: {
      name: '白水县',
      id: 2298
    },
    610528: {
      name: '富平县',
      id: 2296
    },
    610581: {
      name: '韩城市',
      id: 2295
    },
    610582: {
      name: '华阴市',
      id: 2294
    },
    610602: {
      name: '宝塔区',
      id: 833
    },
    610603: {
      name: '安塞区',
      id: 829
    },
    610621: {
      name: '延长县',
      id: 834
    },
    610622: {
      name: '延川县',
      id: 836
    },
    610623: {
      name: '子长县',
      id: 832
    },
    610625: {
      name: '志丹县',
      id: 830
    },
    610626: {
      name: '吴起县',
      id: 831
    },
    610627: {
      name: '甘泉县',
      id: 827
    },
    610628: {
      name: '富县',
      id: 828
    },
    610629: {
      name: '洛川县',
      id: 826
    },
    610630: {
      name: '宜川县',
      id: 837
    },
    610631: {
      name: '黄龙县',
      id: 838
    },
    610632: {
      name: '黄陵县',
      id: 835
    },
    610702: {
      name: '汉台区',
      id: 1064
    },
    610703: {
      name: '南郑区',
      id: 1066
    },
    610722: {
      name: '城固县',
      id: 1068
    },
    610723: {
      name: '洋县',
      id: 1069
    },
    610724: {
      name: '西乡县',
      id: 1065
    },
    610725: {
      name: '勉县',
      id: 1067
    },
    610726: {
      name: '宁强县',
      id: 1062
    },
    610727: {
      name: '略阳县',
      id: 1063
    },
    610728: {
      name: '镇巴县',
      id: 1060
    },
    610729: {
      name: '留坝县',
      id: 1061
    },
    610730: {
      name: '佛坪县',
      id: 1059
    },
    610802: {
      name: '榆阳区',
      id: 1289
    },
    610803: {
      name: '横山区',
      id: 1286
    },
    610822: {
      name: '府谷县',
      id: 1291
    },
    610824: {
      name: '靖边县',
      id: 1290
    },
    610825: {
      name: '定边县',
      id: 1287
    },
    610826: {
      name: '绥德县',
      id: 1288
    },
    610827: {
      name: '米脂县',
      id: 1284
    },
    610828: {
      name: '佳县',
      id: 1285
    },
    610829: {
      name: '吴堡县',
      id: 1283
    },
    610830: {
      name: '清涧县',
      id: 1292
    },
    610831: {
      name: '子洲县',
      id: 1293
    },
    610881: {
      name: '神木市',
      id: 1282
    },
    610902: {
      name: '汉滨区',
      id: 1519
    },
    610921: {
      name: '汉阴县',
      id: 1524
    },
    610922: {
      name: '石泉县',
      id: 1522
    },
    610923: {
      name: '宁陕县',
      id: 1523
    },
    610924: {
      name: '紫阳县',
      id: 1520
    },
    610925: {
      name: '岚皋县',
      id: 1521
    },
    610926: {
      name: '平利县',
      id: 1517
    },
    610927: {
      name: '镇坪县',
      id: 1518
    },
    610928: {
      name: '旬阳县',
      id: 1515
    },
    610929: {
      name: '白河县',
      id: 1516
    },
    611002: {
      name: '商州区',
      id: 2609
    },
    611021: {
      name: '洛南县',
      id: 2613
    },
    611022: {
      name: '丹凤县',
      id: 2612
    },
    611023: {
      name: '商南县',
      id: 2611
    },
    611024: {
      name: '山阳县',
      id: 2610
    },
    611025: {
      name: '镇安县',
      id: 2608
    },
    611026: {
      name: '柞水县',
      id: 2607
    },
    620102: {
      name: '城关区',
      id: 2027
    },
    620103: {
      name: '七里河区',
      id: 2028
    },
    620104: {
      name: '西固区',
      id: 2029
    },
    620105: {
      name: '安宁区',
      id: 2030
    },
    620111: {
      name: '红古区',
      id: 2024
    },
    620121: {
      name: '永登县',
      id: 2023
    },
    620122: {
      name: '皋兰县',
      id: 2025
    },
    620123: {
      name: '榆中县',
      id: 2026
    },
    620200: {
      name: '嘉峪关市',
      id: 2159
    },
    620302: {
      name: '金川区',
      id: 701
    },
    620321: {
      name: '永昌县',
      id: 700
    },
    620402: {
      name: '白银区',
      id: 910
    },
    620403: {
      name: '平川区',
      id: 911
    },
    620421: {
      name: '靖远县',
      id: 907
    },
    620422: {
      name: '会宁县',
      id: 908
    },
    620423: {
      name: '景泰县',
      id: 909
    },
    620502: {
      name: '秦州区',
      id: 1126
    },
    620503: {
      name: '麦积区',
      id: 1121
    },
    620521: {
      name: '清水县',
      id: 1123
    },
    620522: {
      name: '秦安县',
      id: 1124
    },
    620523: {
      name: '甘谷县',
      id: 1125
    },
    620524: {
      name: '武山县',
      id: 1127
    },
    620525: {
      name: '张家川回族自治县',
      id: 1122
    },
    620602: {
      name: '凉州区',
      id: 1360
    },
    620621: {
      name: '民勤县',
      id: 1361
    },
    620622: {
      name: '古浪县',
      id: 1362
    },
    620623: {
      name: '天祝藏族自治县',
      id: 1363
    },
    620702: {
      name: '甘州区',
      id: 1610
    },
    620721: {
      name: '肃南裕固族自治县',
      id: 1613
    },
    620722: {
      name: '民乐县',
      id: 1614
    },
    620723: {
      name: '临泽县',
      id: 1609
    },
    620724: {
      name: '高台县',
      id: 1611
    },
    620725: {
      name: '山丹县',
      id: 1612
    },
    620802: {
      name: '崆峒区',
      id: 108
    },
    620821: {
      name: '泾川县',
      id: 111
    },
    620822: {
      name: '灵台县',
      id: 106
    },
    620823: {
      name: '崇信县',
      id: 107
    },
    620824: {
      name: '华亭县',
      id: 109
    },
    620825: {
      name: '庄浪县',
      id: 110
    },
    620826: {
      name: '静宁县',
      id: 105
    },
    620902: {
      name: '肃州区',
      id: 308
    },
    620921: {
      name: '金塔县',
      id: 305
    },
    620922: {
      name: '瓜州县',
      id: 306
    },
    620923: {
      name: '肃北蒙古族自治县',
      id: 307
    },
    620924: {
      name: '阿克塞哈萨克族自治县',
      id: 309
    },
    620981: {
      name: '玉门市',
      id: 303
    },
    620982: {
      name: '敦煌市',
      id: 304
    },
    621002: {
      name: '西峰区',
      id: 2825
    },
    621021: {
      name: '庆城县',
      id: 2831
    },
    621022: {
      name: '环县',
      id: 2830
    },
    621023: {
      name: '华池县',
      id: 2827
    },
    621024: {
      name: '合水县',
      id: 2826
    },
    621025: {
      name: '正宁县',
      id: 2829
    },
    621026: {
      name: '宁县',
      id: 2828
    },
    621027: {
      name: '镇原县',
      id: 2832
    },
    621102: {
      name: '安定区',
      id: 1703
    },
    621121: {
      name: '通渭县',
      id: 1705
    },
    621122: {
      name: '陇西县',
      id: 1701
    },
    621123: {
      name: '渭源县',
      id: 1700
    },
    621124: {
      name: '临洮县',
      id: 1704
    },
    621125: {
      name: '漳县',
      id: 1702
    },
    621126: {
      name: '岷县',
      id: 1706
    },
    621202: {
      name: '武都区',
      id: 1901
    },
    621221: {
      name: '成县',
      id: 1900
    },
    621222: {
      name: '文县',
      id: 1899
    },
    621223: {
      name: '宕昌县',
      id: 1903
    },
    621224: {
      name: '康县',
      id: 1902
    },
    621225: {
      name: '西和县',
      id: 1905
    },
    621226: {
      name: '礼县',
      id: 1904
    },
    621227: {
      name: '徽县',
      id: 1907
    },
    621228: {
      name: '两当县',
      id: 1906
    },
    622901: {
      name: '临夏市',
      id: 1366
    },
    622921: {
      name: '临夏县',
      id: 1371
    },
    622922: {
      name: '康乐县',
      id: 1370
    },
    622923: {
      name: '永靖县',
      id: 1367
    },
    622924: {
      name: '广河县',
      id: 1365
    },
    622925: {
      name: '和政县',
      id: 1369
    },
    622926: {
      name: '东乡族自治县',
      id: 1368
    },
    622927: {
      name: '积石山保安族东乡族撒拉族自治县',
      id: 1364
    },
    623001: {
      name: '合作市',
      id: 2454
    },
    623021: {
      name: '临潭县',
      id: 2457
    },
    623022: {
      name: '卓尼县',
      id: 2458
    },
    623023: {
      name: '舟曲县',
      id: 2455
    },
    623024: {
      name: '迭部县',
      id: 2456
    },
    623025: {
      name: '玛曲县',
      id: 2452
    },
    623026: {
      name: '碌曲县',
      id: 2453
    },
    623027: {
      name: '夏河县',
      id: 2451
    },
    630102: {
      name: '城东区',
      id: 607
    },
    630103: {
      name: '城中区',
      id: 608
    },
    630104: {
      name: '城西区',
      id: 605
    },
    630105: {
      name: '城北区',
      id: 606
    },
    630121: {
      name: '大通回族土族自治县',
      id: 604
    },
    630122: {
      name: '湟中县',
      id: 602
    },
    630123: {
      name: '湟源县',
      id: 603
    },
    630202: {
      name: '乐都区',
      id: 463
    },
    630203: {
      name: '平安区',
      id: 460
    },
    630222: {
      name: '民和回族土族自治县',
      id: 459
    },
    630223: {
      name: '互助土族自治县',
      id: 462
    },
    630224: {
      name: '化隆回族自治县',
      id: 464
    },
    630225: {
      name: '循化撒拉族自治县',
      id: 461
    },
    632221: {
      name: '门源回族自治县',
      id: 2617
    },
    632222: {
      name: '祁连县',
      id: 2616
    },
    632223: {
      name: '海晏县',
      id: 2615
    },
    632224: {
      name: '刚察县',
      id: 2614
    },
    632321: {
      name: '同仁县',
      id: 2483
    },
    632322: {
      name: '尖扎县',
      id: 2482
    },
    632323: {
      name: '泽库县',
      id: 2481
    },
    632324: {
      name: '河南蒙古族自治县',
      id: 2484
    },
    632521: {
      name: '共和县',
      id: 525
    },
    632522: {
      name: '同德县',
      id: 529
    },
    632523: {
      name: '贵德县',
      id: 528
    },
    632524: {
      name: '兴海县',
      id: 527
    },
    632525: {
      name: '贵南县',
      id: 526
    },
    632621: {
      name: '玛沁县',
      id: 371
    },
    632622: {
      name: '班玛县',
      id: 370
    },
    632623: {
      name: '甘德县',
      id: 369
    },
    632624: {
      name: '达日县',
      id: 368
    },
    632625: {
      name: '久治县',
      id: 373
    },
    632626: {
      name: '玛多县',
      id: 372
    },
    632701: {
      name: '玉树市',
      id: 171
    },
    632722: {
      name: '杂多县',
      id: 173
    },
    632723: {
      name: '称多县',
      id: 172
    },
    632724: {
      name: '治多县',
      id: 176
    },
    632725: {
      name: '囊谦县',
      id: 175
    },
    632726: {
      name: '曲麻莱县',
      id: 174
    },
    632801: {
      name: '格尔木市',
      id: 18
    },
    632802: {
      name: '德令哈市',
      id: 17
    },
    632821: {
      name: '乌兰县',
      id: 15
    },
    632822: {
      name: '都兰县',
      id: 14
    },
    632823: {
      name: '天峻县',
      id: 19
    },
    632825: {
      name: '海西蒙古族藏族自治州直辖',
      id: 16
    },
    640104: {
      name: '兴庆区',
      id: 243
    },
    640105: {
      name: '西夏区',
      id: 245
    },
    640106: {
      name: '金凤区',
      id: 244
    },
    640121: {
      name: '永宁县',
      id: 242
    },
    640122: {
      name: '贺兰县',
      id: 241
    },
    640181: {
      name: '灵武市',
      id: 240
    },
    640202: {
      name: '大武口区',
      id: 206
    },
    640205: {
      name: '惠农区',
      id: 207
    },
    640221: {
      name: '平罗县',
      id: 205
    },
    640302: {
      name: '利通区',
      id: 47
    },
    640303: {
      name: '红寺堡区',
      id: 50
    },
    640323: {
      name: '盐池县',
      id: 49
    },
    640324: {
      name: '同心县',
      id: 48
    },
    640381: {
      name: '青铜峡市',
      id: 46
    },
    640402: {
      name: '原州区',
      id: 1470
    },
    640422: {
      name: '西吉县',
      id: 1468
    },
    640423: {
      name: '隆德县',
      id: 1467
    },
    640424: {
      name: '泾源县',
      id: 1471
    },
    640425: {
      name: '彭阳县',
      id: 1469
    },
    640502: {
      name: '沙坡头区',
      id: 1209
    },
    640521: {
      name: '中宁县',
      id: 1208
    },
    640522: {
      name: '海原县',
      id: 1207
    },
    650102: {
      name: '天山区',
      id: 69
    },
    650103: {
      name: '沙依巴克区',
      id: 68
    },
    650104: {
      name: '新市区',
      id: 67
    },
    650105: {
      name: '水磨沟区',
      id: 66
    },
    650106: {
      name: '头屯河区',
      id: 65
    },
    650107: {
      name: '达坂城区',
      id: 64
    },
    650109: {
      name: '米东区',
      id: 71
    },
    650121: {
      name: '乌鲁木齐县',
      id: 70
    },
    650202: {
      name: '独山子区',
      id: 1514
    },
    650203: {
      name: '克拉玛依区',
      id: 1513
    },
    650204: {
      name: '白碱滩区',
      id: 1512
    },
    650205: {
      name: '乌尔禾区',
      id: 1511
    },
    650402: {
      name: '高昌区',
      id: 1020
    },
    650421: {
      name: '鄯善县',
      id: 1022
    },
    650422: {
      name: '托克逊县',
      id: 1021
    },
    650502: {
      name: '伊州区',
      id: 759
    },
    650521: {
      name: '巴里坤哈萨克自治县',
      id: 761
    },
    650522: {
      name: '伊吾县',
      id: 760
    },
    652301: {
      name: '昌吉市',
      id: 246
    },
    652302: {
      name: '阜康市',
      id: 248
    },
    652323: {
      name: '呼图壁县',
      id: 247
    },
    652324: {
      name: '玛纳斯县',
      id: 249
    },
    652325: {
      name: '奇台县',
      id: 250
    },
    652327: {
      name: '吉木萨尔县',
      id: 251
    },
    652328: {
      name: '木垒哈萨克自治县',
      id: 252
    },
    652701: {
      name: '博乐市',
      id: 1071
    },
    652702: {
      name: '阿拉山口市',
      id: 1073
    },
    652722: {
      name: '精河县',
      id: 1070
    },
    652723: {
      name: '温泉县',
      id: 1072
    },
    652801: {
      name: '库尔勒市',
      id: 814
    },
    652822: {
      name: '轮台县',
      id: 813
    },
    652823: {
      name: '尉犁县',
      id: 815
    },
    652824: {
      name: '若羌县',
      id: 816
    },
    652825: {
      name: '且末县',
      id: 808
    },
    652826: {
      name: '焉耆回族自治县',
      id: 809
    },
    652827: {
      name: '和静县',
      id: 810
    },
    652828: {
      name: '和硕县',
      id: 811
    },
    652829: {
      name: '博湖县',
      id: 812
    },
    652901: {
      name: '阿克苏市',
      id: 2312
    },
    652922: {
      name: '温宿县',
      id: 2311
    },
    652923: {
      name: '库车县',
      id: 2313
    },
    652924: {
      name: '沙雅县',
      id: 2305
    },
    652925: {
      name: '新和县',
      id: 2306
    },
    652926: {
      name: '拜城县',
      id: 2307
    },
    652927: {
      name: '乌什县',
      id: 2308
    },
    652928: {
      name: '阿瓦提县',
      id: 2309
    },
    652929: {
      name: '柯坪县',
      id: 2310
    },
    653001: {
      name: '阿图什市',
      id: 2604
    },
    653022: {
      name: '阿克陶县',
      id: 2603
    },
    653023: {
      name: '阿合奇县',
      id: 2605
    },
    653024: {
      name: '乌恰县',
      id: 2606
    },
    653101: {
      name: '喀什市',
      id: 2489
    },
    653121: {
      name: '疏附县',
      id: 2487
    },
    653122: {
      name: '疏勒县',
      id: 2488
    },
    653123: {
      name: '英吉沙县',
      id: 2490
    },
    653124: {
      name: '泽普县',
      id: 2491
    },
    653125: {
      name: '莎车县',
      id: 2492
    },
    653126: {
      name: '叶城县',
      id: 2493
    },
    653127: {
      name: '麦盖提县',
      id: 2494
    },
    653128: {
      name: '岳普湖县',
      id: 2495
    },
    653129: {
      name: '伽师县',
      id: 2496
    },
    653130: {
      name: '巴楚县',
      id: 2485
    },
    653131: {
      name: '塔什库尔干塔吉克自治县',
      id: 2486
    },
    653201: {
      name: '和田市',
      id: 625
    },
    653221: {
      name: '和田县',
      id: 623
    },
    653222: {
      name: '墨玉县',
      id: 624
    },
    653223: {
      name: '皮山县',
      id: 626
    },
    653224: {
      name: '洛浦县',
      id: 627
    },
    653225: {
      name: '策勒县',
      id: 628
    },
    653226: {
      name: '于田县',
      id: 629
    },
    653227: {
      name: '民丰县',
      id: 630
    },
    654002: {
      name: '伊宁市',
      id: 2677
    },
    654003: {
      name: '奎屯市',
      id: 2679
    },
    654004: {
      name: '霍尔果斯市',
      id: 2681
    },
    654021: {
      name: '伊宁县',
      id: 2674
    },
    654022: {
      name: '察布查尔锡伯自治县',
      id: 2675
    },
    654023: {
      name: '霍城县',
      id: 2676
    },
    654024: {
      name: '巩留县',
      id: 2678
    },
    654025: {
      name: '新源县',
      id: 2680
    },
    654026: {
      name: '昭苏县',
      id: 2682
    },
    654027: {
      name: '特克斯县',
      id: 2683
    },
    654028: {
      name: '尼勒克县',
      id: 2684
    },
    654201: {
      name: '塔城市',
      id: 2408
    },
    654202: {
      name: '乌苏市',
      id: 2410
    },
    654221: {
      name: '额敏县',
      id: 2407
    },
    654223: {
      name: '沙湾县',
      id: 2409
    },
    654224: {
      name: '托里县',
      id: 2411
    },
    654225: {
      name: '裕民县',
      id: 2412
    },
    654226: {
      name: '和布克赛尔蒙古自治县',
      id: 2413
    },
    654301: {
      name: '阿勒泰市',
      id: 550
    },
    654321: {
      name: '布尔津县',
      id: 548
    },
    654322: {
      name: '富蕴县',
      id: 549
    },
    654323: {
      name: '福海县',
      id: 551
    },
    654324: {
      name: '哈巴河县',
      id: 552
    },
    654325: {
      name: '青河县',
      id: 553
    },
    654326: {
      name: '吉木乃县',
      id: 554
    },
    659001: {
      name: '石河子市',
      id: 1357
    },
    659002: {
      name: '阿拉尔市',
      id: 1304
    },
    659003: {
      name: '图木舒克市',
      id: 1296
    },
    659004: {
      name: '五家渠市',
      id: 1295
    },
    659005: {
      name: '北屯市',
      id: 1294
    },
    659006: {
      name: '铁门关市',
      id: 1350
    },
    659007: {
      name: '双河市',
      id: 1349
    },
    659008: {
      name: '可克达拉市',
      id: 1324
    },
    659009: {
      name: '昆玉市',
      id: 1305
    },
    810101: {
      name: '中西区',
      id: 224
    },
    810102: {
      name: '湾仔区',
      id: 226
    },
    810103: {
      name: '东区',
      id: 228
    },
    810104: {
      name: '南区',
      id: 230
    },
    810105: {
      name: '油尖旺区',
      id: 232
    },
    810106: {
      name: '深水埗区',
      id: 234
    },
    810107: {
      name: '九龙城区',
      id: 236
    },
    810108: {
      name: '黄大仙区',
      id: 238
    },
    810109: {
      name: '观塘区',
      id: 239
    },
    810110: {
      name: '荃湾区',
      id: 222
    },
    810111: {
      name: '屯门区',
      id: 223
    },
    810112: {
      name: '元朗区',
      id: 225
    },
    810113: {
      name: '北区',
      id: 227
    },
    810114: {
      name: '大埔区',
      id: 229
    },
    810115: {
      name: '西贡区',
      id: 231
    },
    810116: {
      name: '沙田区',
      id: 233
    },
    810117: {
      name: '葵青区',
      id: 235
    },
    810118: {
      name: '离岛区',
      id: 237
    },
    820101: {
      name: '花地玛堂区',
      id: 2818
    },
    820102: {
      name: '花王堂区',
      id: 2819
    },
    820103: {
      name: '望德堂区',
      id: 2816
    },
    820104: {
      name: '大堂区',
      id: 2817
    },
    820105: {
      name: '风顺堂区',
      id: 2814
    },
    820106: {
      name: '嘉模堂区',
      id: 2815
    },
    820107: {
      name: '路凼填海区',
      id: 2812
    },
    820108: {
      name: '圣方济各堂区',
      id: 2813
    }
  },
  province_list: {
    110000: {
      name: '北京市',
      id: 4
    },
    120000: {
      name: '天津市',
      id: 9
    },
    130000: {
      name: '河北省',
      id: 16
    },
    140000: {
      name: '山西省',
      id: 26
    },
    150000: {
      name: '内蒙古自治区',
      id: 32
    },
    210000: {
      name: '辽宁省',
      id: 18
    },
    220000: {
      name: '吉林省',
      id: 21
    },
    230000: {
      name: '黑龙江省',
      id: 28
    },
    310000: {
      name: '上海市',
      id: 8
    },
    320000: {
      name: '江苏省',
      id: 3
    },
    330000: {
      name: '浙江省',
      id: 7
    },
    340000: {
      name: '安徽省',
      id: 13
    },
    350000: {
      name: '福建省',
      id: 22
    },
    360000: {
      name: '江西省',
      id: 27
    },
    370000: {
      name: '山东省',
      id: 1
    },
    410000: {
      name: '河南省',
      id: 30
    },
    420000: {
      name: '湖北省',
      id: 23
    },
    430000: {
      name: '湖南省',
      id: 15
    },
    440000: {
      name: '广东省',
      id: 24
    },
    450000: {
      name: '广西壮族自治区',
      id: 34
    },
    460000: {
      name: '海南省',
      id: 6
    },
    500000: {
      name: '重庆市',
      id: 25
    },
    510000: {
      name: '四川省',
      id: 19
    },
    520000: {
      name: '贵州省',
      id: 12
    },
    530000: {
      name: '云南省',
      id: 5
    },
    540000: {
      name: '西藏自治区',
      id: 33
    },
    610000: {
      name: '陕西省',
      id: 10
    },
    620000: {
      name: '甘肃省',
      id: 2
    },
    630000: {
      name: '青海省',
      id: 29
    },
    640000: {
      name: '宁夏回族自治区',
      id: 20
    },
    650000: {
      name: '新疆维吾尔自治区',
      id: 11
    },
    710000: {
      name: '台湾省',
      id: 31
    },
    810000: {
      name: '香港特别行政区',
      id: 17
    },
    820000: {
      name: '澳门特别行政区',
      id: 14
    }
  },
}

function areaToOptions(area) {
  let provinceArray = []
  Object.keys(area.province_list).forEach(code => {
    let obj = {}
    obj.value = area.province_list[code].id
    obj.label = area.province_list[code].name
    obj.code = code
    provinceArray.push(obj)
  })
  Object.keys(area.city_list).forEach(code => {
    let obj = {}
    obj.value = area.city_list[code].id
    obj.label = area.city_list[code].name
    obj.code = code
    let result = provinceArray.filter(item => item.code.substr(0, 2) === code.substr(0, 2))
    if (result.length > 0) {
      (result[0].children = result[0].children || []).push(obj)
    }
  })
  Object.keys(area.county_list).forEach(code => {
    let obj = {}
    obj.value = area.county_list[code].id
    obj.label = area.county_list[code].name
    obj.code = code
    let provinceResultArray = provinceArray.filter(item => item.code.substr(0, 2) === code.substr(0, 2))
    if (provinceResultArray.length > 0) {
      let result = provinceResultArray[0].children.filter(item => item.code.substr(0, 4) === code.substr(0, 4))
      if (result.length > 0) {
        (result[0].children = result[0].children || []).push(obj)
      }
    }
  })
  return provinceArray
}

function codeToText(arr) {
  if (!arr) {
    throw Error('参数不能为空')
  }
  if (Object.prototype.toString.call(arr) !== '[object Array]') {
    throw Error('参数必须是数组')
  }
  return arr.map((item, index) => {
    let result = ''
    if (index === 0) {
      Object.keys(area.province_list).forEach(key => {
        if (parseInt(item) === parseInt(area.province_list[key].id)) {
          result = area.province_list[key].name
        }
      })
    } else if (index === 1) {
      Object.keys(area.city_list).forEach(key => {
        if (parseInt(item) === parseInt(area.city_list[key].id)) {
          result = area.city_list[key].name
        }
      })
    } else if (index === 2) {
      Object.keys(area.county_list).forEach(key => {
        if (parseInt(item) === parseInt(area.county_list[key].id)) {
          result = area.county_list[key].name
        }
      })
    }
    return result
  })
}

function parse(target, code) {
  let ret = '';
  Object.keys(target).some(key => {
    if (target[key].id === parseInt(code)) {
      ret = target[key];
      return true;
    }
  });
  return ret;
}

function parseCity(code) {
  let target = area.city_list;
  return parse(target, code);
}

function parseCounty(code) {
  let target = area.county_list;
  return parse(target, code);
}

function parseProvince(code) {
  let target = area.province_list;
  return parse(target, code);
}

export default {
  area: area,
  options: areaToOptions(area),
  codeToText: codeToText,
  parseCity: parseCity,
  parseProvince: parseProvince,
  parseCounty: parseCounty,
}

